import styled from 'styled-components'

import { spaces } from 'Constants'

export default function Legend({ active = true, children, className, color, onClick }) {
  return (
    <Wrapper
      className={className}
      $active={active}
      onClick={onClick}
    >
      <Circle color={color} />
      <span>{children}</span>
    </Wrapper>
  )
}

export const Circle = styled.div`
  height: 11px;
  width: 11px;
  background-color: ${({ color }) => color || 'black'};
  border-radius: 50%;
`

export const Wrapper = styled.div`
  display: grid;
  grid: 1fr / min-content 1fr;
  gap: ${spaces.s};
  font-weight: 600;
  font-size: 13px;
  align-items: center;
  opacity: ${({ $active }) => ($active ? '100%' : '20%')};
  cursor: ${({ onClick }) => onClick && 'pointer'};
  @media (max-width: ${spaces.mobileBreakpoint}) {
    font-size: 11px;
  }
`
