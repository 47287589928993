import { useState } from 'react'
import styled from 'styled-components'

import HighlightedText from 'components/HighlightedText'
import { spaces } from 'Constants'
import Legend from '../Legend'
import { trimHighlightedParagraph } from '../parser'

const OFFSET = 400

export default function Comparator({
  baseTextTitle = 'Propuesta nueva Constitución',
  comparedArticulos,
  highlightedContent,
  legend,
  nombre,
}) {
  const [selectedHighlightIndex, setSelectedHighlightIndex] = useState(0)
  const selectedBorradorHighlight = {
    ...highlightedContent.highlights[selectedHighlightIndex],
  }
  const borradorHighlights = {
    ...highlightedContent,
    highlights: [selectedBorradorHighlight],
  }
  const trimmedBorradorContent = trimHighlightedParagraph({
    content: borradorHighlights.content,
    len: selectedBorradorHighlight.len,
    offset: OFFSET,
    start: selectedBorradorHighlight.start,
  })

  borradorHighlights.content = `...${trimmedBorradorContent.content}...`
  selectedBorradorHighlight.start = trimmedBorradorContent.start
  selectedBorradorHighlight.finish = trimmedBorradorContent.start + selectedBorradorHighlight.len

  const {
    documento_id: documentoId,
    file_path: filePath,
    len,
    start,
  } = selectedBorradorHighlight.match

  const selectedTitle = legend[documentoId].label
  const selectedComparedArticulo = comparedArticulos.find(
    (articulo) =>
      articulo.documentoId === documentoId &&
      articulo.articuloId === filePath.replace('.json', '').replace('.yml', '')
  )

  const trimmedComparedContent = trimHighlightedParagraph({
    content: selectedComparedArticulo.contenido,
    len,
    offset: OFFSET,
    start,
  })
  const comparedHighlights = {
    content: `...${trimmedComparedContent.content}...`,
    highlights: [
      {
        color: selectedBorradorHighlight.color,
        finish: trimmedComparedContent.start + len,
        start: trimmedComparedContent.start,
      },
    ],
    name: selectedComparedArticulo.documentoId,
  }

  return (
    <Wrapper>
      <Top>
        <Title>Comparador Constitucional</Title>
        <Selector>
          {highlightedContent.highlights.map((highlight, index) => {
            const matchStart = highlight?.match?.start
            const matchLen = highlight?.match?.len
            const docId = highlight?.match?.documento_id
            const { color, label } = legend[docId]
            return (
              <Legend
                active={selectedHighlightIndex === index}
                color={color}
                key={`${label}-${color}-${matchStart}-${matchLen}`}
                onClick={() => setSelectedHighlightIndex(index)}
              >
                {label}
              </Legend>
            )
          })}
        </Selector>
      </Top>
      <Titles>
        <SideTitle>{baseTextTitle}</SideTitle>
        <SideTitle>{selectedTitle}</SideTitle>
        <SideSubtitle>{nombre}</SideSubtitle>
        <SideSubtitle>{selectedComparedArticulo.nombre}</SideSubtitle>
        <Text
          interactions={false}
          {...borradorHighlights}
        />
        <Text
          interactions={false}
          content={comparedHighlights.content}
          name={comparedHighlights.name}
          highlights={comparedHighlights.highlights}
        />
      </Titles>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid: min-content 1fr / 1fr;
  gap: ${spaces.l};
  width: 100%;
`

const Titles = styled.div`
  display: grid;
  grid: min-content min-content 1fr / 1fr 1fr;
  gap: ${spaces.m} ${spaces.l};
`

const Top = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  gap: ${spaces.l};
  @media (max-width: ${spaces.mobileBreakpoint}) {
    gap: ${spaces.s};
  }
`

const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  @media (max-width: ${spaces.mobileBreakpoint}) {
    font-size: 20px;
  }
`

const SideTitle = styled(Title)`
  font-size: 18px;
  align-self: left;
  text-align: left;
  @media (max-width: ${spaces.mobileBreakpoint}) {
    font-size: 14px;
  }
`
const SideSubtitle = styled.span`
  font-weight: bold;
  @media (max-width: ${spaces.mobileBreakpoint}) {
    font-size: 14px;
  }
`
const Selector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spaces.m};
  @media (max-width: ${spaces.mobileBreakpoint}) {
    flex-direction: column;
    gap: ${spaces.xs};
  }
`

const Text = styled(HighlightedText)`
  @media (max-width: ${spaces.mobileBreakpoint}) {
    font-size: 11px;
  }
`
