import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

import OptionalOutlink from 'components/OptionalOutlink'
import { socialMedia } from 'Constants'

export default function SocialMediaIcons({ nombreCompleto, redesSociales }) {
  return (
    <AlingIcons>
      {redesSociales
        ?.filter((redSocial) => redSocial.link && redSocial.id !== 'NaN')
        .map(
          ({ link, source }) =>
            nombreCompleto && (
              <OptionalOutlink
                href={link}
                key={link}
              >
                <Icon
                  className="social-network-logo"
                  component={socialMedia[source]}
                />
              </OptionalOutlink>
            )
        )}
    </AlingIcons>
  )
}

const AlingIcons = styled.div`
  display: flex;
  justify-content: flex-start;

  .social-network-logo {
    color: ${({ $primaryColor }) => $primaryColor};
    font-size: 30px;
    text-align: left;
    margin-right: auto;
    margin-right: auto;
  }
`
