import { useParams } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { Collapse, Timeline } from 'antd'
import styled from 'styled-components'

import Content from 'components/Content'
import LoadingSpinner from 'components/StyledSpinner'
import useConfig from 'config/configAdapter/useConfig'
import { spaces } from 'Constants'
import { formatLongOnlyDate } from 'format'
import DetalleVotacionTable from './DetailTable'
import Header from './Header'
import PactoTable from './PactoTable'

const { Panel } = Collapse

const VOTACION = gql`
  query getVotacionArticulo($space_name: String!, $id: String!) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          votacion2023(id: $id) {
            id
            articulo
            capitulo
            versions {
              fecha
              state
              version
              votos {
                elector
                elector_id
                voto
                pacto
              }
              results {
                apruebos
                rechazos
                abstenciones
                noVota
              }
            }
          }
        }
      }
    }
  }
`

function ExpandIcon(props) {
  const { isActive } = props

  return (
    <RightOutlined
      style={{ color: '#CB3694', transition: 'transform 0.1s' }}
      rotate={isActive ? 90 : 0}
    />
  )
}

export default function DetalleArticuloVotaciones() {
  const { antdStyles } = useConfig()
  const menuIconColor = antdStyles.styles.components.Menu.colorIcon
  const { votoId } = useParams()

  const { data, loading } = useQuery(VOTACION, {
    variables: { id: votoId, space_name: 'Consejo Constitucional' },
  })

  if (loading) {
    return <LoadingSpinner />
  }

  const votoData = data?.me?.organizacion?.espacio?.votacion2023 || []

  const [actualVote] = [...votoData.versions]
  return (
    <Wrapper>
      <Header
        iconColor={menuIconColor}
        data={votoData}
        actualVote={actualVote}
      />
      <StyledTimeline>
        {votoData.versions.map((vote) => (
          <Timeline.Item
            color="gray"
            key={`state-article${votoData.id}-v`}
          >
            <Collapse
              ghost
              key={`state-article${votoData.id}-v`}
              defaultActiveKey={`state-article${votoData.id}-v${votoData.versions[0].version}`}
              expandIcon={ExpandIcon}
            >
              <Panel
                header={
                  <SubTitle>
                    {votoData.title} ( v{vote.version} - {formatLongOnlyDate(vote.fecha)})
                  </SubTitle>
                }
                key={`state-article${votoData.id}-v${vote.version}`}
              >
                <Title>Resultado de la votación</Title>
                <PactoTable
                  votos={vote.votos}
                  resultado={vote.results}
                />
                <DetailCollapse
                  ghost
                  key={`state-article-detail${votoData.id}-v`}
                  defaultActiveKey={`state-article-detail-${votoData.id}`}
                  expandIcon={ExpandIcon}
                >
                  <Panel
                    header={<Title>Detalle de la votación</Title>}
                    key={`state-article-detal-${votoData.id}-v${vote.version}`}
                  >
                    <DetalleVotacionTable votos={vote.votos} />
                  </Panel>
                </DetailCollapse>
              </Panel>
            </Collapse>
          </Timeline.Item>
        ))}
      </StyledTimeline>
    </Wrapper>
  )
}

const Title = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 2px;
  padding-bottom: 10px;
`

const StyledTimeline = styled(Timeline)`
  .ant-timeline-item-tail {
    margin-top: 12px; /* baja la línea */
  }
  .ant-timeline-item-head {
    top: 12px; /* baja el dot */
  }
  .ant-timeline-item-content {
    margin-inline-start: 0;
  }

  .ant-collapse-expand-icon {
    padding-inline-end: 5px !important;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 0 0 34px;
  }
`

const SubTitle = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  padding-top: 2px;
`

const DetailCollapse = styled(Collapse)`
  padding-top: 10px;
`

const Wrapper = styled(Content)`
  display: flex;
  flex-direction: column;
  gap: ${spaces.l};
`
