import useConfig from 'config/configAdapter/useConfig'
import { useAuth } from 'vendor/authkit'

export default function Logout() {
  const { setAuthenticated } = useAuth()
  const { baseUrl } = useConfig()

  const handleLogout = () => {
    setAuthenticated(false)
    window.cookies?.remove('session', { url: baseUrl })
    window.location.href = `${baseUrl}/login`
  }

  fetch(`${baseUrl}/auth/logout`, {
    method: 'GET',
  })
    .then(handleLogout)
    .catch(handleLogout)

  return null
}
