import { ConfigProvider } from 'antd'

import useConfig from 'config/configAdapter/useConfig'

export default function AntdConfigProvider({ children }) {
  const { antdStyles = {} } = useConfig()
  const { active: isAntdOverrided = false, styles = {} } = antdStyles
  const configStyles = isAntdOverrided ? styles : {}

  return <ConfigProvider theme={configStyles}>{children}</ConfigProvider>
}
