import { LeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { colors, spaces } from 'Constants'
import ResumenSesionBase from '../ResumenSesion'
import { Value } from '../ResumenSesion/Dato'
import Video from '../Video'

export default function Top({
  editMode,
  filename,
  handleBackButtonClick,
  isFromYoutube,
  isPlaying,
  mergedTranscriptions,
  playerRef,
  primaryColor,
  sesion,
  setIsLoaded,
  setPlayedSeconds,
}) {
  return (
    <Wrapper>
      <LeftOutlined
        style={{ color: primaryColor }}
        onClick={handleBackButtonClick}
      />
      <ResumenSesion
        sesion={sesion}
        editMode={editMode}
        transcripciones={mergedTranscriptions}
        filename={filename}
        hideLabels
      />
      <Video
        isPlaying={isPlaying}
        playerRef={playerRef}
        setIsLoaded={setIsLoaded}
        setPlayedSeconds={setPlayedSeconds}
        url={sesion.video_url}
        isFromYoutube={isFromYoutube(sesion.video_url)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid: 1fr / min-content 65fr 35fr;
  padding-bottom: ${spaces.m};
  gap: ${spaces.m};
  align-items: flex-start;
  border-bottom: 1px solid ${colors.backgroundDarkGrey};
  max-height: 275px;
`

const ResumenSesion = styled(ResumenSesionBase)`
  max-width: none;
  @media (max-width: ${spaces.phoneBreakpoint}) {
    font-size: 12px;
  }

  ${Value}:first-child {
    font-weight: 700;
  }
`
