import React from 'react'
import styled from 'styled-components'

import colungaIndexImage from 'assets/images/colungaIndexImage.svg'
import SubsecretariaLogo from 'assets/images/SubsecretariaNinezLogo.svg'
import { spaces } from 'Constants'

const Rectangle = styled.div`
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-left: 100px;
  @media (max-width: ${spaces.mobileBreakpoint}) {
    padding-left: 0;
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  width: 45%;
  margin-right: 50px;
  @media (max-width: ${spaces.mobileBreakpoint}) {
    width: 100%;
    margin: 20px;
  }
`

const ImageContent = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`
const Title = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 25px;
  color: #071e26;
  max-width: 20ch;
`

const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  width: 100%;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
  color: #071e26;
`

function ColungaIndexPage() {
  return (
    <Rectangle>
      <ContentWrapper>
        <Title>Encuentro nacional: Consejo Consultivo de Niñas, Niños y Adolescentes</Title>
        <Text>
          El viernes 20 de octubre se llevó a cabo el primer conversatorio sobre infancia y
          adolescencia en Chile. En esta instancia, 32 jóvenes –2 representantes electos por región–
          participaron activamente del encuentro que tuvo como propósito fundamental promover y
          asegurar la efectiva participación de los niños, niñas y adolescentes (NNA) en la
          formulación de políticas públicas, así como en proyectos y programas gubernamentales.
        </Text>
        <Text>
          En el evento, los jóvenes se reunieron con las máximas autoridades del país con el fin de
          expresar sus inquietudes y ejercer una influencia directa en los cambios necesarios en sus
          respectivas regiones para mejorar su calidad de vida.
        </Text>
        <ImageContent>
          <Image
            src={SubsecretariaLogo}
            alt="Subsecretaria Niñez Logo"
          />
        </ImageContent>
      </ContentWrapper>
      <Image
        src={colungaIndexImage}
        alt="Colunga Index Page"
      />
    </Rectangle>
  )
}

export default ColungaIndexPage
