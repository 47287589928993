import styled from 'styled-components'

export const DotGroup = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  ${(p) => (p.$highlighted ? 'background: #efefef' : '')};
  border-radius: 4px;
  padding: 4px;
  min-width: 50px;
`

export const Dot = styled.div`
  background: #999;
  border-radius: 50%;
  width: ${(p) => p.size || 5}px;
  height: ${(p) => p.size || 5}px;
  margin: ${(p) => p.$margin || 1}px;
`
