import React from 'react'
import { Flags } from 'react-feature-flags'

import ListadoPersonas from 'components/persona/ListadoPersonas'

function Listado() {
  return (
    <Flags authorizedFlags={['senadores']}>
      <ListadoPersonas
        space="Senadores"
        tipo="senador"
      />
    </Flags>
  )
}

export default Listado
