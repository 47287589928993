import { useContext, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { InfoOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { useConfig } from '@unholster/react-use-config'
import { Button, Input, Tabs } from 'antd'
import styled from 'styled-components'

import Content from 'components/Content'
import { useEnvironment } from 'components/Fetch'
import StyledPageHeader from 'components/PageHeader'
import { StyledDivider } from 'components/StyledDivider'
import LoadingSpinner from 'components/StyledSpinner'
import { spaces as cSpaces } from 'Constants'
import usePagination from 'views/AuthenticatedSite/Pagination'
import SearchContext from './SearchContext'
import searchExamples from './SearchExamples'
import SearchResults from './SearchResults'

const SEARCH = gql`
  fragment SearchResults on SearchResponse {
    pageInfo {
      totalCount
      nextOffset
      size
    }
    results {
      resourceId
      resourceType
      sesion_type
      origen
      title
      date
      highlights {
        ... on SegmentHighlight {
          speaker {
            id
            imagen_url
            nombre_completo
            roles
            partido
          }
          speaker_name
          text
          timecode
        }
      }
    }
  }

  query Search(
    $phrase: String!
    $sortKey: String
    $from: Int!
    $size: Int!
    $filters: [FilterTerms!]
  ) {
    search(
      phrase: $phrase
      pagination: { from: $from, size: $size, sortKey: $sortKey, filters: $filters }
    ) {
      sesiones {
        ...SearchResults
      }
    }
  }
`

export default function Search({ children, queryParam, url }) {
  const location = useLocation()
  const searchPhrase =
    location.pathname === url ? new URLSearchParams(location.search).get(queryParam) : ''
  const value = useMemo(
    () => ({
      queryParam,
      searchPhrase,
      url,
    }),
    [location]
  )

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
}

Search.Input = function SearchInput() {
  const navigate = useNavigate()
  const { queryParam, searchPhrase, url } = useContext(SearchContext)
  const { antdStyles } = useConfig()
  const { spaces } = useEnvironment()
  const primaryColor = antdStyles?.styles.token.colorPrimary
  return (
    <StyledSearchContainer canViewRegiones={spaces.canViewRegiones}>
      <Input.Search
        defaultValue={searchPhrase}
        key={searchPhrase}
        onSearch={(phrase, event) => {
          navigate(`${url}?${queryParam}=${encodeURIComponent(phrase)}`)
          // eslint-disable-next-line no-param-reassign
          event.target.value = ''
        }}
        placeholder="Ingresa el concepto de interés para ver sus menciones en las sesiones"
      />
      <StyledInfoButton
        icon={
          <InfoOutlined
            onClick={() => searchExamples(primaryColor)}
            style={{ fontSize: 12 }}
          />
        }
        type="primary"
      />
    </StyledSearchContainer>
  )
}

const StyledSearchContainer = styled.div`
  display: flex;
  width: ${(props) => (props.canViewRegiones ? '45%' : '70%')};
  align-items: center;
  gap: 6px;
  @media (max-width: ${cSpaces.mobileBreakpoint}) {
    width: 100%;
  }
`

const StyledInfoButton = styled(Button)`
  &.ant-btn.ant-btn-icon-only {
    width: 18px;
    justify-content: center;
    align-items: center;
  }
  border-radius: 50%;
  height: 18px;
  font-size: 6px;
  display: flex;
  box-shadow: none;
  background-color: #c4c4c4;
`

Search.Results = function Results() {
  const { searchPhrase } = useContext(SearchContext)
  const { getPaginationParams, pagination } = usePagination({
    defaultPageSize: 0,
    defaultSortOrder: 'desc',
  })
  const { data, loading, refetch } = useQuery(SEARCH, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables: {
      ...getPaginationParams(),
      phrase: searchPhrase,
    },
  })

  useEffect(() => {
    refetch({
      phrase: searchPhrase,
      ...getPaginationParams(),
    })
  }, [pagination.sort, pagination.order, pagination.pageSize, pagination.pageNumber])
  if (loading) {
    return <LoadingSpinner />
  }

  return loading || !data ? (
    <LoadingSpinner />
  ) : (
    <Content>
      <CustomStyledPageHeader
        title={
          <div>
            Resultados de búsqueda para
            <strong>
              {' '}
              &quot;
              {searchPhrase}
              &quot;{' '}
            </strong>
          </div>
        }
      />
      <StyledDivider />
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            children: (
              <SearchResults
                dataKey="sesiones"
                query={SEARCH}
              />
            ),
            key: '1',
            label: `Sesiones ${data?.search?.sesiones?.pageInfo?.totalCount || 0}`,
          },
        ]}
      />
    </Content>
  )
}

const CustomStyledPageHeader = styled(StyledPageHeader)`
  border: none;
  margin-bottom: 0;
`
