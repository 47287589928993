import { Space } from 'antd'
import styled from 'styled-components'

import Avatar from 'components/Avatar'
import StyledTable from 'components/StyledTable'
import UnderlinedText from 'components/UnderlinedText'
import useConfig from 'config/configAdapter/useConfig'
import { singulares, tipoUnidadTerritorial, unidadTerritorial } from 'Constants'
import { PartidosQuery } from 'query/partidosQuery'
import { TerritoriosQuery } from 'query/territoriosQuery'
import { capitalizeFirstLetter, capitalizeFirstLetterEachWord, shortenName } from 'utils'

const { Column } = StyledTable

function NameColumnContent({ apellidoMaterno, apellidoPaterno, id, imagenURL, nombres, subpacto }) {
  const { avatarColors } = useConfig()

  return (
    <NameSpace
      key={id}
      align="center"
    >
      <Avatar
        src={imagenURL}
        color={avatarColors[subpacto]}
      />
      <NameContainer>
        <UnderlinedText bold>
          {shortenName(nombres)} {apellidoPaterno} {apellidoMaterno}
        </UnderlinedText>
      </NameContainer>
    </NameSpace>
  )
}

function DetalleIntegrantes({ integrantes, role }) {
  let personas = [...integrantes]
  personas = personas.filter((persona) => persona)

  const { partidosArr } = PartidosQuery()
  const { territoriosArr } = TerritoriosQuery(tipoUnidadTerritorial[role])
  const idTerritoriosArr = territoriosArr?.map(({ text }) => ({ text, value: text })) || []

  const nombresArr =
    integrantes
      ?.filter((persona) => persona)
      ?.map(({ nombre_completo: nombreCompleto }) => ({
        text: capitalizeFirstLetterEachWord(nombreCompleto),
        value: nombreCompleto,
      })) || []

  const uTerritorial = unidadTerritorial[singulares[role]]

  const updatedData = false

  return !personas ? null : (
    <StyledTable
      id="integrantes-comision-table"
      getPopupContainer={() => document.getElementById('integrantes-comision-table')}
      rowKey="id"
      dataSource={personas}
    >
      <Column
        key="id"
        render={({
          apellido_materno: apellidoMaterno,
          apellido_paterno: apellidoPaterno,
          id,
          imagen_url: imagenURL,
          nombres,
          partido,
        }) => (
          <NameColumnContent
            apellidoMaterno={apellidoMaterno}
            apellidoPaterno={apellidoPaterno}
            id={id}
            imagenURL={imagenURL}
            nombres={nombres}
            subpacto={partido}
          />
        )}
        title="Nombre"
        filters={updatedData && nombresArr}
        filterSearch
        onFilter={(value, record) => value === record.nombre_completo}
      />
      <Column
        key="partido"
        render={({ partido }) => <p>{partido}</p>}
        title="Partido"
        filters={updatedData && partidosArr}
        filterSearch
        onFilter={(value, record) => record.partido.startsWith(value)}
      />
      <Column
        key="uTerritorial"
        render={({ electo_en: electoEn }) => `Nº ${electoEn.orden}`}
        title={capitalizeFirstLetter(uTerritorial)}
        filters={updatedData && idTerritoriosArr}
        filterSearch
        align="right"
        width={100}
        onFilter={(value, record) => record.electo_en.orden === value}
      />
    </StyledTable>
  )
}

const NameSpace = styled(Space)`
  max-width: 280px;
`

const NameContainer = styled.div``

export default DetalleIntegrantes
