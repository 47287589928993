import { gql, useQuery } from '@apollo/client'

const INSTANCIAS = gql`
  query getInstancias($space_name: String!) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          instacia_aggregate {
            pageInfo {
              nextOffset
              size
              totalCount
            }
            results {
              instancia
            }
          }
        }
      }
    }
  }
`

interface useInstanciaParams {
  spaceName: string
}

const useInstancia = (variables: useInstanciaParams) => {
  const { data: instanciasData } = useQuery(INSTANCIAS, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables,
  })

  const instancias = instanciasData?.me?.organizacion?.espacio?.instacia_aggregate?.results || []

  const adaptedInstancias = instancias
    .map(({ instancia }) => ({
      text: instancia,
      value: instancia,
    }))
    .sort((a, b) => a.text.localeCompare(b.text))

  return { instanciasArr: adaptedInstancias }
}

export default useInstancia
