import { useEffect, useState } from 'react'
import { InfoCircleFilled } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { Tooltip } from 'antd'
import moment from 'moment'
import styled from 'styled-components'

import ActivityBar from 'components/ActivityBar'
import LoadingSpinner from 'components/StyledSpinner'
import StyledTable from 'components/StyledTable'
import useConfig from 'config/configAdapter/useConfig'
import { formatDayMonthDatetime } from 'utils'
import usePagination from 'views/AuthenticatedSite/Pagination'

const { Column } = StyledTable

const CONSEJO_SPACE_NAME = 'Consejo Constitucional'

const CAPITULOS = gql`
  query getCapitulosCount($space_name: String!) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          capitulo_count {
            pageInfo {
              nextOffset
              size
              totalCount
            }
            results {
              titulo
              total
              fecha
              resultado {
                aprobados
                rechazados
                debate
              }
            }
          }
        }
      }
    }
  }
`

function ResultadosArtTitle({ statusTypes }) {
  return (
    <ResultArticuloTitle>
      <ResultArticuloStatement>Resultado</ResultArticuloStatement>
      <StyledStatusList>
        {statusTypes.map((status) => (
          <StyledStatus>
            <Circle color={status.color} />
            <span>{status.title}</span>
          </StyledStatus>
        ))}
      </StyledStatusList>
    </ResultArticuloTitle>
  )
}

function ResultadosArtBody({ resultados }) {
  return (
    <StyledStatusList>
      {resultados.map((resultado) => (
        <ColoredResultado color={resultado.color}>{resultado.value}</ColoredResultado>
      ))}
    </StyledStatusList>
  )
}

function Capitulos() {
  const { antdStyles, statusTypes } = useConfig()
  const primaryColor = antdStyles?.styles.token.colorPrimary
  const [filtros] = useState([])

  const { getPaginationParams, pagination, updatePaginationInfo } = usePagination({
    defaultSortKey: 'fecha',
    defaultSortOrder: 'descend',
  })

  const { data, loading, refetch } = useQuery(CAPITULOS, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ me }) => {
      updatePaginationInfo(me?.organizacion?.espacio?.capitulo_count?.pageInfo)
    },
    variables: {
      ...getPaginationParams(),
      filters: filtros,
      phrase: '',
      space_name: CONSEJO_SPACE_NAME,
    },
  })

  useEffect(() => {
    refetch({
      ...getPaginationParams(),
      filters: filtros,
      phrase: '',
      space_name: CONSEJO_SPACE_NAME,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtros,
    pagination.sort,
    pagination.order,
    pagination.search,
    pagination.pageSize,
    pagination.pageNumber,
  ])

  if (loading) {
    return <LoadingSpinner />
  }

  const capitulos = data?.me?.organizacion?.espacio?.capitulo_count?.results || []

  return (
    <div>
      <StyledTable
        id="capitulos-votaciones-table"
        getPopupContainer={() => document.getElementById('capitulos-votaciones-table')}
        rowKey="nombre"
        scroll={{ x: true }}
        dataSource={capitulos}
        pagination={false}
      >
        <Column
          key="nombre"
          render={({ titulo }) => titulo}
          title="Nombre"
          width={360}
        />
        <Column
          key="art-votados"
          render={({ total }) => <NroArticulosVotados>{total}</NroArticulosVotados>}
          title="Total votaciones"
          width={150}
        />
        <Column
          key="fecha"
          sorter={(a, b) => moment(a.fecha).unix() - moment(b.fecha).unix()}
          showSorterTooltip={false}
          render={({ fecha }) => formatDayMonthDatetime(fecha)}
          width={120}
          title={
            <div>
              Fecha
              <Tooltip title="Fecha de última votación">
                <InfoIcon color={primaryColor} />
              </Tooltip>
            </div>
          }
        />
        <Column
          key="Actividad"
          render={({ resultado }) => (
            <ActivityBar
              activity={statusTypes.map((statusType) => ({
                color: statusType.color,
                value: resultado[statusType.statusId] || 0,
              }))}
            />
          )}
          title="Actividad"
        />
        <Column
          key="resultadosArt"
          render={({ resultado }) => (
            <ResultadosArtBody
              resultados={statusTypes.map((statusType) => ({
                color: statusType.color,
                value: resultado[statusType.statusId] || 0,
              }))}
            />
          )}
          title={<ResultadosArtTitle statusTypes={statusTypes} />}
        />
      </StyledTable>
    </div>
  )
}

const InfoIcon = styled(InfoCircleFilled)`
  margin-left: 5px;
  color: ${({ color }) => color || 'blue'};
`

const ResultArticuloTitle = styled.div`
  width: max-content;
`

const ResultArticuloStatement = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`

const StyledStatusList = styled.div`
  display: flex;
`

const StyledResultArticulo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  margin: 0px 10px;
  width: 80px;
`

const StyledStatus = styled(StyledResultArticulo)`
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
`

const ColoredResultado = styled(StyledResultArticulo)`
  color: ${({ color }) => color || 'black'};
`

const NroArticulosVotados = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Circle = styled.div`
  height: 11px;
  width: 11px;
  background-color: ${({ color }) => color || 'black'};
  border-radius: 50%;
  margin-right: 5px;
`

export default Capitulos
