import { useSearchParams } from 'react-router-dom'
import { Tabs } from 'antd'
import styled from 'styled-components'

import Content from 'components/Content'
import SpaceTitle from 'components/SpaceTitle'
import useConfig from 'config/configAdapter/useConfig'
import Articulos from './Articulos'
import Capitulos from './Capitulos'

export default function Votaciones() {
  const [searchParams, setSearchParams] = useSearchParams()
  const tabParam = searchParams.get('tab')
  const { flags = {} } = useConfig()
  const noActiveTabs = !flags.articulosVotaciones && !flags.capitulosVotaciones
  const tabItems = [
    {
      children: <Articulos />,
      disabled: !flags.articulosVotaciones,
      key: 'articulos',
      label: 'Artículos',
    },
    {
      children: <Capitulos />,
      disabled: !flags.capitulosVotaciones,
      key: 'capitulos',
      label: 'Capítulos',
    },
  ]
  const firstEnabledTab = tabItems.find((item) => !item.disabled)?.key

  return (
    <Wrapper>
      <Header>
        <SpaceTitle sectionName="Historial de Votaciones" />
      </Header>
      <Tabs
        activeKey={noActiveTabs ? null : undefined}
        defaultActiveKey={tabParam || firstEnabledTab}
        items={tabItems}
        onChange={(selectedTab) => {
          setSearchParams({ tab: selectedTab })
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled(Content)`
  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
