import styled from 'styled-components'

import { Circle } from 'components/comparador/Legend'
import { spaces } from 'Constants'
import Circles from './Circles'

function colorsSorter(colorA, colorB, order) {
  const indexKeyA = order.indexOf(colorA)
  const indexKeyB = order.indexOf(colorB)
  return indexKeyA - indexKeyB
}

export default function Header({ colors: headerColors = [], colorsOrder, title }) {
  return (
    <Wrapper>
      {title}
      <Circles>
        {headerColors
          .sort((ca, ab) => colorsSorter(ca, ab, colorsOrder))
          .map((c) => (
            <Circle
              key={`${title.replaceAll(' ', '_')}__${c}`}
              color={c}
            />
          ))}
      </Circles>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: ${spaces.m};
  align-items: center;
  justify-content: space-between;
`
