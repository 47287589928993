import { useNavigate } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import PageHeader from 'components/PageHeader'
import useConfig from 'config/configAdapter/useConfig'
import { spaces } from 'Constants'
import { Label as LabelBase } from '../Columns/State'

export default function Header({ actualVote, data }) {
  const navigate = useNavigate()
  const { primaryColor } = useConfig()

  return (
    <Wrapper>
      <Top>
        <PageHeader
          backIcon={<LeftOutlined style={{ color: primaryColor }} />}
          onBack={() => navigate(-1)}
          title={<Title>{data.articulo} </Title>}
        />
      </Top>
      <Subtitle>Capítulo: {data.capitulo}</Subtitle>
      <Label
        key={`state-article${data.id}-v${actualVote.version}`}
        state={actualVote.state}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.m};
  padding-bottom: ${spaces.m};
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spaces.m};
`

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spaces.s};
  justify-content: space-between;
  width: 100%;
`
const Label = styled(LabelBase)`
  display: flex;
  flex-wrap: wrap;
  margin-left: 32px;
`

const Subtitle = styled.div`
  font-size: 0.9;
  font-weight: 300;
  padding-left: 32px;
`
