import { gql } from '@apollo/client'

export default (origin) => gql`
  query Articulos($tipoArticulo: String) {
    ${origin}(
      tipoArticulo: $tipoArticulo
      pagination: { from: 0, size: 9999, sortKey: "comision", sortOrder: "desc" }
    ) {
      pageInfo {
        totalCount
      }
      results {
        comision
        nombre
        titulo
        contenido
        documentoId
        articuloId
        downloadUrl
        highlights: highlights_comparador {
          start
          len
          match {
            documento_id
            start
            len
            file_path
          }
        }
      }
    }
  }
`
