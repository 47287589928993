import styled from 'styled-components'

import { spaces } from 'Constants'
import { isHighlightActive } from './helpers'
import Legend from './Legend'

export default function Matches({ activeHighlights, legend, matches }) {
  return (
    <Wrapper>
      {matches.map(({ color, finish, match, start }) => {
        if (
          isHighlightActive({
            activeHighlights,
            finish,
            start,
          })
        ) {
          const { documento_id: documentoId, file_path: filePath } = match
          return (
            <Legend
              color={color}
              key={`${filePath}-${documentoId}-${start}-${finish}`}
            >
              {legend[documentoId].label}
            </Legend>
          )
        }
        return null
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: ${spaces.s};
  cursor: pointer;
`
