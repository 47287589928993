import React from 'react'
import { Flags } from 'react-feature-flags'

import SesionList from 'components/sesiones/SesionList'

function SesionSenadorList() {
  return (
    <Flags authorizedFlags={['senadores']}>
      <SesionList
        space="Senadores"
        tipo="Senado"
      />
    </Flags>
  )
}

export default SesionSenadorList
