import { css } from 'styled-components'

const InsideRows = css`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  line-height: 24px;
`

export default InsideRows
