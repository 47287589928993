import { DateTime, Settings } from 'luxon'

export const htmlDecodeText = (rawText) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(rawText, 'text/html')
  return doc.documentElement.textContent
}
export const blendColors = (colorA, colorB, amount) => {
  const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16))
  const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16))
  const r = Math.round(rA + (rB - rA) * amount)
    .toString(16)
    .padStart(2, '0')
  const g = Math.round(gA + (gB - gA) * amount)
    .toString(16)
    .padStart(2, '0')
  const b = Math.round(bA + (bB - bA) * amount)
    .toString(16)
    .padStart(2, '0')
  return `#${r}${g}${b}`
}

export const blendRGBColorArray = (colorArray) => {
  const rgbPattern = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/
  if (colorArray.some((color) => !rgbPattern.test(color))) {
    return null
  }
  if (colorArray.length >= 2) {
    return colorArray
      .splice(1)
      .reduce((finalColor, color) => blendColors(finalColor, color, 0.5), colorArray[0])
  }
  if (colorArray.length === 1) {
    return colorArray[0]
  }
  return 'transparent'
}

export const romanToNum = (roman) => {
  if (roman === '') return 0
  if (roman.startsWith('L')) return 50 + romanToNum(roman.substr(1))
  if (roman.startsWith('XL')) return 40 + romanToNum(roman.substr(2))
  if (roman.startsWith('X')) return 10 + romanToNum(roman.substr(1))
  if (roman.startsWith('IX')) return 9 + romanToNum(roman.substr(2))
  if (roman.startsWith('V')) return 5 + romanToNum(roman.substr(1))
  if (roman.startsWith('IV')) return 4 + romanToNum(roman.substr(2))
  if (roman.startsWith('I')) return 1 + romanToNum(roman.substr(1))
  return 0
}

export function removeAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function toSnakeCase(str, lower = true) {
  const res = removeAccents(str).split(' ').join('_')
  return lower ? res.toLowerCase() : res
}

export function shortenName(str) {
  const nombres = str.split(' ')
  return nombres.length > 1
    ? `${nombres[0].substring(0, 1)}. ${nombres[1].substring(0, 1)}.`
    : `${nombres[0].substring(0, 1)}.`
}

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const formatGenericDatetime = (value, format = 'dd/MM/yyyy') => {
  Settings.defaultLocale = 'es'
  return DateTime.fromISO(value).toFormat(format)
}

export const formatDayMonthDatetime = (value, format = 'dd-MMM') => {
  Settings.defaultLocale = 'es'
  return DateTime.fromISO(value).toFormat(format)
}

export function splitCamelCaseString(string) {
  return string.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
}

export function toNormalForm(string) {
  return removeAccents(string.toLowerCase())
}

export function calculateAge(birthDate) {
  const now = new Date()
  const bd = new Date(birthDate)
  let age = now.getFullYear() - bd.getFullYear()
  const m = now.getMonth() - bd.getMonth()
  if (m < 0 || (m === 0 && now.getDate() < bd.getDate())) {
    age -= 1
  }
  return age
}

export const capitalizeFirstLetterEachWord = (
  value,
  separator = ' ',
  splitEachWordByHyphen = true
) => {
  const tokens = value.split(separator)
  const capitalizedTokens = tokens.map((token) => {
    const tokensByHyphen = token.split('-')
    if (splitEachWordByHyphen && tokensByHyphen.length > 1) {
      return tokensByHyphen.map((tokenByHyphen) => capitalizeFirstLetter(tokenByHyphen)).join('-')
    }
    return capitalizeFirstLetter(token)
  })

  return capitalizedTokens.join(separator)
}

export const normalNumberFormatter = new Intl.NumberFormat('es-CL')
