import { useState } from 'react'
import styled from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'
import { colors } from 'Constants'

const DEFAULT_START_ON_LEFT_ACTIVE = false

export function CustomSwitch({
  labelLeft,
  labelRight,
  onClickLeft,
  onClickRight,
  startOnLeftActive = DEFAULT_START_ON_LEFT_ACTIVE,
}) {
  const [isLeftActive, setIsLeftActive] = useState(startOnLeftActive)
  const { primaryColor } = useConfig()

  const handleOnClick = () => {
    const onClick = isLeftActive ? onClickRight : onClickLeft
    onClick()
    setIsLeftActive(!isLeftActive)
  }

  return (
    <StyledSwitchContainer>
      <SwitchBox
        $primarycolor={primaryColor}
        $active={isLeftActive}
        $isLeftItem
        onClick={handleOnClick}
      >
        <div>{labelLeft}</div>
      </SwitchBox>
      <SwitchBox
        $primarycolor={primaryColor}
        $active={!isLeftActive}
        $isLeftItem={false}
        onClick={handleOnClick}
      >
        <div>{labelRight}</div>
      </SwitchBox>
    </StyledSwitchContainer>
  )
}

const StyledSwitchContainer = styled.div`
  display: grid;
  grid: 1fr / auto auto;
`

const SwitchBox = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid ${(props) => (props.$active ? props.$primarycolor : colors.backgroundDarkGrey)};
  font-weight: ${(props) => (props.$active ? 'bold' : 'normal')};
  color: ${(props) => (props.$active ? colors.backgroundLightGrey : colors.backgroundDarkGrey)};
  background-color: ${(props) => (props.$active ? props.$primarycolor : 'white')};
  border-radius: ${(props) => (props.$isLeftItem ? '3px 0px 0px 3px' : '0px 3px 3px 0px')};
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 5px 8px 5px 8px;
  transition:
    color 500ms,
    border 500ms,
    background-color 300ms;
  ${(props) => props.$active && 'pointer-events: none;'};
`

export const SwitchContainer = styled.div`
  display: flex;
  font-size: 12px;
  justify-content: space-between;
`
