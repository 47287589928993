import React from 'react'
import { Flags } from 'react-feature-flags'

import Wrapper from 'components/Content'
import ListadoPersonas from 'components/persona/ListadoPersonas'

function Listado() {
  return (
    <Wrapper>
      <Flags authorizedFlags={['convencionales']}>
        <ListadoPersonas
          space="Convencionales"
          tipo="convencional"
        />
      </Flags>
    </Wrapper>
  )
}

export default Listado
