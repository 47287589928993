import { spaces } from 'Constants'
import { useEffect, useState } from 'react'

export default function useBreakpoint() {
  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const mobileBreakpiont = Number(spaces.mobileBreakpoint.replace('px', ''))
  const phoneBreakpiont = Number(spaces.phoneBreakpoint.replace('px', ''))

  const isMobile = width < mobileBreakpiont
  const isPhone = width < phoneBreakpiont

  return {
    isMobile,
    isPhone,
    width,
  }
}
