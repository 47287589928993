import styled from 'styled-components'

export default function VoteBar({ $color, $inverted, $percentage, $remainingColor }) {
  return (
    <Bar
      $inverted={$inverted}
      $remainingColor={$remainingColor}
    >
      <ContinuousLine $breakpoint={1 / 6} />
      <Fill
        $percentage={$percentage}
        $color={$color}
      />
      <ContinuousLine $breakpoint={8 / 10} />
    </Bar>
  )
}

const Bar = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: ${({ $inverted }) => ($inverted ? `flex-end` : `flex-start`)};
  background-color: ${({ $remainingColor }) => $remainingColor};
`

const Fill = styled.div`
  background-color: ${({ $color }) => $color};
  height: 100%;
  width: ${({ $percentage }) => $percentage};
`

const ContinuousLine = styled.div`
  height: 26px;
  width: 1px;
  border-left: 1px solid black;
  position: absolute;
  top: 0;
  ${({ $breakpoint }) => $breakpoint && `left: ${$breakpoint * 100}%;`}
`
