import { Flags } from 'react-feature-flags'
import { useNavigate, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import SesionEnSala from 'components/sesiones/SesionesEnSala'
import LoadingSpinner from 'components/StyledSpinner'

const SESION = gql`
  query getSesion($spaceName: String!, $id: String!) {
    me {
      organizacion {
        espacio(space_name: $spaceName) {
          sesion(id: $id) {
            id
            video_url
            origen
            codigo_sesion
            comision
            fecha
            transcripciones {
              hablante_id
              hablante_nombre
              hablante {
                id
                partido
                nombre_completo
                imagen_url
              }
              texto
              tiempo_inicio
              duracion_seg
              tiempo_fin
              tiempo_duracion
            }
          }
        }
      }
    }
  }
`

export default function SesionDiputado() {
  const { sesionId } = useParams()
  const navigate = useNavigate()
  const { data, loading } = useQuery(SESION, {
    variables: { id: sesionId, spaceName: 'Diputados' },
  })

  if (loading) {
    return <LoadingSpinner />
  }
  if (!data) {
    navigate('./..')
    return null
  }

  return (
    <Flags authorizedFlags={['diputados']}>
      <SesionEnSala sesion={data.me.organizacion.espacio.sesion} />
    </Flags>
  )
}
