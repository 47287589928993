import { useState } from 'react'
import { Drawer } from 'antd'
import styled from 'styled-components'

import Card from 'components/comparador/Card'
import Header from 'components/comparador/Header'
import List from 'components/comparador/List'
import Nav from 'components/comparador/Nav'
import { Wrapper as Legend } from './Legend'
import LegendsBase from './Legends'

const origin = 'articulos'

export default function Comparador({
  downloadLink,
  ispublic = false,
  legend,
  pathPrefix,
  results,
  space,
  textTitle,
  tipoArticulo,
  title,
}) {
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }
  return (
    <Wrapper>
      <Header
        title={title}
        legend={legend}
        showDrawer={showDrawer}
      />
      <DetailContainer>
        <Drawer
          placement="left"
          title={<Legends legends={legend} />}
          closable={false}
          onClose={onClose}
          open={open}
          getContainer={false}
          bodyStyle={{ padding: 0 }}
          headerStyle={{ padding: '0 12px' }}
          width={300}
        >
          <Nav
            results={results}
            pathPrefix={pathPrefix}
            legend={legend}
            onClose={onClose}
          />
        </Drawer>
        <List
          CardComponent={Card}
          findAll
          ispublic={ispublic}
          leftHeader={<Title>{textTitle}</Title>}
          tipoArticulo={tipoArticulo}
          tooltipText="Descargar propuesta nueva constitución"
          space={space}
          textTitle={textTitle}
          legend={legend}
          results={results}
          origin={origin}
          defaultDownloadLink={downloadLink}
        />
      </DetailContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid: min-content 1fr / 1fr;
  height: 100%;
  position: relative;
  overflow-y: auto;
`

const DetailContainer = styled.div`
  display: flex;
  overflow-y: auto;
`

const Title = styled.h1`
  font-weight: 500;
  font-size: 16px;
  margin: 0;
`

const Legends = styled(LegendsBase)`
  ${Legend} {
    font-size: 11px;
  }
`
