import { gql, useQuery } from '@apollo/client'

const CAPITULOS = gql`
  query getCapitulos($space_name: String!) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          capitulo_aggregate {
            pageInfo {
              nextOffset
              size
              totalCount
            }
            results {
              nombre
            }
          }
        }
      }
    }
  }
`

interface useCapituloParams {
  spaceName: string
}

const useCapitulo = (variables: useCapituloParams) => {
  const { data: capitulosData } = useQuery(CAPITULOS, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables,
  })

  const capitulos = capitulosData?.me?.organizacion?.espacio?.capitulo_aggregate?.results || []

  const adaptedCapitulos = capitulos
    .map(({ nombre }) => ({
      text: nombre,
      value: nombre,
    }))
    .sort((a, b) => (a.text === '' ? 1 : a.text.localeCompare(b.text, 'es', { numeric: true })))

  return { capitulosArr: adaptedCapitulos }
}

export default useCapitulo
