import { Select } from 'antd'
import styled from 'styled-components'

export function SortOptionsDropDown({
  sortDefaultValue,
  sortOnChange,
  sortOptions,
  sortSelectedValue,
}) {
  return (
    <DropdownContainer>
      <DropdownSelect>
        <strong>Ordenar:</strong>
        <SortSelect
          bordered={false}
          defaultValue={sortDefaultValue}
          onChange={sortOnChange}
          options={sortOptions.map(([value, text]) => ({ label: text, value })).reverse()}
          value={sortSelectedValue}
        />
      </DropdownSelect>
    </DropdownContainer>
  )
}

export default {
  SortOptionsDropDown,
}

const DropdownSelect = styled.div`
  color: gray;
  padding-left: 8px;
`

const DropdownContainer = styled.div`
  display: flex;
`

const SortSelect = styled(Select)`
  color: gray;
  min-width: 150px;
  border: none;
`
