import 'antd/dist/reset.css'

import { useStatedConfig } from 'config/configAdapter/useConfig'
import CNNSans from 'fonts/CNN Sans'
import { ReactNode, useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'

interface GlobalStyleProps {
  children: ReactNode
}

export default function GlobalStyle({ children }: GlobalStyleProps) {
  const {
    config: {
      antdStyles: { active, styles },
    },
  } = useStatedConfig()

  const { fontFamily } = styles.token

  useEffect(() => {
    if (active) {
      const fonts = {
        "'CNN Sans'": CNNSans,
      }
      const configFont = fontFamily
      const selectedFont = fonts[configFont]

      if (selectedFont) document.head.appendChild(selectedFont)
    }
  }, [active, fontFamily])
  return (
    <>
      <Styles />
      {children}
    </>
  )
}

const Styles = createGlobalStyle`
  font-family: 'Open Sans', sans-serif;
  

  .data-category-apruebo {
    &.data-fill, .data-fill {
      background: #D2824E;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #D2824E;
    }
  }


  .data-category-aprueboDignididad {
    &.data-fill, .data-fill {
      background: #BB463E;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #BB463E;
    }
  }


  .data-category-delPueblo,
  .data-category-PuebloConstituyente
   {
    &.data-fill, .data-fill {
      background: #8DC8AE;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #8DC8AE;
    }
  }


  .data-category-nuevaConstitucion {
    &.data-fill, .data-fill {
      background: #684697;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #684697;
    }
  }


  .data-category-otros {
    &.data-fill, .data-fill {
      background: #CC69A5;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #CC69A5;
    }
  }


  .data-category-pueblosIndigenas {
    &.data-fill, .data-fill {
      background: #E7B6D3;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #E7B6D3;
    }
  }


  .data-category-vamosPorChile,
  .data-category-VamosPorChile {
    &.data-fill, .data-fill {
      background: #4780B2;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #4780B2;
    }
  }


  .data-category-ColectivoSocialista {
    &.data-fill, .data-fill {
      background: #EE1A26;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #EE1A26;
    }
  }


  .data-category-MovimientosSocialesConstituyentes {
    &.data-fill, .data-fill {
      background: #E1A0C8;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #E1A0C8;
    }
  }


  .data-category-PueblosIndigenas {
    &.data-fill, .data-fill {
      background: #EE299F;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #EE299F;
    }
  }


  .data-category-FrenteAmplio {
    &.data-fill, .data-fill {
      background: #9B51E0;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #9B51E0;
    }
  }


  .data-category-IndependientesNuevaConstitucion {
    &.data-fill, .data-fill {
      background: #FF6B00;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #FF6B00;
    }
  }


  .data-category-ChileDigno {
    &.data-fill, .data-fill {
      background: #CE1537;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #CE1537;
    }
  }


  .data-category-ColectivoDelApruebo {
    &.data-fill, .data-fill {
      background: #FFA800;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #FFA800;
    }
  }

  .data-category-Mixto {
    &.data-fill, .data-fill {
      background: #3C9A4B;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #3C9A4B;
    }
  }


  .data-category-Otros {
    &.data-fill, .data-fill {
      background: #D8E7B0;
    }

    &.data-bordercolor, .data-bordercolor {
      border-color: #D8E7B0;
    }
  }


  .ant-menu {
    background: none;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: none;
    color: inherit;

    a, a:hover {
      color: inherit;
    }
  }
`
