const { FacebookLogo, InstagramLogo, LinkedinLogo, WebsiteLogo, XLogo } = require('assets')

const origenes = {
  convencional: 'Convención Constitucional',
  diputado: 'Cámara de Diputadas y Diputados',
  regiones: 'regiones',
  senador: 'Senado',
}

const plurales = {
  admisibilidad: 'admisibilidad',
  consejero: 'consejeros',
  convencional: 'convencionales',
  diputado: 'diputados',
  experto: 'expertos',
  mixta: 'mixta',
  senador: 'senadores',
}

const regionToNumber = {
  'Fuera de Chile': 'FueraDeChile',
  'Región Metropolitana de Santiago': 'XVIII',
  'Región de Aisén del Gral. C. Ibáñez del Campo': 'XI',
  'Región de Antofagasta': 'II',
  'Región de Arica y Parinacota': 'XV',
  'Región de Atacama': 'III',
  'Región de Coquimbo': 'IV',
  'Región de La Araucanía': 'IX',
  'Región de Los Lagos': 'X',
  'Región de Los Ríos': 'XIV',
  'Región de Magallanes y de La Antártica Chilena': 'XII',
  'Región de Tarapacá': 'I',
  'Región de Valparaíso': 'V',
  'Región de Ñuble': 'XVI',
  'Región del Bíobío': 'VIII',
  "Región del Libertador B. O'Higgins": 'VI',
  'Región del Maule': 'VII',
}

const origenToPlurales = Object.entries(origenes).reduce((acc, ent) => {
  acc[ent[1]] = plurales[ent[0]]
  return acc
}, {})

module.exports = {
  colors: {
    activeLink: '#2150E8',
    backgroundContent: '#f7f7f7', // Equivalent to #E5E5E5 and 0.3 oppacity
    backgroundDarkGrey: '#E0E0E0',
    backgroundLightGrey: '#EDEDED',
    cnn: '#363795',
    constitutions: {
      constitucion_1925: '#00AFE3',
      constitucion_actual: '#FF71B1',
      constitucion_mb: '#F8C224',
      leyes_organicas: '#7AD261',
      texto_definitivo: '#B38DF0',
    },
    default: '#eee',
    disabledLink: '#828282',
    inputGray: '#F2F2F2',
    menuActiveItem: '#828282',
    menuActiveItemBackground: '#EBEFFD',
    menuSelectedItem: '#2150E8',
    menuSelectedItemBackground: '#2150E80D',
    menuUnselectedItem: '#828282',
    paragraphFont: '#4F4F4F',
    pciudadana: '#0C51A2',
    principalFont: '#646464',
    scannerBlue: '#2150E8',
    selectedNav: '#333333',
    tagFont: '#C4C4C4',
    unselectedNav: '#BDBDBD',
    votacion: {
      aprobada: '#27AE60',
      debate: '#F2C94C',
      pendiente: '#BDBDBD',
      rechazada: '#E15241',
    },
    votacionBackground: '#F3F3F3',
    winterWhite: '#FAFAFA',
  },
  origenToPlurales,
  origenes,
  plurales,
  regionToNumber,
  singulares: {
    admisibilidad: 'admisibilidad',
    consejeros: 'consejero',
    convencionales: 'convencional',
    diputados: 'diputado',
    expertos: 'experto',
    mixta: 'mixta',
    senadores: 'senador',
  },

  socialMedia: {
    FACEBOOK: FacebookLogo,
    INSTAGRAM: InstagramLogo,
    LINKEDIN: LinkedinLogo,
    TWITTER: XLogo,
    WEBSITE: WebsiteLogo,
  },

  spaces: {
    l: '24px',
    m: '12px',
    minWidth: '375px',
    mobileBreakpoint: '768px',
    phoneBreakpoint: '576px',
    s: '6px',
    xl: '36px',
    xs: '3px',
    xxl: '48px',
  },

  tipoTerritorio: {
    D: 'distrito',
    R: 'región',
    S: 'circunscripción',
  },

  tipoUnidadTerritorial: {
    convencional: 'D',
    diputado: 'D',
    senador: 'S',
  },

  unidadTerritorial: {
    admisibilidad: 'NaN',
    consejero: 'NaN',
    convencional: 'distrito',
    diputado: 'distrito',
    experto: 'NaN',
    mixta: 'NaN',
    senador: 'circunscripción',
  },
}
