import { Config } from './config.type'

const DEFAULT_CONFIG: Config = {
  anonymousMode: {
    active: false,
    spaces: {
      canViewConsejoConstitucional: true,
      canViewConstituyentes: true,
      canViewDiputados: false,
      canViewSenadores: false,
    },
  },
  antdStyles: {
    active: false,
    styles: {
      components: {
        Card: {
          padding: 12,
        },
        Menu: {
          colorIcon: '#2150E8',
          colorIconHover: '#2150E8',
        },
        Modal: {
          colorInfo: '#2150E8',
        },
      },
      token: {
        colorLink: '#2150E8',
        colorPrimary: '#2150E8',
        fontFamily: "'Open Sans'",
        logoSize: '36px',
      },
    },
  },
  auth0Domain: '',
  baseUrl: 'http://localhost:3000',
  defaultPath: '/consejo/perfiles',
  description: '',
  flags: {
    admisibilidad: false,
    comisiones: false,
    comparadorConsejo: false,
    consejero: false,
    consejeros: false,
    convencionales: false,
    diputados: false,
    experto: false,
    mixta: false,
    regiones: false,
    senadores: false,
    votaciones2022: false,
  },
  googleAnalyticsIds: [],
  inMaintenance: false,
  isProductionEnv: true,
  privacyPolicyUrl: '',
  showLanding: false,
  showOnTopBar: {
    linkProcesoConstitucional: false,
    linkSecretariaPCiudadana: false,
    logoutButton: true,
    username: true,
  },
}

export default DEFAULT_CONFIG
