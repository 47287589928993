import React from 'react'
import { Flags } from 'react-feature-flags'
import { useParams } from 'react-router-dom'

import RegionTable from 'components/regiones/RegionTable'

const regionesMap = {
  FueraDeChile: 'Fuera de Chile',
  I: 'Región de Tarapacá',
  II: 'Región de Antofagasta',
  III: 'Región de Atacama',
  IV: 'Región de Coquimbo',
  IX: 'Región de La Araucanía',
  V: 'Región de Valparaíso',
  VI: "Región del Libertador B. O'Higgins",
  VII: 'Región del Maule',
  VIII: 'Región del Bíobío',
  X: 'Región de Los Lagos',
  XI: 'Región de Aisén del Gral. C. Ibáñez del Campo',
  XII: 'Región de Magallanes y de La Antártica Chilena',
  XIV: 'Región de Los Ríos',
  XV: 'Región de Arica y Parinacota',
  XVI: 'Región de Ñuble',
  XVIII: 'Región Metropolitana de Santiago',
}

function Region() {
  const { nroRegion } = useParams()
  return (
    <Flags authorizedFlags={['regiones']}>
      <RegionTable
        space="Regiones"
        tipo="Regiones"
        defaultFilters={[{ field: 'sesion_type.keyword', terms: regionesMap[nroRegion] }]}
        regionName={regionesMap[nroRegion]}
      />
    </Flags>
  )
}

export default Region
