const personas = [
  {
    label: 'Aldo Sanhueza Carrera',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Aldo Sanhueza Carrera',
  },
  {
    label: 'Aldo Valle Acevedo',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Aldo Valle Acevedo',
  },
  {
    label: 'Alejandro Kohler Vargas',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Alejandro Kohler Vargas',
  },
  {
    label: 'Alihuén Antileo Navarrete',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Alihuén Antileo Navarrete',
  },
  {
    label: 'Antonio Barchiesi Chávez',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Antonio Barchiesi Chávez',
  },
  {
    label: 'Arturo Phillips Dorr',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Arturo Phillips Dorr',
  },
  {
    label: 'Beatriz Hevia Willer',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Beatriz Hevia Willer',
  },
  {
    label: 'Carlos Recondo Lavanderos',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Carlos Recondo Lavanderos',
  },
  {
    label: 'Carlos Solar Barrios',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Carlos Solar Barrios',
  },
  {
    label: 'Carmen Montoya Mayorga',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Carmen Montoya Mayorga',
  },
  {
    label: 'Carolina Navarrete Rubio',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Carolina Navarrete Rubio',
  },
  {
    label: 'Cecilia Medina Meneses',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Cecilia Medina Meneses',
  },
  {
    label: 'Christian Suárez Crothers',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Christian Suárez Crothers',
  },
  {
    label: 'Claudia Maclean Bravo',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Claudia Maclean Bravo',
  },
  {
    label: 'Diego Vargas Castillo',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Diego Vargas Castillo',
  },
  {
    label: 'Edmundo Eluchans Urenda',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Edmundo Eluchans Urenda',
  },
  {
    label: 'Fernando Viveros Reyes',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Fernando Viveros Reyes',
  },
  {
    label: 'Germán Becker Alvear',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Germán Becker Alvear',
  },
  {
    label: 'Gloria Hutt Hesse',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Gloria Hutt Hesse',
  },
  {
    label: 'Gloria Paredes Díaz',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Gloria Paredes Díaz',
  },
  {
    label: 'Héctor Urban Astete',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Héctor Urban Astete',
  },
  {
    label: 'Ivon Guerra Aguilera',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Ivon Guerra Aguilera',
  },
  {
    label: 'Ivonne Mangelsdorff Galeb',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Ivonne Mangelsdorff Galeb',
  },
  {
    label: 'Jessica Bengoa Mayorga',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Jessica Bengoa Mayorga',
  },
  {
    label: 'Jocelyn Ormeño Lee',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Jocelyn Ormeño Lee',
  },
  {
    label: 'Jorge De La Maza Schleyer',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Jorge De La Maza Schleyer',
  },
  {
    label: 'Jorge Ossandón Spoerer',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Jorge Ossandón Spoerer',
  },
  {
    label: 'José González Pizarro',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'José González Pizarro',
  },
  {
    label: 'Julio Ñanco Antilef',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Julio Ñanco Antilef',
  },
  {
    label: 'Karen Araya Rojas',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Karen Araya Rojas',
  },
  {
    label: 'Kinturay Melin Rapimán',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Kinturay Melin Rapimán',
  },
  {
    label: 'Lorena Gallardo Cárdenas',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Lorena Gallardo Cárdenas',
  },
  {
    label: 'Luis Silva Irarrázaval',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Luis Silva Irarrázaval',
  },
  {
    label: 'Marcela Araya Sepúlveda',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Marcela Araya Sepúlveda',
  },
  {
    label: 'María de los Ángeles López Porfiri',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'María de los Ángeles López Porfiri',
  },
  {
    label: 'María Gatica Gajardo',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'María Gatica Gajardo',
  },
  {
    label: 'María Claudia Jorquera Coria',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'María Claudia Jorquera Coria',
  },
  {
    label: 'María Pardo Vergara',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'María Pardo Vergara',
  },
  {
    label: 'Mariela Fincheira Massardo',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Mariela Fincheira Massardo',
  },
  {
    label: 'Miguel Littín Cucumides',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Miguel Littín Cucumides',
  },
  {
    label: 'Miguel Rojas Soto',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Miguel Rojas Soto',
  },
  {
    label: 'Nancy Marquez González',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Nancy Marquez González',
  },
  {
    label: 'Ninoska Payauna Vilca',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Ninoska Payauna Vilca',
  },
  {
    label: 'Paloma Zúñiga Cerda',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Paloma Zúñiga Cerda',
  },
  {
    label: 'Patricia Spoerer Price',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Patricia Spoerer Price',
  },
  {
    label: 'Paul Sfeir Rubio',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Paul Sfeir Rubio',
  },
  {
    label: 'Pilar Cuevas Mardones',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Pilar Cuevas Mardones',
  },
  {
    label: 'Ricardo Ortega Perrier',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Ricardo Ortega Perrier',
  },
  {
    label: 'Sebastián Figueroa Melo',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Sebastián Figueroa Melo',
  },
  {
    label: 'Sebastián Parraguez González',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Sebastián Parraguez González',
  },
  {
    label: 'Yerko Ljubetic Godoy',
    tipo: 'CONSEJO CONSTITUCIONAL',
    value: 'Yerko Ljubetic Godoy',
  },
  {
    label: 'Paz Anastasiadis Le Roy',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Paz Anastasiadis Le Roy',
  },
  {
    label: 'Jaime Arancibia Mattar',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Jaime Arancibia Mattar',
  },
  {
    label: 'Alexis Omar Cortés Morales',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Alexis Omar Cortés Morales',
  },
  {
    label: 'Carlos Frontaura Rivera',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Carlos Frontaura Rivera',
  },
  {
    label: 'Magaly Fuenzalida Colombo',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Magaly Fuenzalida Colombo',
  },
  {
    label: 'Natalia González Bañados',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Natalia González Bañados',
  },
  {
    label: 'Bettina Mariela Horst Von Thadden',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Bettina Mariela Horst Von Thadden',
  },
  {
    label: 'Gabriela Alejandra Ingeborg Krauss Valle',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Gabriela Alejandra Ingeborg Krauss Valle',
  },
  {
    label: 'Catalina Adriana Lagos Tschorne',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Catalina Adriana Lagos Tschorne',
  },
  {
    label: 'Hernán Larraín Fernandez',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Hernán Larraín Fernandez',
  },
  {
    label: 'Domingo Lovera Parmo',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Domingo Lovera Parmo',
  },
  {
    label: 'Katherine Denisse Martorell Awad',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Katherine Denisse Martorell Awad',
  },
  {
    label: 'Gabriel Ignacio Osorio Vargas',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Gabriel Ignacio Osorio Vargas',
  },
  {
    label: 'Juan José Ossa Santa Cruz',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Juan José Ossa Santa Cruz',
  },
  {
    label: 'Máximo Francisco Pavez Cantillano',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Máximo Francisco Pavez Cantillano',
  },
  {
    label: 'Marcela Inés Peredo Rojas',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Marcela Inés Peredo Rojas',
  },
  {
    label: 'Flavio Quezada Rodríguez',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Flavio Quezada Rodríguez',
  },
  {
    label: 'Teodoro Javier Ribera Neumann',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Teodoro Javier Ribera Neumann',
  },
  {
    label: 'Antonia Rivas Palma',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Antonia Rivas Palma',
  },
  {
    label: 'Catalina Salem Gesell',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Catalina Salem Gesell',
  },
  {
    label: 'José Sebastián Soto Velasco',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'José Sebastián Soto Velasco',
  },
  {
    label: 'Francisco Soto Barrientos',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Francisco Soto Barrientos',
  },
  {
    label: 'Leslie Sánchez Lobos',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Leslie Sánchez Lobos',
  },
  {
    label: 'Verónica Undurraga Valdés',
    tipo: 'COMISIÓN DE EXPERTOS',
    value: 'Verónica Undurraga Valdés',
  },
  {
    label: 'Víctor Manuel Avilés Hernández',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Víctor Manuel Avilés Hernández',
  },
  {
    label: 'Marcos Contreras Enos',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Marcos Contreras Enos',
  },
  {
    label: 'Estefanía Esparza Reyes',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Estefanía Esparza Reyes',
  },
  {
    label: 'Juan Carlos Ferrada Bórquez',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Juan Carlos Ferrada Bórquez',
  },
  {
    label: 'Cecilia Flores Eterovic',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Cecilia Flores Eterovic',
  },
  {
    label: 'Enrique José García Arancibia',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Enrique José García Arancibia',
  },
  {
    label: 'Ana María García Barzelatto',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Ana María García Barzelatto',
  },
  {
    label: 'Claudio Grossman Guiloff',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Claudio Grossman Guiloff',
  },
  {
    label: 'Tomás Jordán Díaz',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Tomás Jordán Díaz',
  },
  {
    label: 'Héctor Antonio Mery Romero',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Héctor Antonio Mery Romero',
  },
  {
    label: 'Marisol Peña Torres',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Marisol Peña Torres',
  },
  {
    label: 'Viviana Ponce De León Solís',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Viviana Ponce De León Solís',
  },
  {
    label: 'Josefina Soto Larreátegui',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Josefina Soto Larreátegui',
  },
  {
    label: 'Julia Urquieta Olivares',
    tipo: 'COMITÉ DE ADMISIBILIDAD',
    value: 'Julia Urquieta Olivares',
  },
]

export default personas
