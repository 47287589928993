import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'
import { colors, origenes, plurales } from 'Constants'
import { capitalizeFirstLetterEachWord } from 'utils'

export default function Name({ origen, rawFullName, speaker }) {
  const { primaryColor } = useConfig()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const consejoTipo = searchParams.get('tipo')

  const tipo =
    origen === 'Consejo Constitucional'
      ? consejoTipo
      : Object.keys(origenes).find((key) => origenes[key] === origen)

  const fullName = capitalizeFirstLetterEachWord(rawFullName || 'Desconocido')

  return (
    <Wrapper>
      {speaker ? (
        <Link
          to={`/${plurales[tipo]}/${speaker.id}`}
          primarycolor={primaryColor}
        >
          {fullName}
        </Link>
      ) : (
        <Unknown color={primaryColor}>{fullName}</Unknown>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-size: 13px;
`

const Unknown = styled.div`
  color: ${colors.selectedNav};
`
