import { useQuery } from '@apollo/client'

import Comparador from 'components/comparador'
import ARTICULOS from 'components/comparador/ARTICULOS.gql'
import LoadingSpinner from 'components/StyledSpinner'
import legend from './legend'

const origin = 'articulos'

export default function ComparadorDetalle({ ispublic = false, space }) {
  const tipoArticulo = 'texto_definitivo'
  const { data, loading } = useQuery(ARTICULOS(origin), {
    variables: {
      tipoArticulo,
    },
  })
  const results = data?.[origin]?.results

  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <Comparador
      textTitle="Propuesta de Nueva Constitución Convención 2022"
      tipoArticulo="texto_definitivo"
      title="Comparador Constitucional 2022"
      pathPrefix="/comparador"
      legend={legend}
      results={results}
      space={space}
      ispublic={ispublic}
    />
  )
}
