import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Button, Checkbox, Space } from 'antd'
import styled from 'styled-components'

import LoadingSpinner from 'components/StyledSpinner'
import StyledTable from 'components/StyledTable'
import { origenes } from 'Constants'
import { formatGenericDatetime } from 'utils'
import usePagination from 'views/AuthenticatedSite/Pagination'

const { Column } = StyledTable

const PROYECTOS = gql`
  query Proyecto(
    $id: String!
    $spaceName: String!
    $from: Int!
    $size: Int!
    $search: String
    $sortKey: String
    $sortOrder: String
    $filters: [FilterTerms!]
  ) {
    me {
      organizacion {
        espacio(space_name: $spaceName) {
          persona(id: $id) {
            proyectos(
              pagination: {
                from: $from
                size: $size
                search: $search
                sortKey: $sortKey
                sortOrder: $sortOrder
                filters: $filters
              }
            ) {
              pageInfo {
                totalCount
              }
              results {
                fecha_presentacion
                boletin
                titulo
                estado
              }
            }
          }
        }
      }
    }
  }
`

const ESTADOSPROYECTOS = gql`
  query EstadosProyectos($from: Int!, $size: Int!) {
    estados_proyectos(pagination: { from: $from, size: $size }) {
      results {
        nombre
      }
    }
  }
`
function DetalleProyectos({ role, spaceName }) {
  const { id } = useParams()
  const origen = origenes[role]
  const [selectedOptions, setSelectedOptions] = useState([])

  const defaultSortKey = 'fecha_presentacion'
  const defaultSortOrder = 'desc'
  const defaultFilters = [{ field: 'origen.keyword', term: origen }]
  const {
    getPaginationParams,
    pagination,
    setFilters,
    setOrder,
    setPageNumber,
    setSort,
    updatePaginationInfo,
  } = usePagination({
    defaultFilters,
    defaultSearch: id,
    defaultSortKey,
    defaultSortOrder,
  })

  const proyectosResponse = useQuery(PROYECTOS, {
    onCompleted: ({
      me: {
        organizacion: {
          espacio: {
            persona: { proyectos },
          },
        },
      },
    }) => updatePaginationInfo(proyectos.pageInfo),
    variables: {
      id: `${role}:${id}`,
      spaceName,
      ...getPaginationParams(),
    },
  })
  const proyectosRawData = proyectosResponse.data
  const proyectosLoading = proyectosResponse.loading
  const proyectosRefetch = proyectosResponse.refetch

  const estadosResponse = useQuery(ESTADOSPROYECTOS, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables: {
      from: 0,
      size: -1,
    },
  })
  const estadosRawData = estadosResponse.data
  const estadosLoading = estadosResponse.loading

  useEffect(() => {
    proyectosRefetch(getPaginationParams())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.sort,
    pagination.order,
    pagination.search,
    pagination.pageSize,
    pagination.pageNumber,
    pagination.filters,
  ])

  const onTableChange = (tablePagination, _filters, sorter) => {
    if (tablePagination) {
      setPageNumber(tablePagination.current)
    }
    if (sorter) {
      setSort(sorter.order ? sorter.columnKey : defaultSortKey)
      setOrder(sorter.order)
    }
  }

  const getSortOrder = (sortKey) => {
    if (pagination.order === null) {
      if (sortKey === defaultSortKey) {
        return defaultSortOrder
      }
      return null
    }
    if (sortKey === pagination.sort) {
      return pagination.order
    }
    return null
  }

  if (proyectosLoading || estadosLoading) {
    return <LoadingSpinner />
  }

  const proyectosData = proyectosRawData?.me?.organizacion?.espacio?.persona?.proyectos.results
  const estadosData =
    estadosRawData &&
    estadosRawData.estados_proyectos.results.map((estado) => ({
      label: estado.nombre,
      value: estado.nombre,
    }))
  return (
    <StyledTable
      onChange={onTableChange}
      dataSource={proyectosData}
      rowKey="boletin"
      pagination={{
        current: pagination.pageNumber,
        onChange: setPageNumber,
        pageSize: pagination.pageSize,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
        total: pagination.totalItems,
      }}
      sortDirections={['descend', 'ascend', 'descend']}
      getPopupContainer={(trigger) => trigger.parentNode.parentNode.parentNode}
    >
      <Column
        dataIndex="boletin"
        key="boletin.keyword"
        title="Nº Boletín"
        render={(boletin) => (
          <StyledBoletin
            href=""
            target="_blank"
          >
            {boletin}
          </StyledBoletin>
        )}
        sorter
        sortOrder={getSortOrder('boletin.keyword')}
      />
      <Column
        width="40%"
        dataIndex="titulo"
        key="titulo"
        title="Título"
      />
      <Column
        dataIndex="fecha_presentacion"
        key="fecha_presentacion"
        title="Fecha"
        sorter
        align="right"
        width={125}
        sortOrder={getSortOrder('fecha_presentacion')}
        render={(date) => formatGenericDatetime(date, 'd MMM y')}
      />
      <Column
        dataIndex="estado"
        key="estado_actual.keyword"
        title="Estado"
        filterDropdown={
          <CheckboxFilter
            onReset={() => setFilters(defaultFilters)}
            onUpdate={(states) => {
              const field = 'estado_actual.keyword'
              const filterTerms = states.reduce(
                (terms, state) => terms.concat([{ field, term: state }]),
                defaultFilters
              )
              setFilters(filterTerms)
              setPageNumber(1)
            }}
            options={estadosData}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        }
      />
    </StyledTable>
  )
}

function CheckboxFilter({ onReset, onUpdate, options, selectedOptions, setSelectedOptions }) {
  return (
    <div style={{ padding: 8 }}>
      <StyledCheckboxGroup
        options={options}
        value={selectedOptions}
        onChange={(selected) => setSelectedOptions(selected)}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => onUpdate(selectedOptions)}
        >
          Enter
        </Button>
        <Button
          onClick={() => {
            setSelectedOptions([])
            onReset()
          }}
        >
          Reiniciar
        </Button>
      </Space>
    </div>
  )
}

const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-bottom: 5px;
`

const StyledBoletin = styled.a`
  color: black !important;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-decoration: underline !important;
`

export default DetalleProyectos
