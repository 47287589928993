import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import useConfig, { useStatedConfig } from 'config/configAdapter/useConfig'
import { ApolloProvider } from 'config/graphql'
import HttpProvider from 'config/http'
import SpaceProvider from 'providers/SpaceProvider'
import { AuthBoundary } from 'vendor/authkit'
import AuthenticatedSite from 'views/AuthenticatedSite'
import ComparadorDetalle from 'views/AuthenticatedSite/Convencionales/Comparador'
import Landing from 'views/Landing/Landing'
import Login from 'views/Login'
import Logout from 'views/Logout'
import Mantencion from 'views/Mantencion'
import PoliticaPrivacidad from 'views/PoliticaPrivacidad'

export default function App() {
  const {
    config: { googleAnalyticsIds, inMaintenance },
    isLoading,
  } = useStatedConfig()
  const { isProductionEnv } = useConfig()

  useEffect(() => {
    if (isProductionEnv) {
      try {
        const reactGAInput = googleAnalyticsIds.map((gaID) => ({
          trackingId: gaID,
        }))
        ReactGA.initialize(reactGAInput)
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [googleAnalyticsIds])

  if (inMaintenance) return <Mantencion />

  if (isLoading) return null

  return (
    <Router>
      <Routes>
        <Route
          element={<Landing />}
          path="/public/landing"
        />
      </Routes>
      <AuthBoundary fallback={<Login />}>
        <Routes>
          <Route
            element={
              <ApolloProvider path="public_graphql/">
                <Routes>
                  <Route
                    element={<ComparadorDetalle ispublic />}
                    path="/public/comparador/:comision"
                  />
                </Routes>
              </ApolloProvider>
            }
            path={'/public/*'}
          />
          <Route
            element={
              <HttpProvider>
                <ApolloProvider>
                  <SpaceProvider>
                    <Routes>
                      <Route
                        element={<Login />}
                        path="/login"
                      />
                      <Route
                        element={<Logout />}
                        path="/logout"
                      />
                      <Route
                        element={<PoliticaPrivacidad />}
                        path="/privacy-policy"
                      />
                      <Route
                        element={<AuthenticatedSite />}
                        path="*"
                      />
                    </Routes>
                  </SpaceProvider>
                </ApolloProvider>
              </HttpProvider>
            }
            path="*"
          />
        </Routes>
      </AuthBoundary>
    </Router>
  )
}
