const convencionales = [
  {
    __typename: 'Convencional',
    lookups: [
      'Cantuarias, Rocío',
      'Rocío Cantuarias',
      'ROCIO AMPARO CANTUARIAS RUBIO',
      'rocio_amparo_cantuarias_rubio',
      'Rocío Cantuarias Rubio',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Logan, Rodrigo',
      'Rodrigo Logan',
      'RODRIGO ALEJANDRO LOGAN SOTO',
      'rodrigo_alejandro_logan_soto',
      'Rodrigo Logan Soto',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoDelApruebo',
      nombre: 'Colectivo del Apruebo',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Martínez, Helmuth',
      'Helmuth Martínez',
      'HELMUTH JACOBO MARTINEZ LLANCAPAN',
      'helmuth_jacobo_martinez_llancapan',
      'Helmuth Martínez Llancapán',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Vidal, Rossana',
      'Vidal, Loreto',
      'Rossana Vidal',
      'ROSSANA LORETO VIDAL HERNANDEZ',
      'rossana_loreto_vidal_hernandez',
      'Rossana Loreto Vidal Hernández',
    ],
    pacto: { __typename: 'Pacto', key: 'Otros', nombre: 'Otros' },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Cozzi, Ruggero',
      'Ruggero Cozzi',
      'RUGGERO IGNACIO COZZI ELZO',
      'ruggero_ignacio_cozzi_elzo',
      'Ruggero Ignacio Cozzi Elzo',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Pustilnick, Tammy',
      'Tammy Pustilnick',
      'TAMMY SOLANGE PUSTILNICK ARDITI',
      'tammy_solange_pustilnick_arditi',
      'Tammy Solange Pustilnick Arditi',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Villena, Ingrid',
      'Ingrid Villena',
      'INGRID FERNANDA VILLENA NARBONA',
      'ingrid_fernanda_villena_narbona',
      'Ingrid Fernanda Villena Narbona',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Atria, Fernando',
      'Fernando Atria',
      'FERNANDO ATRIA LEMAITRE',
      'fernando_atria_lemaitre',
      'Fernando Atria Lemaitre',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Baradit, Jorge',
      'Jorge Baradit',
      'JORGE MARCOS BARADIT MORALES',
      'jorge_marcos_baradit_morales',
      'Jorge Marcos Baradit Morales',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Barceló, Luis',
      'Luis Barceló',
      'LUIS RAMON BARCELO AMADO',
      'luis_ramon_barcelo_amado',
      'Luis Ramón Barceló Amado',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoDelApruebo',
      nombre: 'Colectivo del Apruebo',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Royo, Manuela',
      'Manuela Royo',
      'MANUELA ROYO LETELIER',
      'manuela_royo_letelier',
      'Manuela Royo Letelier',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Cubillos, Marcela',
      'Marcela Cubillos',
      'MARCELA CUBILLOS SIGALL',
      'marcela_cubillos_sigall',
      'Marcela Cubillos Sigall',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Linconao, Francisca',
      'Francisca Linconao',
      'FRANCISCA LINCONAO HUIRCAPAN',
      'francisca_linconao_huircapan',
      'Francisca Linconao Huircapan',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'González, Lidia',
      'Lidia González',
      'LIDIA CRISTINA GONZALEZ CALDERON',
      'lidia_cristina_gonzalez_calderon',
      'Lidia Cristina González Calderón',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Montealegre, Katerine',
      'Katerine Montealegre',
      'KATERINE PRICILA MONTEALEGRE NAVARRO',
      'katerine_pricila_montealegre_navarro',
      'Katerine Priscila Montealegre',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Tepper, María Angélica',
      'María Angélica Tepper',
      'MARIA ANGELICA TEPPER KOLOSSA',
      'maria_angelica_tepper_kolossa',
      'Angelica Tepper Kolossa',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Mena, Felipe',
      'Felipe Mena',
      'FELIPE IGNACIO MENA VILLAR',
      'felipe_ignacio_mena_villar',
      'Felipe Ignacio Mena Villar',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Caiguan, Alexis',
      'Alexis Caiguan',
      'ALEXIS REINALDO CAIGUAN ANCAPAN',
      'alexis_reinaldo_caiguan_ancapan',
      'Alexis Caiguán Ancapán',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Millabur, Adolfo',
      'Adolfo Millabur',
      'ADOLFO NONATO MILLABUR ÑANCUIL',
      'adolfo_nonato_millabur_nancuil',
      'Adolfo Millabur Ñancuil',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Orellana, Matías',
      'Matías Orellana',
      'MATIAS IGNACIO ORELLANA CUELLAR',
      'matias_ignacio_orellana_cuellar',
      'Matías Ignacio Orellana Cuellar',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Cancino, Adriana',
      'Adriana Cancino',
      'ADRIANA JANET MERCEDES CANCINO MENESES',
      'adriana_janet_mercedes_cancino_meneses',
      'Adriana Janet Mercedes Cancino Meneses',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Squella, Agustín',
      'Agustín Squella',
      'AGUSTIN SQUELLA NARDUCCI',
      'agustin_squella_narducci',
      'Agustin Squella Narducci',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoDelApruebo',
      nombre: 'Colectivo del Apruebo',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Monckeberg, Cristian',
      'Cristian Monckeberg',
      'CRISTIAN MONCKEBERG BRUNER',
      'cristian_monckeberg_bruner',
      'Cristián Monckeberg Bruner',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Andrade, Cristóbal',
      'Cristóbal Andrade',
      'CRISTOBAL PATRICIO ANDRADE LEON',
      'cristobal_patricio_andrade_leon',
      'Cristóbal Patricio Andrade León',
    ],
    pacto: { __typename: 'Pacto', key: 'Otros', nombre: 'Otros' },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Bravo, Daniel',
      'Daniel Bravo',
      'DANIEL ALEJANDRO BRAVO SILVA',
      'daniel_alejandro_bravo_silva',
      'Daniel Alejandro Bravo Silva',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Delgado, Aurora',
      'Aurora Delgado',
      'AURORA GENOVEVA DELGADO VERGARA',
      'aurora_genoveva_delgado_vergara',
      'Aurora Genoveva Delgado Vergara',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Giustinianovich, Elisa',
      'Elisa Giustinianovich',
      'ELISA AMANDA GIUSTINIANOVICH CAMPOS',
      'elisa_amanda_giustinianovich_campos',
      'Elisa Amanda Giustinianovich Campos',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Labraña, Elsa',
      'Elsa Labraña',
      'ELSA CAROLINA LABRAÑA PINO',
      'elsa_carolina_labrana_pino',
      'Elsa Carolina Labraña Pino',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Harboe, Felipe',
      'Felipe Harboe',
      'FELIPE HARBOE BASCUÑAN',
      'felipe_harboe_bascunan',
      'Felipe Harboe Bascuñán',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoDelApruebo',
      nombre: 'Colectivo del Apruebo',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Videla, Carolina',
      'Carolina Videla',
      'CAROLINA ELIANA VIDELA OSORIO',
      'carolina_eliana_videla_osorio',
      'Carolina Eliana Videla Osorio',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ChileDigno',
      nombre: 'Chile Digno',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Viera, Christian',
      'Christian Viera',
      'CHRISTIAN PABLO VIERA ALVAREZ',
      'christian_pablo_viera_alvarez',
      'Christian Pablo Viera Alvarez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Uribe, César',
      'César Uribe',
      'CESAR RODRIGO URIBE ARAYA',
      'cesar_rodrigo_uribe_araya',
      'César Uribe Araya',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Celedón, Roberto',
      'Roberto Celedón',
      'ROBERTO ANTONIO CELEDON FERNANDEZ',
      'roberto_antonio_celedon_fernandez',
      'Roberto Antonio Celedón Fernández',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ChileDigno',
      nombre: 'Chile Digno',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Galleguillos, Félix',
      'Félix Galleguillos',
      'FELIX RAMON GALLEGUILLOS AYMANI',
      'felix_ramon_galleguillos_aymani',
      'Felix Ramon Galleguillos Aymani',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Larraín, Hernán',
      'Hernán Larraín',
      'HERNAN LARRAIN MATTE',
      'hernan_larrain_matte',
      'Hernán Larraín Matte',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Aguilera, Tiare',
      'Tiare Aguilera',
      'TIARE MAEVA CAROLINA AGUILERA HEY',
      'tiare_maeva_carolina_aguilera_hey',
      'Elsa Carolina Labraña Pino',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Laibe, Tomás',
      'Tomás Laibe',
      'TOMAS CALEB LAIBE SAEZ',
      'tomas_caleb_laibe_saez',
      'Tomas Caleb Laibe Saez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Zúñiga, Luis Arturo',
      'Luis Arturo Zúñiga',
      'LUIS ARTURO ZUÑIGA JORY',
      'luis_arturo_zuniga_jory',
      'Luis Arturo Zúñiga Jory',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Caamaño, Francisco',
      'Francisco Caamaño',
      'FRANCISCO JAVIER CAAMAÑO ROJAS',
      'francisco_javier_caamano_rojas',
      'Francisco Javier Caamaño Rojas',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Vargas, Margarita',
      'Margarita Vargas',
      'MARGARITA VIRGINIA VARGAS LOPEZ',
      'margarita_virginia_vargas_lopez',
      'Margarita Virginia Vargas Lopez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Salinas, Fernando',
      'Fernando Salinas',
      'FERNANDO CARLOS SALINAS MANFREDINI',
      'fernando_carlos_salinas_manfredini',
      'Fernando Salinas Manfredini',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Vergara, Lisette',
      'Lisette Vergara',
      'LISETTE LORENA VERGARA RIQUELME',
      'lisette_lorena_vergara_riquelme',
      'Lisette Lorena Vergara Riquelme',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Vega, Roberto',
      'Roberto Vega',
      'ROBERTO HERNAN CAMPUSANO VEGA',
      'roberto_hernan_campusano_vega',
      'Roberto Vega Campusano',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Tirado, Fernando',
      'Fernando Tirado',
      'FERNANDO DEL CARMEN TIRADO SOTO',
      'fernando_del_carmen_tirado_soto',
      'Rodrigo Logan Soto',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Hube, Constanza',
      'Constanza Hube',
      'CONSTANZA VERONICA HUBE PORTUS',
      'constanza_veronica_hube_portus',
      'Constanza Verónica Hube Portus',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Oyarzún, María José',
      'María José Oyarzún',
      'MARIA JOSE OYARZUN SOLIS',
      'maria_jose_oyarzun_solis',
      'María José Oyarzún Solis',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Rebolledo, Bárbara',
      'Bárbara Rebolledo',
      'BARBARA ALEJANDRA REBOLLEDO AGUIRRE',
      'barbara_alejandra_rebolledo_aguirre',
      'Barbara Rebolledo Aguirre',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Jofré, Alvaro',
      'Alvaro Jofré',
      'ALVARO ARTURO JOFRE CACERES',
      'alvaro_arturo_jofre_caceres',
      'Álvaro Jofré Cáceres',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Toloza, Pablo',
      'Pablo Toloza',
      'PABLO ANTONIO TOLOZA FERNANDEZ',
      'pablo_antonio_toloza_fernandez',
      'Pablo Antonio Toloza Fernandez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Labra, Patricia',
      'Patricia Labra',
      'PATRICIA FRANCISCA LABRA BESSERER',
      'patricia_francisca_labra_besserer',
      'Patricia Francisca Labra Besserer',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Politzer, Patricia',
      'Patricia Politzer',
      'PATRICIA ROSA POLITZER KEREKES',
      'patricia_rosa_politzer_kerekes',
      'Patricia Politzer Kerekes',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Portilla, Ericka',
      'Ericka Portilla',
      'ERICKA NATALIA PORTILLA BARRIOS',
      'ericka_natalia_portilla_barrios',
      'Ericka Natalia Portilla Barrios',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ChileDigno',
      nombre: 'Chile Digno',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Fontaine, Bernardo',
      'Bernardo Fontaine',
      'BERNARDO FONTAINE TALAVERA',
      'bernardo_fontaine_talavera',
      'Bernardo Fontaine Talavera',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Calvo, Carlos',
      'Carlos Calvo',
      'CARLOS MANUEL CALVO MUÑOZ',
      'carlos_manuel_calvo_munoz',
      'Carlos Manuel Calvo Muñoz',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Arrau, Martín',
      'Martín Arrau',
      'CARLOS MARTIN ARRAU GARCIA HUIDOBRO',
      'carlos_martin_arrau_garcia_huidobro',
      'Juan José Martin Bravo',
      'Martín Arrau García-Huidobro',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Garín, Renato',
      'Renato Garín',
      'RENATO FABRIZIO GARIN GONZALEZ',
      'renato_fabrizio_garin_gonzalez',
      'Renato Fabrizio Garín González',
    ],
    pacto: { __typename: 'Pacto', key: 'Otros', nombre: 'Otros' },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Bown, Carol',
      'Carol Bown',
      'CAROL CECILIA BOWN SEPULVEDA',
      'carol_cecilia_bown_sepulveda',
      'Carol Cecilia Bown Sepúlveda',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Vilches, Carolina',
      'Carolina Vilches',
      'CAROLINA CYNTIA VILCHES FUENZALIDA',
      'carolina_cyntia_vilches_fuenzalida',
      'Carolina Cyntia Vilches Fuenzalida',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Alvarez, Rodrigo',
      'Rodrigo Alvarez',
      'RODRIGO ALEJANDRO ALVAREZ ZENTENO',
      'rodrigo_alejandro_alvarez_zenteno',
      'Rodrigo Alejandro Álvarez Zenteno',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Jurgensen, Harry',
      'Harry Jurgensen',
      'HARRY ROLANDO JURGENSEN CAESAR',
      'harry_rolando_jurgensen_caesar',
      'Harry Jurgensen Caesar',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Gutiérrez, Hugo',
      'Hugo Gutiérrez',
      'HUGO HUMBERTO GUTIERREZ GALVEZ',
      'hugo_humberto_gutierrez_galvez',
      'Hugo Humberto Gutiérrez Gálvez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ChileDigno',
      nombre: 'Chile Digno',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Roa, Giovanna',
      'Giovanna Roa',
      'GIOVANNA ANGELA ROA CADIN',
      'giovanna_angela_roa_cadin',
      'Giovanna Ángela Roa Cadin',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Achurra, Ignacio',
      'Ignacio Achurra',
      'IGNACIO JAIME ACHURRA DIAZ',
      'ignacio_jaime_achurra_diaz',
      'Ignacio Jaime Achurra Díaz',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Marinovic, Teresa',
      'Teresa Marinovic',
      'TERESA MARGARITA NATALIA MARINOVIC VIAL',
      'teresa_margarita_natalia_marinovic_vial',
      'Teresa Margarita Natalia Marinovic Vial',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Fuchslocher, Javier',
      'Javier Fuchslocher',
      'JAVIER ALEJANDRO FUCHSLOCHER BAEZA',
      'javier_alejandro_fuchslocher_baeza',
      'Javier Fuchslocher Baeza',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Miranda, Valentina',
      'Valentina Miranda',
      'VALENTINA ANDREA MIRANDA ARCE',
      'valentina_andrea_miranda_arce',
      'Valentina Andrea Miranda Arce',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ChileDigno',
      nombre: 'Chile Digno',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Grandón, Giovanna',
      'Giovanna Grandón',
      'GIOVANNA JAZMIN GRANDON CARO',
      'giovanna_jazmin_grandon_caro',
      'Giovanna Jazmín Grandón Caro',
    ],
    pacto: { __typename: 'Pacto', key: 'Mixto', nombre: 'Mixto' },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Mayol, Luis',
      'Luis Mayol',
      'LUIS ALEJANDRO ABEL MAYOL BOUCHON',
      'luis_alejandro_abel_mayol_bouchon',
      'Luis Mayol Bouchon',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Bassa, Jaime',
      'Jaime Bassa',
      'JAIME ANDRES BASSA MERCADO',
      'jaime_andres_bassa_mercado',
      'Jaime Andrés Bassa Mercado',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Domínguez, Gaspar',
      'Gaspar Domínguez',
      'GASPAR ROBERTO DOMINGUEZ DONOSO',
      'gaspar_roberto_dominguez_donoso',
      'Gaspar Roberto Domínguez Donoso',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Navarrete, Geoconda',
      'Geoconda Navarrete',
      'GEOCONDA ELVIRA NAVARRETE ARRATIA',
      'geoconda_elvira_navarrete_arratia',
      'Geoconda Navarrete Arratia',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Ossandón, Manuel',
      'Manuel Ossandón',
      'MANUEL JOSE OSSANDON LIRA',
      'manuel_jose_ossandon_lira',
      'Manuel José Ossandón Lira',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Martín, Juan José',
      'Martin, Juan José',
      'Juan José Martín',
      'JUAN JOSE ANGEL SAN MARTIN BRAVO',
      'juan_jose_angel_san_martin_bravo',
      'Juan José Martin Bravo',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Woldarsky, Manuel',
      'Manuel Woldarsky',
      'MANUEL MAURICIO WOLDARSKY GONZALEZ',
      'manuel_mauricio_woldarsky_gonzalez',
      'Manuel Mauricio Woldarsky González',
    ],
    pacto: { __typename: 'Pacto', key: 'Mixto', nombre: 'Mixto' },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Silva, Luciano',
      'Luciano Silva',
      'LUCIANO ERNESTO SILVA MORA',
      'luciano_ernesto_silva_mora',
      'Luciano Ernesto Silva Mora',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Arellano, Marco',
      'Marco Arellano',
      'MARCO ANTONIO ARELLANO ORTEGA',
      'marco_antonio_arellano_ortega',
      'Marco Antonio Arellano Ortega',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Rivera, María Magdalena',
      'María Magdalena Rivera',
      'MARIA MAGDALENA RIVERA IRIBARREN',
      'maria_magdalena_rivera_iribarren',
      'María Magdalena Rivera Iribarren',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Schonhaut, Constanza',
      'Constanza Schonhaut',
      'CONSTANZA GABRIELA SCHONHAUT SOTO',
      'constanza_gabriela_schonhaut_soto',
      'Constanza Gabriela Schönhaut Soto',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Hurtado, Maximiliano',
      'Maximiliano Hurtado',
      'MAXIMILIANO ANDRES HURTADO ROCO',
      'maximiliano_andres_hurtado_roco',
      'Maximiliano Andrés Hurtado Roco',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Flores, Alejandra',
      'Alejandra Flores',
      'ALEJANDRA ALICIA FLORES CARLOS',
      'alejandra_alicia_flores_carlos',
      'Alejandra Alicia Flores Carlos',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Chinga, Eric',
      'Eric Chinga',
      'ERIC JOHANNY CHINGA FERREIRA',
      'eric_johanny_chinga_ferreira',
      'Roberto Antonio Celedón Fernández',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Carrillo, Alondra',
      'Alondra Carrillo',
      'ALONDRA CARRILLO VIDAL',
      'alondra_carrillo_vidal',
      'Alondra Carrillo Vidal',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Botto, Miguel Ángel',
      'Miguel Ángel Botto',
      'MIGUEL ANGEL BOTTO SALINAS',
      'miguel_angel_botto_salinas',
      'Miguel Ángel Botto Salinas',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoDelApruebo',
      nombre: 'Colectivo del Apruebo',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Henríquez, Natalia',
      'Natalia Henríquez',
      'NATALIA ESTHER HENRIQUEZ CARREÑO',
      'natalia_esther_henriquez_carreno',
      'Natalia Esther Henríquez Carreño',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Núñez, Nicolás',
      'Nicolás Núñez',
      'NICOLAS FERNANDO NUÑEZ GANGAS',
      'nicolas_fernando_nunez_gangas',
      'Nicolás Fernando Nuñez Gangas',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ChileDigno',
      nombre: 'Chile Digno',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Cruz, Andrés',
      'Andrés Cruz',
      'ANDRES NORBERTO CRUZ CARRASCO',
      'andres_norberto_cruz_carrasco',
      'Andrés Norberto Cruz Carrasco',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Sepúlveda, Bárbara',
      'Bárbara Sepúlveda',
      'BARBARA SEPULVEDA HALES',
      'barbara_sepulveda_hales',
      'Bárbara Sepulveda Hales',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ChileDigno',
      nombre: 'Chile Digno',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Cretton, Eduardo',
      'Eduardo Cretton',
      'EDUARDO ANDRES CRETTON REBOLLEDO',
      'eduardo_andres_cretton_rebolledo',
      'Eduardo Andrés Cretton Rebolledo',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Baranda, Benito',
      'Benito Baranda',
      'BENITO JOSE BARANDA FERRAN',
      'benito_jose_baranda_ferran',
      'Benito Baranda Ferrán',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Muñoz, Pedro',
      'Pedro Muñoz',
      'PEDRO ENRIQUE LEIVA MUÑOZ',
      'pedro_enrique_leiva_munoz',
      'Pedro David Muñoz Leiva',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Rivera, Pollyana',
      'Pollyana Rivera',
      'POLLYANA DUENDY ALEJANDRA RIVERA BIGAS',
      'pollyana_duendy_alejandra_rivera_bigas',
      'Pollyana Duendy Alejandra Rivera Bigas',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Letelier, Margarita',
      'Margarita Letelier',
      'CLAUDIA MARGARITA LETELIER CORTES',
      'claudia_margarita_letelier_cortes',
      'Manuela Royo Letelier',
      'Margarita Letelier Cortés',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Neumann, Ricardo',
      'Ricardo Neumann',
      'RICARDO ANDRES NEUMANN BERTIN',
      'ricardo_andres_neumann_bertin',
      'Ricardo Andrés Neumann Bertín',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Castro, Claudia',
      'Claudia Castro',
      'CLAUDIA MABEL CASTRO GUTIERREZ',
      'claudia_mabel_castro_gutierrez',
      'Claudia Mabel Castro Gutiérrez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Rojas, Rodrigo',
      'Rodrigo Rojas',
      'RODRIGO ERNESTO ROJAS VADE',
      'rodrigo_ernesto_rojas_vade',
      'Rodrigo Ernesto Rojas Vade',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Namor, Guillermo',
      'Guillermo Namor',
      'GUILLERMO NICOLAS NAMOR KONG',
      'guillermo_nicolas_namor_kong',
      'Guillermo Nicolás Namor Kong',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Catrileo, Rosa',
      'Rosa Catrileo',
      'ROSA ELIZABETH CATRILEO ARIAS',
      'rosa_elizabeth_catrileo_arias',
      'Rosa Elizabeth Catrileo Arias',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Velásquez, Hernán',
      'Hernán Velásquez',
      'HERNAN JESUS VELASQUEZ NUÑEZ',
      'hernan_jesus_velasquez_nunez',
      'Hernán Jesús Velásquez Núñez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ChileDigno',
      nombre: 'Chile Digno',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Hurtado, Ruth',
      'Ruth Hurtado',
      'RUTH ESTER HURTADO OLAVE',
      'ruth_ester_hurtado_olave',
      'Ruth Hurtado Olave',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Madriaga, Tania',
      'Tania Madriaga',
      'TANIA ISABEL MADRIAGA FLORES',
      'tania_isabel_madriaga_flores',
      'Tania Isabel Madriaga Flores',
    ],
    pacto: { __typename: 'Pacto', key: 'Mixto', nombre: 'Mixto' },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Urrutia, Tatiana',
      'Tatiana Urrutia',
      'TATIANA KARINA URRUTIA HERRERA',
      'tatiana_karina_urrutia_herrera',
      'Tatiana Karina Urrutia Herrera',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Quinteros, María Elisa',
      'María Elisa Quinteros',
      'MARIA ELISA QUINTEROS CACERES',
      'maria_elisa_quinteros_caceres',
      'María Elisa Quinteros Cáceres',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Godoy, Isabel',
      'Isabel Godoy',
      'ISABEL SELENA GODOY MONARDEZ',
      'isabel_selena_godoy_monardez',
      'Isabel Selena Godoy Monárdez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Bacian, Wilfredo',
      'Wilfredo Bacian',
      'WILFREDO MANUEL BACIAN DELGADO',
      'wilfredo_manuel_bacian_delgado',
      'Wilfredo Manuel Bacian Delgado',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Gómez, Yarela',
      'Yarela Gómez',
      'YARELA NICOHL GOMEZ SANCHEZ',
      'yarela_nicohl_gomez_sanchez',
      'Yarela Nicohl Gómez Sánchez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Arancibia, Jorge',
      'Jorge Arancibia',
      'JORGE SEGUNDO ARANCIBIA REYES',
      'jorge_segundo_arancibia_reyes',
      'Jorge Patricio Arancibia Reyes',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Barraza, Marcos',
      'Marcos Barraza',
      'MARCOS PATRICIO BARRAZA GOMEZ',
      'marcos_patricio_barraza_gomez',
      'Marcos Patricio Barraza Gómez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ChileDigno',
      nombre: 'Chile Digno',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Céspedes, Lorena',
      'Lorena Céspedes',
      'LORENA DEL PILAR CESPEDES FERNANDEZ',
      'lorena_del_pilar_cespedes_fernandez',
      'Lorena del Pilar Céspedes Fernández',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Serey, Mariela',
      'Mariela Serey',
      'MARIELA ANDREA SEREY JIMENEZ',
      'mariela_andrea_serey_jimenez',
      'Mariela Andrea Serey Jiménez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Castillo, María Trinidad',
      'María Trinidad Castillo',
      'MARIA TRINIDAD CASTILLO BOILET',
      'maria_trinidad_castillo_boilet',
      'Maria Trinidad Castillo Boilet',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Vargas, Mario',
      'Mario Vargas',
      'MARIO EDUARDO VARGAS VIDAL',
      'mario_eduardo_vargas_vidal',
      'Mario Ruben Vargas Vidal',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'San Juan, Constanza',
      'Constanza San Juan',
      'CONSTANZA ANDREA SAN JUAN STANDEN',
      'constanza_andrea_san_juan_standen',
      'Constanza Andrea San Juan Standen',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Moreno, Alfredo',
      'Alfredo Moreno',
      'ALFREDO MORENO ECHEVERRIA',
      'alfredo_moreno_echeverria',
      'Alfredo Moreno Echeverría',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'González, Dayana',
      'González, Dayyana',
      'Dayana González',
      'DAYYANA ALEJANDRA GONZALEZ ARAYA',
      'dayyana_alejandra_gonzalez_araya',
      'Dayyana Alejandra González Araya',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Sánchez, Beatriz',
      'Beatriz Sánchez',
      'BEATRIZ DE JESUS SANCHEZ MUÑOZ',
      'beatriz_de_jesus_sanchez_munoz',
      'Beatriz de Jesús Sánchez Muñoz',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Abarca, Damaris',
      'Damaris Abarca',
      'DAMARIS DANIELA GONZALEZ ABARCA',
      'damaris_daniela_gonzalez_abarca',
      'Damaris Nicole Abarca González',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Saldaña, Alvin',
      'Alvin Saldaña',
      'ALVIN ANTONIO SALDAÑA MUÑOZ',
      'alvin_antonio_saldana_munoz',
      'Alvin Antonio Saldaña Muñoz',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Grandón, Paola',
      'Paola Grandón',
      'PAOLA ALEJANDRA GONZALEZ GRANDON',
      'paola_alejandra_gonzalez_grandon',
      'Paola Alejandra Grandón González',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ChileDigno',
      nombre: 'Chile Digno',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'De la Maza, Bernardo',
      'Bernardo De la Maza',
      'BERNARDO ARTURO DE LA MAZA BAÑADOS',
      'bernardo_arturo_de_la_maza_banados',
      'Bernardo de la Maza Bañados',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Labbé, Bastián',
      'Bastián Labbé',
      'BASTIAN ESTEBAN LABBE SALAZAR',
      'bastian_esteban_labbe_salazar',
      'Bastián Esteban Labbé Salazar',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Fernández, Patricio',
      'Patricio Fernández',
      'PATRICIO HERMAN FERNANDEZ CHADWICK',
      'patricio_herman_fernandez_chadwick',
      'Patricio Herman Fernández Chadwick',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Veloso, Paulina',
      'Paulina Veloso',
      'PAULINA NATALIA VELOSO MUÑOZ',
      'paulina_natalia_veloso_munoz',
      'Paulina Veloso Muñoz',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Valenzuela, Paulina',
      'Paulina Valenzuela',
      'PAULINA VALERIA VALENZUELA RIO',
      'paulina_valeria_valenzuela_rio',
      'Paulina Valenzuela Río',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Valenzuela, César',
      'César Valenzuela',
      'CESAR IGNACIO VALENZUELA MAASS',
      'cesar_ignacio_valenzuela_maass',
      'César Valenzuela Maass',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Montero, Ricardo',
      'Ricardo Montero',
      'RICARDO IGNACIO MONTERO ALLENDE',
      'ricardo_ignacio_montero_allende',
      'Ricardo Ignacio Montero Allende',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Alvarado, Gloria',
      'Gloria Alvarado',
      'GLORIA DEL TRANSITO ALVARADO JORQUERA',
      'gloria_del_transito_alvarado_jorquera',
      'Gloria del Transito Alvarado Jorquera',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Mamani, Isabella',
      'Isabella Mamani',
      'ISABELLA BRUNILDA MAMANI MAMANI',
      'isabella_brunilda_mamani_mamani',
      'Tania Isabel Madriaga Flores',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Olivares, Ivanna',
      'Ivanna Olivares',
      'IVANNA DANIELA OLIVARES MIRANDA',
      'ivanna_daniela_olivares_miranda',
      'Ivanna Daniela Olivares Miranda',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Hoppe, Vanessa',
      'Vanessa Hoppe',
      'VANESSA CAMILA HOPPE ESPOZ',
      'vanessa_camila_hoppe_espoz',
      'Vanessa Camila Hoppe Espoz',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Antilef, Victorino',
      'Victorino Antilef',
      'VICTORINO ERNESTO ANTILEF ÑANCO',
      'victorino_ernesto_antilef_nanco',
      'Victorino Ernesto Antilef Ñanco',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Arauna, Francisca',
      'Francisca Arauna',
      'FRANCISCA MARYCARMEN ARAUNA URRUTIA',
      'francisca_marycarmen_arauna_urrutia',
      'Francisca Marycarmen Arauna Urrutia',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Mella, Jeniffer',
      'Jeniffer Mella',
      'JENIFFER VALERIA MELLA ESCOBAR',
      'jeniffer_valeria_mella_escobar',
      'Jennifer Valeria Mella Escobar',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Jiménez, Luis',
      'Luis Jiménez',
      'LUIS ALBERTO JIMENEZ CACERES',
      'luis_alberto_jimenez_caceres',
      'Luis Mayol Bouchon',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Chahín, Fuad',
      'Fuad Chahín',
      'FUAD EDUARDO CHAHIN VALENZUELA',
      'fuad_eduardo_chahin_valenzuela',
      'Fuad Chahin Valenzuela',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoDelApruebo',
      nombre: 'Colectivo del Apruebo',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Meneses, Janis',
      'Janis Meneses',
      'JANIS JAN DEL CARMEN MENESES PALMA',
      'janis_jan_del_carmen_meneses_palma',
      'Janis Jan del Carmen Meneses Palma',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Pinto, Malucha',
      'Malucha Pinto',
      'MALUCHA PINTO SOLARI',
      'malucha_pinto_solari',
      'Malucha Pinto Solari',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Abarca, Jorge',
      'Jorge Abarca',
      'JORGE BRUNO ABARCA RIVEROS',
      'jorge_bruno_abarca_riveros',
      'Jorge Bruno Abarca Riveros',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Alvarez, Julio',
      'Julio Alvarez',
      'JULIO JOSE LUIS ALVAREZ PINTO',
      'julio_jose_luis_alvarez_pinto',
      'Julio José Luis Álvarez Pinto',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Vallejos, Loreto',
      'Loreto Vallejos',
      'LORETO CRISTINA VALLEJOS DAVILA',
      'loreto_cristina_vallejos_davila',
      'Loreto Cristina Vallejos Dávila',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Ubilla, María Cecilia',
      'María Cecilia Ubilla',
      'MARIA CECILIA UBILLA PEREZ',
      'maria_cecilia_ubilla_perez',
      'María Cecilia Ubilla Pérez',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'VamosPorChile',
      nombre: 'Vamos por Chile',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Reyes, María Ramona',
      'María Ramona Reyes',
      'MARIA RAMONA REYES PAINEQUEO',
      'maria_ramona_reyes_painequeo',
      'Maria Ramona Reyes Painequeo',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Pérez, Alejandra',
      'Alejandra Pérez',
      'ALEJANDRA PIA PEREZ ESPINA',
      'alejandra_pia_perez_espina',
      'Alejandra Pía Pérez Espina',
    ],
    pacto: { __typename: 'Pacto', key: 'Mixto', nombre: 'Mixto' },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Daza, Mauricio',
      'Mauricio Daza',
      'MAURICIO ANTONIO DAZA CARRASCO',
      'mauricio_antonio_daza_carrasco',
      'Mauricio Antonio Daza Carrasco',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Ampuero, Adriana',
      'Adriana Ampuero',
      'ADRIANA CAMILA AMPUERO BARRIENTOS',
      'adriana_camila_ampuero_barrientos',
      'Adriana Camila Ampuero Barrientos',
    ],
    pacto: { __typename: 'Pacto', key: 'Otros', nombre: 'Otros' },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Dorador, Cristina',
      'Cristina Dorador',
      'CRISTINA INES DORADOR ORTIZ',
      'cristina_ines_dorador_ortiz',
      'Cristina Inés Dorador Ortiz',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'MovimientosSocialesConstituyentes',
      nombre: 'Movimientos Sociales Constituyentes',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Castillo, Eduardo',
      'Eduardo Castillo',
      'EDUARDO GUILLERMO CASTILLO VIGOUROUX',
      'eduardo_guillermo_castillo_vigouroux',
      'Eduardo Guillermo Castillo Vigouroux',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoDelApruebo',
      nombre: 'Colectivo del Apruebo',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Loncon, Elisa',
      'Elisa Loncon',
      'ELISA DEL CARMEN LONCON ANTILEO',
      'elisa_del_carmen_loncon_antileo',
      'Elisa Loncón Antileo',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Llanquileo, Natividad',
      'Natividad Llanquileo',
      'NATIVIDAD DEL CARMEN LLANQUILEO PILQUIMAN',
      'natividad_del_carmen_llanquileo_pilquiman',
      'Janis Jan del Carmen Meneses Palma',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PueblosIndigenas',
      nombre: 'Pueblos Indígenas',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Alvez, Amaya',
      'Amaya Alvez',
      'AMAYA PAULINA ALVEZ MARIN',
      'amaya_paulina_alvez_marin',
      'Amaya Paulina Alvez Marín',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Stingo, Daniel',
      'Daniel Stingo',
      'DANIEL RODRIGO STINGO CAMUS',
      'daniel_rodrigo_stingo_camus',
      'Daniel Rodrigo Stingo Camus',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'FrenteAmplio',
      nombre: 'Frente Amplio',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Zárate, Camila',
      'Camila Zárate',
      'CAMILA IGNACIA SOULE ZARATE',
      'camila_ignacia_soule_zarate',
      'Camila Ignacia Zárate Zárate',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'PuebloConstituyente',
      nombre: 'Pueblo Constituyente',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Gallardo, Bessy',
      'Bessy Gallardo',
      'BESSY MIREYA DEL ROSARIO GALLARDO PRADO',
      'bessy_mireya_del_rosario_gallardo_prado',
      'Bessy Mireya Del Rosario Gallardo Prado',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoDelApruebo',
      nombre: 'Colectivo del Apruebo',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Celis, Raúl',
      'Raúl Celis',
      'RAUL EDUARDO CELIS MONTT',
      'raul_eduardo_celis_montt',
      'Raúl Celis Montt',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesRNE',
      nombre: 'Independientes RN-Evópoli',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Sepúlveda, Carolina',
      'Carolina Sepúlveda',
      'CAROLINA ANDREA SEPULVEDA CASTILLO',
      'carolina_andrea_sepulveda_castillo',
      'Bárbara Sepulveda Hales',
      'Carolina Andrea Sepúlveda Sepúlveda',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'IndependientesNuevaConstitucion',
      nombre: 'Independientes por la Nueva Constitución',
    },
  },
  {
    __typename: 'Convencional',
    lookups: [
      'Gómez, Claudio',
      'Claudio Gómez',
      'CLAUDIO FRANCISCO GOMEZ CASTRO',
      'claudio_francisco_gomez_castro',
      'Claudio Andrés Gómez Castro',
    ],
    pacto: {
      __typename: 'Pacto',
      key: 'ColectivoSocialista',
      nombre: 'Colectivo Socialista',
    },
  },
]

export default convencionales
