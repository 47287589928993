import { LeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { spaces } from 'Constants'
import Division from './Division'
import ResumenSesion from './ResumenSesion'
import TranscriptionContent from './TranscriptionContent'

export default function Desktop({
  editMode,
  filename,
  goto,
  handleBackButtonClick,
  isPlaying,
  mergedTranscriptions,
  playedSeconds,
  playerRef,
  primaryColor,
  sesion,
  setIsLoaded,
  setPlayedSeconds,
  setShowResumenSesion,
  showResumenSesion,
  transcripcionRef,
}) {
  return (
    <Wrapper>
      <LeftOutlined
        onClick={() => handleBackButtonClick()}
        style={{ color: primaryColor }}
      />
      {showResumenSesion && (
        <ResumenSesion
          sesion={sesion}
          editMode={editMode}
          transcripciones={mergedTranscriptions}
          filename={filename}
        />
      )}
      <Division
        showResumenSesion={showResumenSesion}
        setShowResumenSesion={setShowResumenSesion}
      />
      <TranscriptionContent
        goto={goto}
        isPlaying={isPlaying}
        origen={sesion.origen}
        playerRef={playerRef}
        playedSeconds={playedSeconds}
        transcripciones={mergedTranscriptions}
        transcripcionRef={transcripcionRef}
        setIsLoaded={setIsLoaded}
        setPlayedSeconds={setPlayedSeconds}
        url={sesion.video_url}
        sesion={sesion}
        editMode={editMode}
        filename={filename}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  gap: ${spaces.xs};
`
