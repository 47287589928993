import { gql, useQuery } from '@apollo/client'

const PACTOS = gql`
  query getPactos($space_name: String!, $tipo: String!) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          pactos(tipo: $tipo) {
            pageInfo {
              nextOffset
              size
              totalCount
            }
            results {
              nombre
            }
          }
        }
      }
    }
  }
`

interface usePactosParams {
  spaceName: string
  tipo: string
}

const usePactos = (variables: usePactosParams) => {
  const { data: pactosData } = useQuery(PACTOS, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables,
  })

  const pactos = pactosData?.me?.organizacion?.espacio?.pactos?.results || []

  const adaptedPartidos = pactos.map(({ nombre }) => ({
    text: nombre,
    value: nombre,
  }))

  return { pactosArr: adaptedPartidos }
}

export default usePactos
