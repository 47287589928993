import { colors } from 'Constants'

const splitByIncisos = (content) => {
  // eslint-disable-next-line max-len
  const incisoPattern =
    '\\s*\\([\\s\\w]*[inciso|Inciso|INCISO|incisos| ][\\s\\w,]*\\)(:*[\\.-]*)\\s*'
  const incisoRegex = new RegExp(incisoPattern, 'g')
  return content.replace(incisoRegex, '\n')
}

const filterSpecialCharacters = (characters) => {
  const undesiredCharactersPattern = '!(^[\\s]*:[\\s]*|(^(?!.))|^(\\.-)+)'
  const charactersToFilter = new RegExp(undesiredCharactersPattern, 'g')
  return characters.replace(charactersToFilter, '')
}

export const removeSpaces = (characters) => {
  const newLinePattern = '\\r?\\n'
  const newLineRegex = new RegExp(newLinePattern, 'g')
  const joinUsingNewLines = characters.replace(newLineRegex, '')
  return joinUsingNewLines
}

export const parseContent = (content) => {
  const splitContent = splitByIncisos(content)
  const filterContent = filterSpecialCharacters(splitContent)
  return filterContent
}

export const trimHighlightedParagraph = ({ content, len, offset = 200, start }) => {
  const startIndex = start - offset
  const finishIndex = start + len + offset
  const startOffset = startIndex > 0 ? startIndex : 0
  const finishOffset = finishIndex < content.length ? finishIndex : content.length - 1
  const trimmedContent = content.slice(startOffset, finishOffset)
  const newStart = (startOffset > 0 ? offset : start) + 3
  return { content: trimmedContent, start: newStart }
}

export const parseHighlightedContent = ({ contenido, highlights = [] }) => {
  const newLine = parseContent(contenido)

  return {
    content: newLine,
    highlights: highlights.map((highlight) => {
      const documentoId = highlight.match.documento_id.split('articulos_')[1]
      return {
        ...highlight,
        color: colors.constitutions[documentoId],
        finish: highlight.start + highlight.len,
        match: {
          ...highlight.match,
          documento_id: documentoId,
        },
      }
    }),
  }
}
