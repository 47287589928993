import React from 'react'
import { Flags } from 'react-feature-flags'

import ComisionList from 'components/Comisiones/ComisionList'

function ComisionDiputadosList() {
  return (
    <Flags authorizedFlags={['comisiones']}>
      <ComisionList
        space="Diputados"
        tipo="Cámara de Diputadas y Diputados"
      />
    </Flags>
  )
}

export default ComisionDiputadosList
