import React from 'react'
import { Flags } from 'react-feature-flags'

import Wrapper from 'components/Content'
import SesionList from 'components/sesiones/SesionList'

function SesionConvencionalList() {
  return (
    <Wrapper>
      <Flags authorizedFlags={['convencionales']}>
        <SesionList
          space="Convencionales"
          tipo="convencional"
        />
      </Flags>
    </Wrapper>
  )
}

export default SesionConvencionalList
