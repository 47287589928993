import { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Input, Row, Space } from 'antd'
import styled from 'styled-components'

import { toNormalForm } from 'utils'

const checklistOptionsFilter = (input, checklistOptions, setChecklistOptions) => {
  const filteredOptions = []
  checklistOptions.forEach((option) => {
    if (toNormalForm(option.text).includes(toNormalForm(input))) {
      filteredOptions.push(option)
    }
  })
  setChecklistOptions(filteredOptions)
}

function CustomCheckbox({ option }) {
  return (
    <Col>
      <Row span={8}>
        <Checkbox value={option.value}>{option.text}</Checkbox>
      </Row>
    </Col>
  )
}

function DropdownCheckboxes({ checkedOptions, checklistOptions, handleChange }) {
  const optionValues = checklistOptions.reduce((a, b) => {
    a.push(b.value)
    return a
  }, [])
  const previousInputSelected = checkedOptions.filter((opt) => !optionValues.includes(opt))
  return (
    <StyledCheckboxGroup
      onChange={(currentInputSelected) => {
        handleChange([...previousInputSelected, ...currentInputSelected])
      }}
      value={checkedOptions}
    >
      {checklistOptions.map((option) => (
        <CustomCheckbox
          key={option.value}
          option={option}
        />
      ))}
    </StyledCheckboxGroup>
  )
}

function DropdownSearch({
  checkedOptions = [],
  checklistOptions,
  handleClear,
  handleFilter,
  width,
}) {
  const [filteredChecklistOptions, setFilteredChecklistOptions] = useState(checklistOptions)
  const [selectedChecklistOptions, setSelectedChecklistOptions] = useState(checkedOptions)
  const reset = () => {
    setSelectedChecklistOptions([])
    handleClear()
  }
  return (
    <StyledDropdown style={{ width: width || '205px' }}>
      <Input
        onChange={(e) =>
          checklistOptionsFilter(e.target.value, checklistOptions, setFilteredChecklistOptions)
        }
        placeholder="Buscar..."
        style={{ display: 'blok', marginBottom: 8 }}
      />

      <DropdownCheckboxes
        checklistOptions={filteredChecklistOptions}
        checkedOptions={selectedChecklistOptions}
        handleChange={setSelectedChecklistOptions}
      />

      <StyledButtons>
        <Button
          icon={<SearchOutlined />}
          onClick={() => {
            if (selectedChecklistOptions.length > 0) {
              handleFilter(selectedChecklistOptions)
            } else {
              reset()
            }
          }}
          size="small"
          style={{ width: 90 }}
          type="primary"
        >
          Buscar
        </Button>
        <Button
          onClick={reset}
          size="small"
          style={{ width: 90 }}
        >
          Reiniciar
        </Button>
      </StyledButtons>
    </StyledDropdown>
  )
}

export default function FilterSearch({
  currentFiltersDict,
  field,
  optionsArr,
  setQueryFilters,
  updateFilters,
  width,
}) {
  return (
    optionsArr && (
      <DropdownSearch
        handleClear={() => updateFilters(currentFiltersDict, setQueryFilters, [field])}
        handleFilter={(selectedPartidos) => {
          updateFilters(
            {
              ...currentFiltersDict,
              [field]: selectedPartidos,
            },
            setQueryFilters
          )
        }}
        checklistOptions={optionsArr}
        checkedOptions={currentFiltersDict[field] ? currentFiltersDict[field] : []}
        width={width}
      />
    )
  )
}

const StyledCheckboxGroup = styled(Checkbox.Group)`
  max-height: 150px;
  overflow-y: scroll;
  width: 100%;
  display: block !important;
`
const StyledDropdown = styled.div`
  padding: 8px;
`

const StyledButtons = styled(Space)`
  padding-top: 8px;
`
