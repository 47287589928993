const INTERVALS = [3600, 60, 1]

export const toSeconds = (timecode) =>
  timecode
    .split(':')
    .map((x, i) => Number.parseInt(x, 10) * INTERVALS[i])
    .reduce((a, b) => a + b)

export const getGuestsObj = (transcripciones) => {
  const uniqueNames = [
    ...new Set(
      transcripciones
        .filter((transcript) => !transcript.hablante_id)
        .map((transcript) => transcript.hablante_nombre)
    ),
  ]
  return uniqueNames.reduce((accumulator, currentValue, index) => {
    const keywords = ['speaker', 'hablante', '']
    const currentValueLower = currentValue.toLowerCase()
    const isUnknown = keywords.some((keyword) => currentValueLower.includes(keyword))

    accumulator[currentValue] = isUnknown ? `Hablante ${index + 1}` : currentValue
    return accumulator
  }, {})
}

export const isFromYoutube = (url) => url?.startsWith('https://www.youtube.com')

export const getFilenameFromVideoURL = (videoURL) =>
  decodeURIComponent(videoURL.split('.mp4')[0].split('/').pop())
