import { Empty } from 'antd'
import styled from 'styled-components'

import StyledTable from 'components/StyledTable'

const { Column } = StyledTable

function DetalleIndicaciones() {
  const data = []
  const indicacionesData = data
  return data ? (
    <StyledTable
      dataSource={indicacionesData}
      scroll={{ y: 400 }}
    >
      <Column
        key="boletin"
        title="Nº Boletín"
        render={({ boletin }) => (
          <StyledBoletin
            href=""
            target="_blank"
          >
            {boletin}
          </StyledBoletin>
        )}
      />
      <Column
        width="40%"
        dataIndex="titulo"
        key="titulo"
        title="Título"
      />
      <Column
        dataIndex="fecha"
        key="fecha"
        title="Fecha"
        render={(fecha) => fecha.split(' ')[0]}
        align="right"
        width={125}
        sorter
      />
      <Column
        dataIndex="estado"
        key="estado"
        title="Estado"
      />
    </StyledTable>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )
}

const StyledBoletin = styled.a`
  color: black !important;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-decoration: underline !important;
`

export default DetalleIndicaciones
