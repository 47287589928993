import { colors } from 'Constants'
import legend from 'views/AuthenticatedSite/Convencionales/Comparador/legend'

export default {
  ...legend,
  texto_definitivo: {
    color: colors.constitutions.texto_definitivo,
    href: 'https://d3tks6n15ycs13.cloudfront.net/textos/Texto-Definitivo-CPR-2022-Tapas.pdf',
    label: 'Propuesta Convención 2022',
  },
}
