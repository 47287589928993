import { useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import styled from 'styled-components'

import { parseHighlightedContent } from 'components/comparador/parser'
import HighlightedText from 'components/HighlightedText'
import HighlightTooltip from 'views/AuthenticatedSite/Articulos/HighlightTooltip'
import { isHighlightActive } from './helpers'
import Matches from './Matches'
import ComparatorModal from './Modal'

const ARTICULOS = (origin) => gql`
  query Articulos($ids: [ArticulosByIds!]) {
    ${origin}(
      ids: $ids
      pagination: {
        from: 0
        size: 9999
        sortKey: "fecha"
        sortOrder: "desc"
        filters: []
        search: ""
      }
    ) {
      results {
        nombre
        contenido
        documentoId
        articuloId
      }
    }
  }
`

export default function ComparadorCard({
  contenido,
  highlights,
  legend,
  nombre,
  origin,
  textTitle,
  titulo,
}) {
  const [activeHighlights, setActiveHighlights] = useState([])
  const [activeHighlightedContent, setActiveHighlightedContent] = useState([])
  const [modalActive, setModalActive] = useState(false)
  const highlightedContent = parseHighlightedContent({
    contenido,
    highlights,
  })

  const [articulosQuery, { data: comparedArticulos }] = useLazyQuery(ARTICULOS(origin))

  const aHighlightedContent = (selectedHighlights) => ({
    ...highlightedContent,
    highlights: highlightedContent.highlights.filter(({ finish, start }) =>
      isHighlightActive({ activeHighlights: selectedHighlights, finish, start })
    ),
  })

  return (
    <Wrapper>
      {titulo && <Text>{titulo}</Text>}
      <Text>{nombre}</Text>
      <ComparatorModal
        closeModal={() => setModalActive(false)}
        comparedArticulos={comparedArticulos?.[origin]?.results}
        highlightedContent={activeHighlightedContent}
        legend={legend}
        nombre={nombre}
        titulo={titulo}
        open={modalActive}
        baseTextTitle={textTitle}
      />
      <HighlightedText
        highlightWrapper={{
          component: HighlightTooltip,
          props: {
            content: (
              <Matches
                activeHighlights={activeHighlights}
                matches={highlightedContent.highlights}
                legend={legend}
              />
            ),
          },
        }}
        onClick={({ highlightedIds }) => {
          const aHighlightContent = aHighlightedContent(highlightedIds)
          setActiveHighlightedContent(aHighlightContent)
          setModalActive(true)
          const params = {
            variables: {
              ids: aHighlightContent.highlights.map(({ match }) => ({
                articuloId: match.file_path.replace('.json', '').replace('.yml', ''),
                documentoId: match.documento_id,
              })),
            },
          }
          articulosQuery(params)
        }}
        onHoverChange={(newActiveHighlights) => {
          if (newActiveHighlights.length) setActiveHighlights(newActiveHighlights)
        }}
        content={highlightedContent.content}
        highlights={highlightedContent.highlights}
      >
        {highlightedContent.content}
      </HighlightedText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.p`
  font-weight: bold;
  font-size: 1.1em;
`
