import { Avatar } from 'antd'
import styled from 'styled-components'

import { colors } from 'Constants'

export default styled(Avatar).attrs((props) => ({
  size: props.size || 60, // Defaults size to 60px
}))`
  border: 2px solid ${(props) => props.color || colors.default};
  cursor: default;
  display: block;

  img {
    object-fit: fill;
  }
`
