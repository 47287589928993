import { Menu as BaseMenu } from 'antd'
import { PoweredUnholster } from 'assets'
import { useEnvironment } from 'components/Fetch'
import useConfig from 'config/configAdapter/useConfig'
import { colors, spaces as cSpaces } from 'Constants'
import { styled } from 'styled-components'

import getMenuItems from './getMenuItems'
import Logo from './Logo'

const openUnholsterSite = () => window.open('https://unholster.com/', '_blank')

interface SideContentProps {
  onClose?: () => void
}

export default function SideContent({ onClose }: SideContentProps) {
  const { spaces } = useEnvironment()
  const { antdStyles, flags = {}, primaryColor, watermarkPath } = useConfig()
  const menuIconColor = antdStyles.styles.components?.Menu?.colorIcon

  const config = useConfig()

  return (
    <Wrapper $watermark={watermarkPath}>
      <Logo />
      <Menu
        theme="light"
        mode="inline"
        items={getMenuItems({ config, flags, spaces })}
        primarycolor={primaryColor}
        menuiconcolor={menuIconColor}
        onClick={onClose}
      />
      <UnholsterFooter onClick={openUnholsterSite} />
    </Wrapper>
  )
}

const Menu = styled(BaseMenu)<{ primarycolor: string; menuiconcolor?: string }>`
  border-inline-end: none !important;
  width: 200px;

  .ant-menu-item {
    padding: 10px;
    align-items: start !important;
    padding-left: 35px !important;
    border-radius: 0;
    margin: 0 0 0 0;
    background: #fff;
    width: 100%;
    height: 100%;
  }

  .anticon,
  .menu-link {
    color: ${colors.menuUnselectedItem};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    overflow: auto;
    text-wrap: wrap;
  }

  && {
    .anticon {
      color: ${colors.paragraphFont};
      margin-top: 6px;
    }
  }

  .ant-menu-item-group-title {
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 0.075em;
    color: ${colors.paragraphFont};
  }

  .ant-menu-title-content {
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 0.075em;
    line-height: 22px;
    color: ${colors.paragraphFont};
  }

  .ant-menu-submenu-arrow {
    color: ${colors.paragraphFont};
  }

  .ant-menu-submenu {
    background-color: white !important;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active {
    color: ${({ primarycolor }) => primarycolor};
    background-color: ${colors.menuSelectedItemBackground} !important;
    .menu-link {
      color: ${({ primarycolor }) => primarycolor};
    }

    a {
      color: ${({ primarycolor }) => primarycolor} !important;
    }
    path {
      stroke-width: 1.3;
      stroke: ${({ menuiconcolor }) => menuiconcolor} !important;
      fill: ${({ menuiconcolor }) => menuiconcolor} !important;
    }
  }

  .custom-icon {
    svg {
      color: #fff;
    }
    path {
      stroke-width: 1.3;
      stroke: ${colors.paragraphFont};
    }
  }
`

const UnholsterFooter = styled(PoweredUnholster)`
  align-self: center;
  justify-self: center;
  cursor: pointer;
  margin: ${cSpaces.l} auto;
  width: 50%;
`

const Wrapper = styled.div<{ $watermark?: string }>`
  background-image: url(${({ $watermark }) => $watermark || 'none'});
  background-repeat: no-repeat;
  background-position: 50% 80%;
  background-size: auto;
  height: 100%;
  display: grid;
  grid: min-content 1fr min-content / 1fr;
  border-right: 1px solid ${colors.backgroundDarkGrey};
  overflow-y: auto;
`
