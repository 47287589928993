import { PageHeader } from '@ant-design/pro-layout'
import styled from 'styled-components'

export default styled(PageHeader)`
  padding: 0;
  min-width: 350px;

  .ant-page-header-heading {
    width: 100%;
  }
  .ant-page-header-heading-title {
    font-weight: 400;
    white-space: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  .ant-page-header-content {
    padding-block-start: 0;
  }
`
