import { Tabs, Tooltip } from 'antd'
import Wrapper from 'components/Content'
import ListadoPersonas from 'components/persona/ListadoPersonas'
import SpaceTitle from 'components/SpaceTitle'
import useConfig from 'config/configAdapter/useConfig'
import { colors, plurales, spaces } from 'Constants'
import useBreakpoint from 'query/useBreakpoint'
import { Flags } from 'react-feature-flags'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { capitalizeFirstLetter } from 'utils'

const PERFILES = [
  {
    searchLabel: 'consejero',
    tipo: 'consejero',
  },
  {
    searchLabel: 'experto',
    tipo: 'experto',
  },
  {
    searchLabel: 'miembro',
    tipo: 'admisibilidad',
  },
]

const getItems = ({ config, roleParam }) =>
  Object.values(PERFILES).map(({ searchLabel, tipo }) => ({
    children: roleParam === tipo && (
      <ListadoPersonas
        space="Consejo Constitucional"
        tipo={tipo}
        searchName={searchLabel}
        roleFilter={{ field: 'roles', terms: tipo }}
      />
    ),
    disabled: !config.flags[tipo],
    key: tipo,
    label: capitalizeFirstLetter(plurales[tipo]),
  }))

function Listado() {
  const [searchParams, setSearchParams] = useSearchParams()
  const roleParam = searchParams.get('role') || 'consejero'
  const { isMobile } = useBreakpoint()
  const config = useConfig()
  const tooltipText = (
    <StyledText>
      Los candidatos fueron publicados de acuerdo al pacto y partido por los que fueron electos.
    </StyledText>
  )

  return (
    <Wrapper>
      <Flags authorizedFlags={['consejeros']}>
        <Header $isMobile={isMobile}>
          <SpaceTitle sectionName="Perfiles Consejo Constitucional" />
          <Tooltip
            placement="right"
            color="white"
            title={tooltipText}
          >
            <Info>i</Info>
          </Tooltip>
        </Header>
        <Tabs
          defaultActiveKey={roleParam}
          items={getItems({ config, roleParam })}
          onChange={(selectedKey) => {
            setSearchParams({ role: selectedKey })
          }}
        />
      </Flags>
    </Wrapper>
  )
}

const Header = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  background-color: ${colors.white};
  align-items: center;
  gap: ${spaces.xs};
  font-size: ${({ $isMobile }) => ($isMobile ? '0.75rem' : '0.9rem')};
`

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 1;
  height: 1.5em;
  width: 1.5em;
  background-color: ${({ color }) => color || 'black'};
  border-radius: 50%;
  padding-top: 0.05em;
`

const StyledText = styled.p`
  color: black;
  max-width: 230px;
  height: 100%;
  display: flex;
  align-items: baseline;
`

export default Listado
