import { Modal } from 'antd'
import styled from 'styled-components'

import LoadingSpinner from 'components/StyledSpinner'
import { spaces } from 'Constants'
import useBreakpoint from 'query/useBreakpoint'
import Comparator from './Comparator'

export default function ComparatorModal({
  baseTextTitle,
  closeModal,
  comparedArticulos,
  highlightedContent,
  legend,
  nombre,
  open,
}) {
  const { isMobile } = useBreakpoint()
  return (
    <Modal
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={
        isMobile && {
          style: {
            boxShadow: 'none',
            fontSize: '11px',
            fontWeight: '300',
            padding: `${spaces.s} ${spaces.m}`,
          },
        }
      }
      onCancel={closeModal}
      onOk={closeModal}
      open={open}
      width={1000}
      okText="Cerrar"
    >
      {!comparedArticulos && <StyledSpinner />}
      {comparedArticulos && (
        <Comparator
          comparedArticulos={comparedArticulos}
          highlightedContent={highlightedContent}
          nombre={nombre}
          baseTextTitle={baseTextTitle}
          legend={legend}
        />
      )}
    </Modal>
  )
}

const StyledSpinner = styled(LoadingSpinner)`
  margin-top: 40px;
`
