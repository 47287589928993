import styled from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'
import { colors } from 'Constants'

export default function Time({ onClick, time }) {
  const { primaryColor } = useConfig()
  return (
    <Wrapper
      type="link"
      onClick={onClick}
      $primaryColor={primaryColor}
    >
      {time}
    </Wrapper>
  )
}

const Wrapper = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${colors.selectedNav};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${({ $primaryColor }) => $primaryColor};
  }
`
