import { gql, useQuery } from '@apollo/client'

const TERRITORIOS = gql`
  query getTerritorios(
    $from: Int!
    $size: Int!
    $search: String
    $sortKey: String
    $sortOrder: String
    $filters: [FilterTerms!]
  ) {
    territorios(
      pagination: {
        from: $from
        size: $size
        search: $search
        sortKey: $sortKey
        sortOrder: $sortOrder
        filters: $filters
      }
    ) {
      pageInfo {
        nextOffset
        size
        totalCount
      }
      results {
        id
        orden
        tipo
      }
    }
  }
`

/* eslint-disable import/prefer-default-export */
export const TerritoriosQuery = (tipoTerritorio) => {
  const territoriosData = useQuery(TERRITORIOS, {
    errorPolicy: 'content_type=None',
    variables: {
      filters: [
        {
          field: 'tipo',
          match: tipoTerritorio,
        },
      ],
      from: 0,
      search: '',
      size: 100,
      sortKey: 'id',
      sortOrder: 'asc',
    },
  }).data

  const territoriosArr = !territoriosData
    ? []
    : territoriosData.territorios.results.reduce((acc, it) => {
        acc.push({
          text: it.orden,
          value: it.id,
        })
        return acc
      }, [])

  return { territoriosArr }
}
