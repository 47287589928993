import Icon from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'

import { ExitIcon } from 'assets'
import useConfig from 'config/configAdapter/useConfig'
import useBreakpoint from 'query/useBreakpoint'

export function TopbarHyperlink({ icon, message, url }) {
  const { baseUrl } = useConfig()
  return (
    <StyledHyperlink
      href={url || baseUrl}
      target="_blank"
      rel="noreferrer"
    >
      {message}
      <HyperlinkIcon>{icon}</HyperlinkIcon>
    </StyledHyperlink>
  )
}

export function LogoutButton() {
  const { baseUrl } = useConfig()
  const { isMobile } = useBreakpoint()

  return (
    <StyledButton
      icon={
        <Icon
          component={ExitIcon}
          color="grey"
        />
      }
      onClick={() => window.location.replace(`${baseUrl}/auth/logout`)}
      type="text"
    >
      {!isMobile && 'Salir'}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  color: grey;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.ant-btn.ant-btn-icon-only {
    width: min-content;
  }
`

const StyledHyperlink = styled(Button)`
  border: 0;
  color: black;
  font-size: 16px;
  margin-left: 4px;
  :hover {
    background: rgba(0, 0, 0, 0.06);
    color: black !important;
  }
`

const HyperlinkIcon = styled.span`
  margin-left: 10px;
`
