/* eslint-disable react/jsx-props-no-spreading */
import {
  ApolloClient,
  ApolloProvider as ApolloProviderBase,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { useAuth } from 'vendor/authkit'
import { apiUrl, defaultDevAuthSettings } from '..'
import { typeDefs } from './schema'

export function ApolloProvider({ path = 'graphql/', ...props }) {
  const { setAuthenticated } = useAuth()
  const links = [
    onError(({ networkError }) => {
      if (networkError) {
        if (networkError.statusCode === 401 || networkError.statusCode === 403) {
          setAuthenticated(false)
        }
      }
    }),

    new HttpLink({
      credentials: 'include',
      headers: defaultDevAuthSettings,
      uri: `${apiUrl}/${path}`,
    }),
  ]

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from(links),
    typeDefs,
  })

  return (
    <ApolloProviderBase
      client={client}
      {...props}
    />
  )
}

export default ApolloProvider
