/* eslint-disable react/jsx-props-no-spreading */
import { Provider } from 'use-http'

import { apiUrl } from 'config'

export default function HttpProvider(props) {
  return (
    <Provider
      {...props}
      url={apiUrl}
    />
  )
}
