import { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import styled from 'styled-components'

import StyledTitle from 'components/SpaceTitle'
import useConfig from 'config/configAdapter/useConfig'
import { spaces } from 'Constants'
import useDebounce from 'hooks/useDebounce'
import usePagination from 'views/AuthenticatedSite/Pagination'
import Table from './Table'

const getFiltersSummary = (filters) => {
  const summaryEntries = filters.map((filter) => [filter.field, filter.conj || filter.terms])
  return Object.fromEntries(summaryEntries)
}

export default function ListadoPerfiles({ roleFilter, searchName, space, tipo }) {
  const [personaSearch, setPersonaSearch] = useState('')
  const personaSearchDebounced = useDebounce(personaSearch, 500)
  const [phrase, setPhrase] = useState('')
  const {
    getPaginationParams,
    pagination,
    setOrder,
    setPageNumber,
    setSort,
    updatePaginationInfo,
  } = usePagination({
    defaultFilters: roleFilter ? [roleFilter] : [],
    defaultSortKey: 'apellido_paterno.keyword',
  })

  useEffect(() => {
    setPhrase(personaSearchDebounced)
    setPageNumber(1)
  }, [personaSearchDebounced])

  const [currentFilters, setCurrentFilters] = useState(getFiltersSummary(pagination.filters))
  const config = useConfig()

  const triggerSearch = (value) => {
    setPersonaSearch(value)
    if (value === '') setPhrase('')
  }

  const forceSearch = () => {
    setPhrase(personaSearch)
    setPageNumber(1)
  }

  return (
    <Wrapper>
      <Header>
        {config.canViewTitle.includes(space) && (
          <StyledTitle
            origenName={space}
            sectionName="Perfiles"
          />
        )}
        <SearchInput
          suffix={<SearchIcon />}
          placeholder={`Buscar ${searchName || tipo}`}
          allowClear
          onChange={(event) => triggerSearch(event.target.value)}
          onPressEnter={forceSearch}
          value={personaSearch}
        />
      </Header>
      <Table
        personaSearch={personaSearch}
        roleFilter={roleFilter}
        space={space}
        tipo={tipo}
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
        getPaginationParams={getPaginationParams}
        setOrder={setOrder}
        setSort={setSort}
        updatePaginationInfo={updatePaginationInfo}
        setPageNumber={setPageNumber}
        pagination={pagination}
        phrase={phrase}
        setPhrase={setPhrase}
      />
    </Wrapper>
  )
}

const SearchInput = styled(Input)`
  width: 260px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.l};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${spaces.s};
`

const SearchIcon = styled(SearchOutlined)`
  opacity: 0.5;
`
