import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Table } from 'antd'
import styled from 'styled-components'

import FilterSearch from 'components/FilterSearch'
import StyledTitle from 'components/SpaceTitle'
import LoadingSpinner from 'components/StyledSpinner'
import useConfig from 'config/configAdapter/useConfig'
import { formatLongOnlyDate } from 'format'
import useSesionComisiones from 'query/useSesionComisiones'
import usePagination from 'views/AuthenticatedSite/Pagination'

const SESIONES = gql`
  query getSesiones(
    $space_name: String!
    $from: Int!
    $size: Int!
    $sortKey: String
    $sortOrder: String
    $phrase: String!
    $filters: [FilterInput!]
  ) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          sesiones(
            search: { phrase: $phrase, filters: $filters }
            pagination: { from: $from, size: $size, sortKey: $sortKey, sortOrder: $sortOrder }
          ) {
            pageInfo {
              totalCount
              nextOffset
              size
            }
            results {
              id
              titulo
              origen
              fecha
              comision
            }
          }
        }
      }
    }
  }
`

const updateFilters = (filtersDict, setFilters, excludeFields = []) => {
  const filtersList = Object.keys(filtersDict)
    .filter((field) => !excludeFields.includes(field))
    .map((field) => ({
      field,
      terms: filtersDict[field],
    }))

  setFilters(filtersList)
}

function transformJson(inputJson) {
  const outputJson = {}
  inputJson.forEach((item) => {
    outputJson[item.field] = item.terms
  })
  return outputJson
}

const { Column } = Table
export default function Listado({ defaultFilters = [], space, tipo }) {
  const [phrase] = useState('')
  const [filtros, setFiltros] = useState([])
  const config = useConfig()

  const {
    getPaginationParams,
    pagination,
    setFilters,
    setOrder,
    setPageNumber,
    setSort,
    updatePaginationInfo,
  } = usePagination({
    defaultSortKey: 'fecha',
    defaultSortOrder: 'descend',
  })

  const { comisionesArr } = useSesionComisiones({
    space_name: space,
    tipo,
  })

  const { data, loading, refetch } = useQuery(SESIONES, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ me }) => {
      updatePaginationInfo(me?.organizacion?.espacio?.sesiones?.pageInfo)
    },
    variables: {
      ...getPaginationParams(),
      filters: [...filtros, ...defaultFilters],
      phrase,
      space_name: space,
    },
  })
  useEffect(() => {
    refetch({
      ...getPaginationParams(),
      filters: [...filtros, ...defaultFilters],
      phrase,
      space_name: space,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.sort,
    pagination.order,
    pagination.search,
    pagination.pageSize,
    pagination.pageNumber,
    tipo,
  ])

  if (loading) {
    return <LoadingSpinner />
  }
  const sesiones = data?.me?.organizacion?.espacio?.sesiones?.results || []

  const onTableChange = (_pagination, filters, sorter) => {
    if (filters) {
      const fields = Object.keys(filters)
      const filterTerms = []
      fields.forEach((field) => {
        if (filters[field]) {
          filters[field].forEach((term) => {
            filterTerms.push({ field, term })
          })
        }
      })
      setFilters(filterTerms)
    }
    if (sorter) {
      setSort(sorter.columnKey || '_score')
      setOrder(sorter.order)
    }
  }

  return (
    <Wrapper>
      {config.canViewTitle.includes(space) && <StyledTitle sectionName="Lista de Sesiones" />}
      <Table
        scroll={{ x: true }}
        onChange={onTableChange}
        sortDirections={['descend', 'ascend', 'descend']}
        getPopupContainer={(trigger) => trigger.parentNode.parentNode.parentNode}
        dataSource={sesiones}
        pagination={{
          current: pagination.pageNumber,
          onChange: setPageNumber,
          pageSize: pagination.pageSize,
          showSizeChanger: false,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          total: pagination.totalItems,
        }}
        rowKey="id"
      >
        <Column
          key="fecha"
          sorter
          sortOrder={pagination.order || 'descend'}
          title="Fecha"
          align="left"
          width={125}
          render={({ fecha }) => formatLongOnlyDate(fecha)}
        />
        <Column
          width="max-content"
          filterDropdown={
            <FilterSearch
              optionsArr={comisionesArr}
              currentFiltersDict={transformJson(filtros)}
              field="comision.keyword"
              setQueryFilters={setFiltros}
              updateFilters={updateFilters}
              width="300px"
            />
          }
          key="titulo"
          title="Comisión"
          render={({ comision }) => (
            <SessionInfoContainer>
              <div>{comision}</div>
            </SessionInfoContainer>
          )}
        />
        <Column
          key="nombre"
          title="Nombre sesión"
          align="left"
          render={({ id, titulo }) => (
            <Link to={`${id}?tipo=${tipo}`}>
              <StyledSessionTitle>{titulo}</StyledSessionTitle>
            </Link>
          )}
        />
      </Table>
    </Wrapper>
  )
}

const SessionInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
`

const StyledSessionTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.33px;
  color: black;
  text-decoration: underline;
`

const Wrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`
