import { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Card, Divider, Empty, List, Typography } from 'antd'
import styled from 'styled-components'

import OptionalOutlink from 'components/OptionalOutlink'
import LoadingSpinner from 'components/StyledSpinner'
import useConfig from 'config/configAdapter/useConfig'
import { colors } from 'Constants'
import { formatMediumDate, formatNationalDate } from 'format'
import { capitalizeFirstLetter } from 'utils'
import usePagination from 'views/AuthenticatedSite/Pagination'

const { Text } = Typography

const NOTICIA = gql`
  query Noticia($id: String!, $spaceName: String!, $from: Int!, $size: Int!) {
    me {
      organizacion {
        espacio(space_name: $spaceName) {
          persona(id: $id) {
            noticias(pagination: { from: $from, size: $size }) {
              results {
                fechaPublicacion
                resumen
                medio
                medioLogo
                url
              }
              pageInfo {
                totalCount
              }
            }
          }
        }
      }
    }
  }
`

export default function MediaSection({ id, spaceName }) {
  const { primaryColor } = useConfig()
  const { getPaginationParams, pagination, setPageNumber, updatePaginationInfo } = usePagination()
  const { data, loading, refetch } = useQuery(NOTICIA, {
    errorPolicy: 'all',
    onCompleted: ({ me }) => {
      updatePaginationInfo(me?.organizacion?.espacio?.persona?.noticias?.pageInfo)
    },
    variables: {
      id,
      spaceName,
      ...getPaginationParams(),
    },
  })

  const noticiasData = data?.me?.organizacion?.espacio?.persona?.noticias?.results ?? null

  useEffect(() => {
    refetch(getPaginationParams())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.sort,
    pagination.order,
    pagination.search,
    pagination.pageSize,
    pagination.pageNumber,
  ])

  const isEmpty = noticiasData?.length === 0

  if (loading) return <LoadingSpinner />

  if (isEmpty) {
    return (
      <EmptyContainer>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </EmptyContainer>
    )
  }

  return (
    <List
      dataSource={noticiasData}
      itemLayout="vertical"
      pagination={{
        current: pagination.pageNumber,
        onChange: setPageNumber,
        pageSize: pagination.pageSize,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
        total: pagination.totalItems,
      }}
      renderItem={(noticia) => (
        <List.Item>
          <NewWrapper>
            {noticia.fechaPublicacion && (
              <MonthTitle>
                {capitalizeFirstLetter(formatMediumDate(noticia.fechaPublicacion))}
              </MonthTitle>
            )}
            <StyledCard cover={<Logo src={noticia.medioLogo} />}>
              <Divider style={{ marginBottom: 10, marginTop: 0 }} />
              <StyledRow>
                <OptionalOutlink href={noticia.url}>
                  <StyledText $primaryColor={primaryColor}>Ver publicación</StyledText>
                </OptionalOutlink>
                {noticia.fecha && <StyledText>{formatNationalDate(noticia.fecha)}</StyledText>}
              </StyledRow>
              <Text type="secondary">
                <span className="text">{noticia.resumen}</span>
              </Text>
            </StyledCard>
          </NewWrapper>
        </List.Item>
      )}
      split={false}
    />
  )
}

export { MediaSection }

const MonthTitle = styled.div`
  font-weight: 600;
`

const EmptyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledText = styled.p`
  color: ${({ $primaryColor }) => $primaryColor};
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`

const NewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
`

const StyledCard = styled(Card)`
  width: 100%;
  flex: 7;
  border-radius: 2px;
  border-color: ${colors.backgroundDarkGrey};
  .ant-card-cover {
    padding: 24px 24px 0px 24px;
  }
`

const Logo = styled.img`
  && {
    width: min-content;
    border-radius: 0 !important;
  }
  height: 30px;
`
