import { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { List, Skeleton } from 'antd'
import styled from 'styled-components'

import Segment from 'components/Segment'
import { StyledList } from 'components/StyledList'
import LoadingSpinner from 'components/StyledSpinner'
import { colors } from 'Constants'
import { formatMediumDate } from 'format'
import { capitalizeFirstLetter, capitalizeFirstLetterEachWord } from 'utils'
import usePagination from '../Pagination'
import { itemRender, orderOptions, pageSizeOptions } from './common'
import SearchContext from './SearchContext'
import { SortOptionsDropDown } from './SortOptionsDropDown'

export default function SearchResults({ dataKey, EmptyComponent, query }) {
  const { searchPhrase } = useContext(SearchContext)
  const {
    getPaginationParams,
    pagination,
    setPageNumber,
    setPageSize,
    setSort,
    updatePaginationInfo,
  } = usePagination({ defaultSortKey: 'fecha' })

  const { data, loading, refetch } = useQuery(query, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      updatePaginationInfo(result?.search[dataKey]?.pageInfo)
    },
    variables: {
      phrase: searchPhrase,
      ...getPaginationParams(),
    },
  })

  useEffect(() => {
    setPageNumber(1)
  }, [searchPhrase, setPageNumber])

  useEffect(() => {
    refetch({
      phrase: searchPhrase,
      ...getPaginationParams(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase, pagination.pageSize, pagination.pageNumber])

  if (loading) {
    return <LoadingSpinner />
  }
  const dataSource = data ? data.search[dataKey].results : []

  return (
    <>
      <SortOptionsDropDown
        showDefaultValue={pageSizeOptions[0]}
        showOnChange={setPageSize}
        showOptions={pageSizeOptions}
        showSelectedValue={pagination.pageSize}
        sortDefaultValue={orderOptions[0][0]}
        sortOnChange={setSort}
        sortOptions={orderOptions}
        sortSelectedValue={pagination.sort}
      />
      <StyledList
        dataSource={dataSource || []}
        itemLayout="vertical"
        locale={EmptyComponent && { emptyText: <EmptyComponent /> }}
        pagination={{
          current: pagination.pageNumber,
          itemRender,
          onChange: setPageNumber,
          pageSize: pagination.pageSize,
          position: 'top',
          showSizeChanger: false,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          total: pagination.totalItems,
        }}
        renderItem={(item) => (
          <List.Item>
            <Skeleton
              active
              loading={loading}
            >
              <DateContainer>
                <strong>{capitalizeFirstLetter(formatMediumDate(item.date))}</strong>
              </DateContainer>
              <HighlightsContainer>
                <strong>{item.title}</strong>
              </HighlightsContainer>
              <HighlightsContainer>
                {item.highlights.map(({ speaker, speaker_name: speakerName, text, timecode }) => (
                  <Segment
                    item={item}
                    imageUrl={speaker?.imagen_url}
                    key={`${item.resourceId} ${timecode}`}
                    resourceId={item.resourceId}
                    speakerName={capitalizeFirstLetterEachWord(
                      speaker?.nombre_completo || speakerName
                    )}
                    text={text}
                    rol={speaker?.roles}
                    timecode={timecode}
                    partido={speaker?.partido}
                  />
                ))}
              </HighlightsContainer>
            </Skeleton>
          </List.Item>
        )}
        split={false}
      />
    </>
  )
}

const HighlightsContainer = styled.div`
  border: 1px solid ${colors.default};
  padding: 8px;
`

const DateContainer = styled.div`
  color: gray;
  padding: 8px 4px;
`
