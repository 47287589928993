import { Empty, Table } from 'antd'
import styled from 'styled-components'

import VotoContainer from 'components/VotoContainer'
import { colors } from 'Constants'

const { Column } = Table

function contarVotosPorPactoYTipo(votos) {
  const conteo = votos.reduce((accumulator, voto) => {
    const { pacto } = voto
    const tipoVoto = voto.voto
    if (!accumulator[pacto]) {
      accumulator[pacto] = {}
    }
    accumulator[pacto][tipoVoto] = (accumulator[pacto][tipoVoto] || 0) + 1

    return accumulator
  }, {})
  return conteo
}

function PactoTable({ resultado, votos }) {
  const conteoVotosPorPacto = contarVotosPorPactoYTipo(votos)

  const dataSource = Object.keys(conteoVotosPorPacto).map((pacto) => ({
    Abstencion: conteoVotosPorPacto[pacto].ABSTENCION || null,
    EnContra: conteoVotosPorPacto[pacto]['EN CONTRA'] || null,
    Pactos: pacto,
    aFavor: conteoVotosPorPacto[pacto]['A FAVOR'] || null,
    key: pacto,
  }))

  function getWinner(result) {
    const valoresNumericos = Object.values(result)
      .map((valor) => parseFloat(valor))
      .filter((valor) => !Number.isNaN(valor))
    const maxValor = Math.max(...valoresNumericos)
    const resultBoolean = {}

    Object.keys(result).forEach((clave) => {
      if (clave !== '__typename') {
        resultBoolean[clave] = parseFloat(result[clave]) === maxValor
      }
    })

    return resultBoolean
  }

  const voteResult = getWinner(resultado)

  return votos ? (
    <VotoTable
      dataSource={dataSource}
      pagination={false}
      scroll={{ x: true }}
    >
      <Column
        key="Pactos"
        title="Pacto"
        render={({ Pactos }) => <PactoContent>{Pactos}</PactoContent>}
      />
      <Column
        key="A favor"
        title="A favor"
        render={({ aFavor }) => (
          <VotoContainer
            $count={aFavor}
            $maxNRows={5}
            $color={colors.votacion.aprobada}
            $colorBackground={voteResult.apruebos}
          />
        )}
      />
      <Column
        key="En Contra"
        title="En contra"
        render={({ EnContra }) => (
          <VotoContainer
            $count={EnContra}
            $maxNRows={5}
            $color={colors.votacion.rechazada}
            $colorBackground={voteResult.rechazos}
          />
        )}
      />
      <Column
        key="Abstención"
        title="Abstención"
        render={({ Abstencion }) => (
          <VotoContainer
            $count={Abstencion}
            $maxNRows={5}
            $color={colors.votacion.pendiente}
            $colorBackground={voteResult.abstenciones}
          />
        )}
      />
    </VotoTable>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )
}

export default PactoTable

const PactoContent = styled.div`
  font-size: 13px;
  padding-left: 12px;
  min-width: 100px;
`

const VotoTable = styled(Table)`
  td {
    padding: 0 !important;
  }
`
