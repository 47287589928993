import React, { useEffect } from 'react'
import { HeartOutlined, RetweetOutlined, TwitterOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { Empty, List, Space } from 'antd'
import styled from 'styled-components'

import LoadingSpinner from 'components/StyledSpinner'
import useConfig from 'config/configAdapter/useConfig'
import { formatGenericDatetime } from 'utils'
import usePagination from 'views/AuthenticatedSite/Pagination'

const getTwitterUserName = (redesSociales) => {
  const redSocial = redesSociales.find((item) => item.source === 'TWITTER')
  return redSocial?.id
}

const MEDIOSDIGITALES = gql`
  query getTweets(
    $id: String!
    $spaceName: String!
    $from: Int!
    $size: Int!
    $sortKey: String
    $sortOrder: String
    $filters: [FilterTerms!]
  ) {
    me {
      organizacion {
        espacio(space_name: $spaceName) {
          persona(id: $id) {
            redes_sociales {
              source
              id
              link
            }
            tweets(
              pagination: {
                from: $from
                size: $size
                sortKey: $sortKey
                sortOrder: $sortOrder
                filters: $filters
              }
            ) {
              pageInfo {
                nextOffset
                size
                totalCount
              }
              results {
                id
                created_at
                favorite_count
                full_text
                ingest_date
                retweet_count
                user_name
                user_screen_name
                link
              }
            }
          }
        }
      }
    }
  }
`

export default function DetalleMediosDigitales({ personaId, spaceName }) {
  const { primaryColor } = useConfig()
  const { getPaginationParams, pagination, setPageNumber, updatePaginationInfo } = usePagination({
    defaultFilters: [],
    defaultSortKey: 'created_at',
    defaultSortOrder: 'desc',
  })

  const { data, loading, refetch } = useQuery(MEDIOSDIGITALES, {
    onCompleted: ({
      me: {
        organizacion: {
          espacio: { persona },
        },
      },
    }) => updatePaginationInfo(persona.tweets.pageInfo),
    variables: {
      ...getPaginationParams(),
      id: personaId,
      spaceName,
    },
  })

  useEffect(() => {
    refetch(getPaginationParams())
  }, [pagination.sort, pagination.order, pagination.pageSize, pagination.pageNumber])
  if (loading) {
    return <LoadingSpinner />
  }

  const persona = data?.me.organizacion?.espacio?.persona
  const tweetsData =
    persona.tweets &&
    persona.tweets.results &&
    persona.tweets.results.filter((tweet) => Boolean(tweet))

  const userScreenName = getTwitterUserName(data.me.organizacion.espacio.persona.redes_sociales)
  const isEmpty = !tweetsData || tweetsData.length === 0

  if (isEmpty) {
    return (
      <EmptyContainer>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </EmptyContainer>
    )
  }

  return (
    <List
      itemLayout="vertical"
      dataSource={tweetsData}
      pagination={{
        current: pagination.pageNumber,
        onChange: setPageNumber,
        pageSize: pagination.pageSize,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
        total: pagination.totalItems,
      }}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          actions={[
            <Space key="list-vertical-retweet-o">
              <RetweetOutlined />
              {item.retweet_count}
            </Space>,
            <Space key="list-vertical-like-o">
              <HeartOutlined />
              {item.favorite_count}
            </Space>,
          ]}
        >
          <List.Item.Meta
            title={
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer"
              >
                <StyledLinkSpace key="list-vertical-tittle-o">
                  <TwitterOutlined style={{ color: primaryColor }} />
                  {`${userScreenName} - ${formatGenericDatetime(item.created_at, 'd MMM y, t')}`}
                </StyledLinkSpace>
              </a>
            }
          />
          <StyledRow>{item.full_text}</StyledRow>
        </List.Item>
      )}
    />
  )
}

const EmptyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledLinkSpace = styled(Space)`
  color: #000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  cursor: pointer;
`

const StyledRow = styled.div`
  font-size: 14px;
  width: 95%;
`
