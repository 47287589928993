import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuOutlined, SearchOutlined } from '@ant-design/icons'
import { Button as ButtonBase } from 'antd'
import styled from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'
import { colors, spaces } from 'Constants'
import useBreakpoint from 'query/useBreakpoint'
import Search from '../Search'
import { LogoutButton, TopbarHyperlink } from './Buttons'
import Profile from './Profile'
import { StyledTopBar } from './TopBarNavigation'

export default function TopBar({ onOpenMenu, showOnTopBar }) {
  const {
    linkProcesoConstitucional: showLinkProcesoConstitucional,
    linkSecretariaPCiudadana: showLinkSecretariaPCiudadana,
    logoutButton: showLogout,
    username: showUsername,
  } = showOnTopBar
  const { isMobile } = useBreakpoint()
  const { logoPath } = useConfig()
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const isOpen = isMobile && isSearchOpen
  const navigate = useNavigate()
  return (
    <Wrapper>
      <Top>
        {isMobile && (
          <Button
            icon={<MenuOutlined />}
            onClick={onOpenMenu}
          />
        )}
        {isMobile ? (
          <Logo
            src={logoPath}
            alt="logo"
            onClick={() => navigate('/')}
          />
        ) : (
          <Search.Input />
        )}
        <Top.Separator />
        {isMobile && (
          <Button
            icon={<SearchOutlined />}
            onClick={() => setIsSearchOpen(!isSearchOpen)}
            type="link"
          />
        )}
        {showUsername && <Profile />}
        {showLogout && <LogoutButton />}
        {showLinkProcesoConstitucional && (
          <TopbarHyperlink
            icon={
              <Logo
                src="/icons/blackLaunchIcon.svg"
                alt="logo"
              />
            }
            message="Proceso Constitucional"
            url="https://www.procesoconstitucional.cl/"
          />
        )}
        {showLinkSecretariaPCiudadana && (
          <TopbarHyperlink
            icon={
              <img
                src="/icons/blackLaunchIcon.svg"
                alt="logo"
              />
            }
            message="Secretaría de Participación Ciudadana"
            url="https://www.secretariadeparticipacion.cl/"
          />
        )}
      </Top>
      {isOpen && (
        <Bottom>
          <Search.Input />
        </Bottom>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.backgroundDarkGrey};
`
const Top = styled(StyledTopBar)`
  gap: ${spaces.s};
  @media (max-width: ${spaces.mobileBreakpoint}) {
    padding: ${spaces.m};
  }
`
const Bottom = styled.div`
  display: flex;
  @media (max-width: ${spaces.mobileBreakpoint}) {
    padding: ${spaces.m};
  }
`
const Logo = styled.img`
  height: 100%;
  max-height: 32px;
  padding: 0 ${spaces.s};
  transform: translateY(2px);
`

const Button = styled(ButtonBase)`
  svg {
    margin-top: 3px;
  }
`
