import styled from 'styled-components'

const Circle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${(props) => `${props.color}`};
`

export default Circle
