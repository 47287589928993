import CNNBold from './CNNSans-Bold.ttf'
import CNNBoldItalic from './CNNSans-BoldItalic.ttf'
import CNNItalic from './CNNSans-Italic.ttf'
import CNNLight from './CNNSans-Light.ttf'
import CNNLightItalic from './CNNSans-LightItalic.ttf'
import CNNMedium from './CNNSans-Medium.ttf'
import CNNMediumItalic from './CNNSans-MediumItalic.ttf'
import CNNRegular from './CNNSans-Regular.ttf'

const CNNSans = document.createElement('style')

CNNSans.textContent = `
  @font-face {
    font-family: 'CNN Sans';
    src: url(${CNNBold}) format('truetype');
    font-weight: bold;
  }
  @font-face {
    font-family: 'CNN Sans';
    src: url(${CNNBoldItalic}) format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: 'CNN Sans';
    src: url(${CNNItalic}) format('truetype');
    font-style: italic;
  }
  @font-face {
    font-family: 'CNN Sans';
    src: url(${CNNLight}) format('truetype');
    font-weight: 300;
  }
  @font-face {
    font-family: 'CNN Sans';
    src: url(${CNNLightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'CNN Sans';
    src: url(${CNNMedium}) format('truetype');
    font-weight: 500;
  }
  @font-face {
    font-family: 'CNN Sans';
    src: url(${CNNMediumItalic}) format('truetype');
    font-style: italic;
    font-weight: 500;
  }
  @font-face {
    font-family: 'CNN Sans';
    src: url(${CNNRegular}) format('truetype');
    font-weight: normal;
  }
`

export default CNNSans
