import { useNavigate } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import StyledPageHeader from 'components/PageHeader'
import useConfig from 'config/configAdapter/useConfig'
import { capitalizeFirstLetterEachWord } from 'utils'

const roleAdapter = {
  admisibilidad: 'admisibilidad',
  consejeros: 'consejero',
  expertos: 'experto',
  mixta: 'mixta',
}

export default function DetalleHeader({ isConsejoMember, nombreCompleto, role }) {
  const { primaryColor } = useConfig()
  const navigate = useNavigate()
  return (
    <StyledHeader $primaryColor={primaryColor}>
      <StyledPageHeader
        className="page-header"
        backIcon={<LeftOutlined style={{ color: primaryColor }} />}
        onBack={() =>
          navigate(
            isConsejoMember ? `/consejo/perfiles?role=${roleAdapter[role]}` : `/${role}/perfiles`
          )
        }
        title={capitalizeFirstLetterEachWord(nombreCompleto || '')}
      />
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  background-color: #ffffff;
  padding-top: 0;

  .page-header {
    padding-bottom: 0px;
  }
`
