import { useEffect, useState } from 'react'
import { Flags } from 'react-feature-flags'
import { Link, useSearchParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Table, Tooltip } from 'antd'
import styled from 'styled-components'

import Content from 'components/Content'
import SectionTitle from 'components/SpaceTitle'
import StyledTable from 'components/StyledTable'
import { CustomSwitch, SwitchContainer } from 'components/Switch'
import { nuevosPactos, pactoDeConstituyenteViejos, viejosPactos } from 'config/staticData'
import { formatShortDate } from 'format'
import { htmlDecodeText } from 'utils'
import usePagination from '../../Pagination'
import convencionales from './convencionales'
import { Dot, DotGroup } from './Dots'
import {
  electoresSinPacto,
  pactoPorConvencional,
  recordToObject,
  rollupPorOpcion,
  rollupPorPactoYOpcion,
} from './rollupVotos'

const { Column } = Table

const VOTACIONES = gql`
  query getVotaciones(
    $space_name: String!
    $from: Int!
    $size: Int!
    $sortKey: String
    $sortOrder: String
  ) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          votaciones(
            pagination: { from: $from, size: $size, sortKey: $sortKey, sortOrder: $sortOrder }
          ) {
            pageInfo {
              nextOffset
              size
              totalCount
            }
            results {
              id
              titulo
              tema
              resultado
              patrocinadores
              fecha
              materia_original
              votos {
                opcion
                elector
              }
            }
          }
        }
      }
    }
  }
`

const mapNewToOldQueryResult = (queryResult) => {
  if (!queryResult) return queryResult

  const oldData = {
    convencionales,
    votaciones: queryResult.votaciones,
  }

  return oldData
}

function Votaciones() {
  electoresSinPacto.clear()
  const {
    getPaginationParams,
    pagination,
    setOrder,
    setPageNumber,
    setSort,
    updatePaginationInfo,
  } = usePagination({ defaultSortKey: 'fecha' })

  const { data: newQueryData, refetch } = useQuery(VOTACIONES, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: (completedData) => {
      updatePaginationInfo(completedData?.me?.organizacion?.espacio?.votaciones?.pageInfo)
    },
    variables: {
      ...getPaginationParams(),
      space_name: 'Convencionales',
    },
  })

  const rawData = newQueryData?.me?.organizacion?.espacio

  const data = mapNewToOldQueryResult(rawData)

  const [newPactSwitch, setNewPactSwitch] = useState(true)

  const pactoDeConstituyente = data && pactoPorConvencional(data.convencionales)

  if (electoresSinPacto.size > 0) {
    throw new Error(`Electores sin pacto: ${Array.from(electoresSinPacto.keys())}`)
  }

  const aggregateData = (votaciones, pactos) =>
    votaciones?.map((votacion) => {
      const title = htmlDecodeText(votacion.titulo || '')
      const subject = htmlDecodeText(votacion.materia_original || '')

      const { votos } = recordToObject(votacion, pactos)
      const porOpcion = rollupPorOpcion(votos)
      const opcionesOrdenadas = [...porOpcion.keys()]

      return {
        fecha: votacion.fecha,
        key: votacion.id,
        opciones: opcionesOrdenadas,
        porOpcion,
        porPactoYOpcion: Object.fromEntries(
          rollupPorPactoYOpcion(votos, opcionesOrdenadas).entries()
        ),
        titulo: title || subject.substring(0, 50),
      }
    })

  const resultMap = {
    'A Favor': 'Aprobada',
    Abstención: '',
    'En Contra': 'Rechazada',
  }

  const pactoSwitchMap = {
    false: {
      columns: viejosPactos,
      data: aggregateData(data && data.votaciones.results, pactoDeConstituyenteViejos),
      label: 'Pactos Originales',
    },
    true: {
      columns: nuevosPactos,
      data: aggregateData(data && data.votaciones.results, pactoDeConstituyente),
      label: 'Pactos Vigentes',
    },
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const page = Number(searchParams.get('page')) || 1

  useEffect(() => {
    refetch(getPaginationParams())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageSize, pagination.pageNumber])

  const onTableChange = (tablePagination, _filters, sorter) => {
    if (tablePagination) {
      setPageNumber(tablePagination.current)
      setSearchParams({ page: tablePagination.current })
    }
    if (sorter) {
      const sortKey = sorter.order ? sorter.columnKey : '_score'
      const sortOrder = sorter.order
      setSort(sortKey)
      setOrder(sortOrder)
    }
  }

  return (
    <Flags authorizedFlags={['votaciones2022']}>
      <VotacionesWrapper>
        <SwitchContainer>
          <SectionTitle sectionName="Historial de Votaciones" />
          <CustomSwitch
            labelLeft="Pactos originales"
            labelRight="Pactos vigentes"
            onClickLeft={() => setNewPactSwitch(false)}
            onClickRight={() => setNewPactSwitch(true)}
          />
        </SwitchContainer>
        <StyledTable
          dataSource={pactoSwitchMap[newPactSwitch].data}
          onChange={onTableChange}
          rowKey="key"
          loading={!data}
          pagination={{
            current: page,
            pageSize: pagination.pageSize,
            showSizeChanger: false,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
            total: pagination.totalItems,
          }}
          sortDirections={['descend', 'ascend', 'descend']}
          scroll={{ x: true }}
        >
          <Column
            className="titulo"
            dataIndex="titulo"
            key="titulo"
            render={(titulo, record) => (
              <Title
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div>
                  <div
                    style={{
                      ...(resultMap[record.opciones[0]] === 'Aprobada'
                        ? {
                            fontWeight: 'bold',
                            lineHeight: '22px',
                          }
                        : {
                            lineHeight: '18px',
                          }),
                    }}
                  >
                    {resultMap[record.opciones[0]]}
                  </div>
                  <StyledLink to={`/convencionales/votaciones/${record.key}?page=${page}`}>
                    {titulo}
                  </StyledLink>
                </div>
              </Title>
            )}
            title="Título"
            width="200px"
          />
          <Column
            className="fecha"
            dataIndex="fecha"
            key="fecha"
            render={(fecha) => formatShortDate(fecha)}
            sorter
            sortOrder={pagination.order || 'descend'}
            title="Fecha"
            width="100px"
          />
          <Column
            align="center"
            className="votacion"
            dataIndex="porOpcion"
            key="porOpcion"
            render={(porOpcion, record) => (
              <Cell>
                {[...porOpcion.entries()].map((entry, idx) => (
                  <CellItem
                    key={entry[0]}
                    style={{ textAlign: 'right' }}
                  >
                    <Votacion>{entry[1].length}</Votacion>
                    <Opcion>{record.opciones[idx]}</Opcion>
                  </CellItem>
                ))}
              </Cell>
            )}
            title="Votación"
            width="120px"
          />
          {pactoSwitchMap[newPactSwitch].columns.map(({ key, label, longName }) => (
            <Column
              align="center"
              dataIndex={['porPactoYOpcion', key]}
              key="key"
              render={({ opcionPreferida, porOpcion }) => (
                <Cell className={`data-category-${key}`}>
                  {porOpcion.map(({ opcion, votos }) => (
                    <CellItem key={opcion}>
                      <DotGroup>
                        {votos.map(({ elector: electorVoto }) => (
                          <Dot
                            className={opcion === opcionPreferida && 'data-fill'}
                            key={electorVoto}
                            $margin={1}
                            size={4}
                          />
                        ))}
                      </DotGroup>
                    </CellItem>
                  ))}
                </Cell>
              )}
              title={<Tooltip title={longName}>{label}</Tooltip>}
            />
          ))}
        </StyledTable>
      </VotacionesWrapper>
    </Flags>
  )
}

export default styled(Votaciones)`
  td {
    vertical-align: top;
    padding: 0 !important;
    margin: 4px !important;
    border: none !important;
    border-bottom: 1px solid black;
    height: 120px; /* HACK  */
  }
`

const Title = styled.div`
  display: flex;
  padding: 4px 16px;

  &:hover {
    text-decoration: none !important;
  }
`

const Cell = styled.div``

const CellItem = styled.div`
  height: 27px;
  padding: 2px 2px;
  border-bottom: 1px solid #ddd;
  display: flex;

  &:first-child {
    font-weight: bold;
    font-size: 1em;
  }

  &:last-child {
    border-bottom: none;
  }

  font-size: 0.8em;
  white-space: nowrap;

  .anticon {
    margin-bottom: 2px;
  }
`

const Votacion = styled.div`
  display: inline-block;
  margin: 0 6px;
  width: 32px;
`

const Opcion = styled.div`
  display: inline-block;
  width: 88px;
  text-align: left;
`

const StyledLink = styled(Link)`
  line-height: 22px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
`

const VotacionesWrapper = styled(Content)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
