import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Drawer, Layout } from 'antd'
import { styled } from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'
import useBreakpoint from 'query/useBreakpoint'
import Routes from './Routes'
import Search from './Search'
import SideContent from './Sidebar/SideContent'
import TopBar from './TopBar'

const { Content, Sider } = Layout

const INITIAL_QUERY = gql`
  query getUserID {
    me {
      id
    }
  }
`

export default function AuthenticatedSite() {
  const { showOnTopBar } = useConfig()
  const [open, setOpen] = useState(false)
  const { isMobile } = useBreakpoint()
  const { loading } = useQuery(INITIAL_QUERY)

  if (loading) return null

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Layout style={{ height: '100vh' }}>
      {isMobile ? (
        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          open={open}
          key="left"
          bodyStyle={{ padding: 0 }}
          width="200px"
        >
          <SideContent onClose={onClose} />
        </Drawer>
      ) : (
        <Sider
          theme="light"
          width="min-content"
        >
          <SideContent />
        </Sider>
      )}
      <Layout>
        <Search
          queryParam="q"
          url="/search"
        >
          <TopBar
            showOnTopBar={showOnTopBar}
            onOpenMenu={showDrawer}
          />
          <Content>
            <Sheet>
              <Routes />
            </Sheet>
          </Content>
        </Search>
      </Layout>
    </Layout>
  )
}

const Sheet = styled.div`
  background: white;
  overflow-y: auto;
  height: 100%;
`
