import styled from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'
import { spaces } from 'Constants'
import { formatNationalDate } from 'format'
import HablantesEditor from '../HablantesEditor'
import Dato from './Dato'

export default function ResumenSesion({
  className,
  editMode,
  filename,
  hideLabels,
  sesion,
  transcripciones,
}) {
  const { flags = {} } = useConfig()
  const {
    comision,
    fecha,
    nombre_solicitante: nombreSolicitante,
    organizacion,
    sede,
    tema,
    titulo,
  } = sesion
  return (
    <Wrapper className={className}>
      {!flags.regiones ? (
        <Contenido>
          {titulo && (
            <Dato
              titulo={!hideLabels && 'Nombre'}
              contenido={titulo}
            />
          )}
          {fecha && (
            <Dato
              titulo={!hideLabels && 'Fecha'}
              contenido={formatNationalDate(fecha)}
            />
          )}
          {comision && (
            <Dato
              titulo={!hideLabels && 'Comisión'}
              contenido={comision}
            />
          )}
        </Contenido>
      ) : (
        <Contenido>
          {nombreSolicitante && <Titulo>{nombreSolicitante}</Titulo>}
          <Dato
            titulo={!hideLabels && 'Tema'}
            contenido={tema || 'No identificado'}
          />
          <Dato
            titulo={!hideLabels && 'Fecha'}
            contenido={formatNationalDate(fecha)}
          />
          <Dato
            titulo={!hideLabels && 'Organización'}
            contenido={organizacion || 'No identificada'}
          />
          <Dato
            titulo={!hideLabels && 'Sede'}
            contenido={sede || 'No identificada'}
          />
        </Contenido>
      )}
      {editMode && (
        <HablantesEditor
          transcripciones={transcripciones}
          filename={filename}
          titulo={titulo}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-size: 14px;
`

const Titulo = styled.div`
  font-size: 16px;
  font-weight: 700;
`

export const Contenido = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.s};
`
