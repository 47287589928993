import { useConfig as useConfigBase } from '@unholster/react-use-config'

import { AdaptedConfig } from './config.type'

export default function useConfig() {
  const { config } = useConfigBase<AdaptedConfig>()
  return config
}

export function useStatedConfig() {
  return useConfigBase<AdaptedConfig>()
}
