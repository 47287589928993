import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons'
import styled from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'
import { colors } from 'Constants'

export default function Division({ setShowResumenSesion, showResumenSesion }) {
  const { primaryColor } = useConfig()
  return (
    <StyledDivision>
      {showResumenSesion ? (
        <MinusCircleFilled
          onClick={() => setShowResumenSesion(false)}
          style={{ color: primaryColor }}
        />
      ) : (
        <PlusCircleFilled
          onClick={() => setShowResumenSesion(true)}
          style={{ color: primaryColor }}
        />
      )}
      <StyledVerticalLine />
    </StyledDivision>
  )
}

const StyledVerticalLine = styled.div`
  background-color: ${colors.backgroundLightGrey};
  width: 1px;
  height: 100%;
`

const StyledDivision = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 0 15px;
  font-size: 29px;
`
