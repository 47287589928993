import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'
import { spaces } from 'Constants'
import Desktop from './Desktop'
import { getFilenameFromVideoURL, isFromYoutube, toSeconds } from './helpers'
import mergeTranscriptions from './mergeTranscripctions'
import Mobile from './Mobile'

export default function SesionesEnSala({ sesion }) {
  const { primaryColor } = useConfig()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const editMode = searchParams.get('editame') === 'si'

  const match = /^#(\d+:\d\d:\d\d)$/.exec(location.hash)
  const startingSeconds = match ? toSeconds(match[1]) : 0

  const [playedSeconds, setPlayedSeconds] = useState(startingSeconds)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [showResumenSesion, setShowResumenSesion] = useState(true)

  const playerRef = useRef()
  const currentPlayer = playerRef.current
  const transcripcionRef = useRef()

  const goto = (seconds) => {
    if (playerRef.current) {
      playerRef.current.seekTo(seconds, true)
      const { url } = playerRef.current.props
      const isFromBeginning = seconds === 0
      const shouldPlay = isLoaded && !isFromBeginning
      if (isFromYoutube(url)) {
        const internalPlayer = playerRef.current.getInternalPlayer()
        if (internalPlayer) {
          internalPlayer.playVideo()
        }
      }
      setIsPlaying(shouldPlay)
    }
    if (transcripcionRef.current) {
      transcripcionRef.current.follow(seconds)
    }
  }

  useEffect(() => {
    if (currentPlayer) {
      goto(startingSeconds)
    }
  }, [currentPlayer, startingSeconds, isLoaded])

  if (!sesion) {
    navigate('./..')
    return null
  }

  const filename = getFilenameFromVideoURL(sesion.video_url)

  const handleBackButtonClick = () => {
    navigate(-1)
  }

  const mergedTranscriptions = editMode
    ? sesion.transcripciones
    : mergeTranscriptions(sesion.transcripciones)
  return (
    <Wrapper>
      {editMode ? (
        <Desktop
          editMode={editMode}
          filename={filename}
          goto={goto}
          handleBackButtonClick={handleBackButtonClick}
          isFromYoutube={isFromYoutube}
          isPlaying={isPlaying}
          mergedTranscriptions={mergedTranscriptions}
          playedSeconds={playedSeconds}
          playerRef={playerRef}
          primaryColor={primaryColor}
          sesion={sesion}
          setIsLoaded={setIsLoaded}
          setPlayedSeconds={setPlayedSeconds}
          transcripcionRef={transcripcionRef}
          showResumenSesion={showResumenSesion}
          setShowResumenSesion={setShowResumenSesion}
        />
      ) : (
        <Mobile
          editMode={editMode}
          filename={filename}
          goto={goto}
          handleBackButtonClick={handleBackButtonClick}
          isFromYoutube={isFromYoutube}
          isPlaying={isPlaying}
          mergedTranscriptions={mergedTranscriptions}
          playedSeconds={playedSeconds}
          playerRef={playerRef}
          primaryColor={primaryColor}
          sesion={sesion}
          setIsLoaded={setIsLoaded}
          setPlayedSeconds={setPlayedSeconds}
          transcripcionRef={transcripcionRef}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  gap: ${spaces.xs};
`
