/* eslint-disable react/no-danger */
import { Link } from 'react-router-dom'
import { Comment } from '@ant-design/compatible'
import { UserOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import styled from 'styled-components'

import Avatar from 'components/Avatar'
import useConfig from 'config/configAdapter/useConfig'
import { regionToNumber } from 'Constants'

const { Text } = Typography

const OriginMapping = {
  'consejo constitucional': 'consejo',
  regiones: 'regiones',
}

const prefixLink = (sesion) => {
  const origen = OriginMapping[sesion.origen.toLowerCase()]
  const region = regionToNumber[sesion.sesion_type]
  const type = region ? `${region}/sesiones` : 'sesiones'
  return `${origen}/${type}`
}

function Segment({ imageUrl, item, partido, resourceId, speakerName, text, timecode }) {
  const { avatarColors } = useConfig()

  const prefix = prefixLink(item)

  return (
    <ListComment
      author={
        <div>
          <Text type="primary">
            <span
              className="speaker"
              dangerouslySetInnerHTML={{ __html: speakerName }}
            />
          </Text>{' '}
          - <StyledLink to={`/${prefix}/${resourceId}/#${timecode}`}>{timecode}</StyledLink>
        </div>
      }
      avatar={
        imageUrl ? (
          <Avatar
            src={imageUrl}
            color={avatarColors[partido]}
          />
        ) : (
          <Avatar icon={<UserOutlined />} />
        )
      }
      content={
        <Text type="secondary">
          <span
            className="text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Text>
      }
    />
  )
}

const ListComment = styled(Comment)`
  .ant-comment-avatar {
    cursor: default;
    img {
      height: ${({ size }) => size || 60}px;
      width: ${({ size }) => size || 60}px;
    }
  }

  .ant-comment-content-detail {
    line-height: 22px;
    vertical-align: baseline;
    letter-spacing: normal;
    word-spacing: 0px;
  }
`

const StyledLink = styled(Link)`
  line-height: 22px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
`

export default Segment
