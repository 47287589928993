import { List } from 'antd'
import styled from 'styled-components'

import { colors } from 'Constants'

export const StyledList = styled(List)`
  .ant-list-item-meta-title,
  .ant-list-item-meta {
    margin-bottom: 0;
  }

  .ant-comment-content {
    padding: 8px;
    margin: 4px 16px;

    .text {
      font-size: 0.9em;
    }
  }

  .ant-comment-inner {
    padding: 0;
  }

  .ant-comment-content-authro {
    margin-bottom: 0;
  }

  .ant-pagination-item,
  .ant-pagination-item-ellipsis,
  .ant-pagination-item-container,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev-custom-icon,
  .ant-pagination-jump-next-custom-icon {
    display: none !important;
  }

  .ant-list-item-no-flex {
    padding-top: 0;
  }

  em {
    background: yellow;
  }

  .ant-list-pagination {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .ant-pagination-disabled {
    > * {
      color: ${colors.disabledLink} !important;
      opacity: 0.5;
    }
  }
`

export default StyledList
