import addTimeStringTimes from './addTwoTimestrings'

const mergeTranscriptions = (transcriptions) =>
  transcriptions.reduce((acc, transcription) => {
    const last = acc[acc.length - 1]
    if (last && last?.hablante_nombre === transcription.hablante_nombre) {
      acc[acc.length - 1] = {
        ...last,
        texto: `${last.texto}${transcription.texto}`,
        tiempo_duracion: addTimeStringTimes(last.tiempo_duracion, transcription.tiempo_duracion),
        tiempo_fin: transcription.tiempo_fin,
      }
      return acc
    }
    return [...acc, transcription]
  }, [])

export default mergeTranscriptions
