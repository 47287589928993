import { gql, useQuery } from '@apollo/client'
import { capitalizeFirstLetterEachWord } from 'utils'

const ESTADOS = gql`
  query getEstados($space_name: String!) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          estado_aggregate {
            pageInfo {
              nextOffset
              size
              totalCount
            }
            results {
              estado
            }
          }
        }
      }
    }
  }
`

interface useEstadoParams {
  spaceName: string
}

const useEstado = (variables: useEstadoParams) => {
  const { data: estadosData } = useQuery(ESTADOS, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables,
  })

  const estados = estadosData?.me?.organizacion?.espacio?.estado_aggregate?.results || []

  const adaptedEstados = estados.map(({ estado }) => ({
    text: capitalizeFirstLetterEachWord(estado),
    value: estado,
  }))

  return { estadosArr: adaptedEstados }
}

export default useEstado
