// SVG
import { ReactComponent as Audio } from './audioIcon.svg'
import { ReactComponent as Video } from './videoIcon.svg'

const icons = {
  Audio,
  Video,
}

export default icons
