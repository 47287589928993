import { useState } from 'react'
import { Flags } from 'react-feature-flags'
import { Tabs } from 'antd'
import styled from 'styled-components'

import Wrapper from 'components/Content'
import SesionList from 'components/sesiones/SesionList'
import StyledTitle from 'components/SpaceTitle'
import useConfig from 'config/configAdapter/useConfig'
import { colors, plurales } from 'Constants'
import { capitalizeFirstLetter } from 'utils'

const TIPOS = ['consejero', 'experto', 'admisibilidad']

function typeToFlag(t) {
  return `sesion${t.charAt(0).toUpperCase()}${t.slice(1)}`
}

function SesionConsejoList() {
  const [_actualKey, setActualKey] = useState()
  const config = useConfig()

  const defaultKey = TIPOS.findIndex((t) => config.flags[typeToFlag(t)])

  return (
    <Wrapper>
      <Flags authorizedFlags={['sesionesConsejo']}>
        <StyledHeader>
          <StyledTitle sectionName="Listado de sesiones" />
        </StyledHeader>
        <Tabs
          defaultActiveKey={defaultKey}
          items={TIPOS.map((tipo, idx) => ({
            children: (
              <SesionList
                space="Consejo Constitucional"
                tipo={tipo}
                defaultFilters={[{ field: 'sesion_type', terms: tipo }]}
              />
            ),
            disabled: !config.flags[typeToFlag(tipo)],
            key: idx,
            label: capitalizeFirstLetter(plurales[tipo]),
          }))}
          onChange={(activeKey) => {
            setActualKey(activeKey)
          }}
        />
      </Flags>
    </Wrapper>
  )
}

const StyledHeader = styled.div`
  display: flex;
  background-color: ${colors.white};
  padding-top: 0;
  align-items: center;
`

export default SesionConsejoList
