import { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Input } from 'antd'
import styled from 'styled-components'

import PriorityText from 'components/PriorityText'
import LoadingSpinner from 'components/StyledSpinner'
import { origenToPlurales } from 'Constants'
import usePagination from 'views/AuthenticatedSite/Pagination'
import ComisionCard from './ComisionCard'

const { Search } = Input

const COMISIONES = gql`
  query getComisiones(
    $space_name: String!
    $from: Int!
    $size: Int!
    $sortKey: String
    $sortOrder: String
    $phrase: String!
  ) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          comisiones(
            search: { phrase: $phrase }
            pagination: { from: $from, size: $size, sortKey: $sortKey, sortOrder: $sortOrder }
          ) {
            pageInfo {
              nextOffset
              size
              totalCount
              __typename
            }
            results {
              id
              nombre
              origen
              presidente {
                apellido_materno
              }
              integrantes {
                apellido_materno
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`

function Filter({ setFiltered }) {
  return (
    <Search
      placeholder="Buscar por publicación o comisión"
      allowClear
      onChange={(input) => {
        setFiltered(input.nativeEvent.target.value)
      }}
      className="comisiones-input"
      disabled
    />
  )
}

export default function Listado({ space, tipo }) {
  const { getPaginationParams, pagination, updatePaginationInfo } = usePagination({
    defaultPageSize: 100,
    defaultSortKey: 'scraped_at',
  })

  const [filtered, setFiltered] = useState('')

  const { data, loading, refetch } = useQuery(COMISIONES, {
    errorPolicy: 'all',
    onCompleted: ({ comisiones }) => {
      updatePaginationInfo(comisiones.pageInfo)
    },
    variables: {
      ...getPaginationParams(),
      phrase: '',
      space_name: space,
    },
  })
  useEffect(() => {
    refetch(getPaginationParams())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageNumber])

  if (loading) {
    return <LoadingSpinner />
  }
  const comisionesData = data?.me?.organizacion.espacio.comisiones.results || []

  return (
    <>
      <StyledHeader>
        <PriorityText
          className="section-title"
          bold
        >
          Comisiones
        </PriorityText>
        <Filter setFiltered={setFiltered} />
      </StyledHeader>
      <CardsGrid>
        {comisionesData.map(
          ({ id, nombre }) =>
            nombre.includes(filtered) && (
              <ComisionCard
                goTo={`/${origenToPlurales[tipo]}/comisiones/${id}`}
                nombre={nombre}
                key={nombre}
              />
            )
        )}
      </CardsGrid>
    </>
  )
}

const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(18vw, 200px), 1fr));
  row-gap: 29px;
  column-gap: 48px;
`

const StyledHeader = styled.div`
  margin: 0px 0px 15px 2px;
  display: flex;
  justify-content: space-between;

  .comisiones-input {
    width: 300px;
    margin-bottom: 17px;
  }

  .section-title {
    font-size: 16px;
    line-height: 22px;
    margin-right: 40px;
  }
`
