import { useAutoAnimate } from '@formkit/auto-animate/react'
import styled from 'styled-components'

import ColoredRectangle from 'components/ColorBorderRectagle'
import { colors } from 'Constants'
import InsideRows from './utilityStyles'

export function Label({ className, state }) {
  const stateMap = {
    aprobada: 'Aprobada por >3/5',
    debate: 'Debate',
    pendiente: 'Pendiente',
    rechazada: 'Rechazada por >2/3',
  }

  return (
    <ColoredRectangle
      className={className}
      color={colors.votacion[state]}
      text={stateMap[state]}
    />
  )
}

export default function State({ data }) {
  const [parent] = useAutoAnimate()
  const [firstRow] = [...data.versions]

  return (
    <Wrapper ref={parent}>
      <Label state={firstRow.state} />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  ${InsideRows}
  width: 100%;
  justify-content: left;
  flex-direction: row;
`
