import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Button, DatePicker, Space } from 'antd'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { styled } from 'styled-components'

import { spaces } from 'Constants'

dayjs.extend(customParseFormat)

const { RangePicker } = DatePicker

export default function RangeDatePicker({ onChange, onClear, onFilter, value }) {
  return (
    <RangePickerWrapper>
      <Space
        direction="vertical"
        size={12}
      >
        <RangePicker
          onChange={onChange}
          format="DD-MM-YYYY"
          value={value}
          placeholder={['Fecha inicial', 'Fecha de fin']}
        />
        <Space>
          <Button
            icon={<SearchOutlined />}
            onClick={onFilter}
            size="small"
            style={{ width: 90 }}
            type="primary"
          >
            Filtrar
          </Button>
          <Button
            onClick={onClear}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </Space>
      </Space>
    </RangePickerWrapper>
  )
}

const RangePickerWrapper = styled.div`
  padding: 8px;
  @media (max-width: ${spaces.mobileBreakpoint}) {
    .ant-picker-panels {
      display: flex;
      flex-direction: column;
    }
  }
`
