function addTimeStringTimes(time1, time2) {
  // Step 1: Convert string times to Date objects
  const time1Parts = time1.split(':')
  const time2Parts = time2.split(':')

  const date1 = new Date()
  date1.setHours(parseInt(time1Parts[0], 10))
  date1.setMinutes(parseInt(time1Parts[1], 10))
  date1.setSeconds(parseInt(time1Parts[2], 10))

  const date2 = new Date()
  date2.setHours(parseInt(time2Parts[0], 10))
  date2.setMinutes(parseInt(time2Parts[1], 10))
  date2.setSeconds(parseInt(time2Parts[2], 10))

  // Step 2: Add the time objects together
  const totalMilliseconds = date1.getTime() + date2.getTime()

  // Step 3: Format the result back to "00:00:00" format
  const resultDate = new Date(totalMilliseconds)
  const hours = String(resultDate.getHours()).padStart(2, '0')
  const minutes = String(resultDate.getMinutes()).padStart(2, '0')
  const seconds = String(resultDate.getSeconds()).padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
}

export default addTimeStringTimes
