import ReactPlayer from 'react-player'
import styled from 'styled-components'

function extractVideoId(url) {
  const regex = /v=([^&]+)/
  const match = url.match(regex)

  if (match && match[1]) {
    return match[1]
  }

  return null
}

export default function Video({
  className,
  isFromYoutube,
  isPlaying,
  playerRef,
  setIsLoaded,
  setPlayedSeconds,
  url,
}) {
  const playerConfig = {
    file: {
      attributes: {
        autoPlay: true,
      },
    },
    youtube: {
      playerVars: {
        autoplay: 1,
        controls: 1,
        videoId: extractVideoId(url),
      },
    },
  }

  const commonProps = {
    onProgress: (progress) => progress.loaded !== 0 && setPlayedSeconds(progress.playedSeconds),
    onReady: () => setIsLoaded(true),
    playing: isPlaying,
    progressInterval: 500,
    ref: playerRef,
  }

  return (
    <Wrapper className={className}>
      <ReactPlayer
        url={url}
        config={playerConfig}
        height="100%"
        width="100%"
        controls
        onProgress={commonProps.onProgress}
        onReady={commonProps.onReady}
        playing={commonProps.playing}
        progressInterval={commonProps.progressInterval}
        ref={commonProps.ref}
        wrapper={isFromYoutube ? YoutubeWrapper : undefined}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  max-height: 275px;
  height: 100%;
  width: 100%;
`

const YoutubeWrapper = styled.div`
  height: 0;
  overflow: hidden;
  max-width: 488px;
  max-height: 275px;
  padding-bottom: min(275px, 56.25%);
  position: relative;

  & iframe,
  object,
  embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`
