import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Table as BaseTable, Tooltip } from 'antd'
import styled from 'styled-components'

import Avatar from 'components/Avatar'
import FilterSearch from 'components/FilterSearch'
import PactoLabel from 'components/PactoLabel'
import LoadingSpinner from 'components/StyledSpinner'
import UnderlinedText from 'components/UnderlinedText'
import useConfig from 'config/configAdapter/useConfig'
import { plurales, tipoUnidadTerritorial, unidadTerritorial } from 'Constants'
import useDebounce from 'hooks/useDebounce'
import { TerritoriosQuery } from 'query/territoriosQuery'
import useComisiones from 'query/useComisiones'
import usePactos from 'query/usePactos'
import usePartidos from 'query/usePartidos'
import { capitalizeFirstLetterEachWord, normalNumberFormatter } from 'utils'

const { Column } = BaseTable

const PARLAMENTARIOS = gql`
  query getPersonas(
    $space_name: String!
    $from: Int!
    $size: Int!
    $sortKey: String
    $sortOrder: String
    $phrase: String!
    $filters: [FilterInput!]
  ) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          personas(
            search: { phrase: $phrase, filters: $filters }
            pagination: { from: $from, size: $size, sortKey: $sortKey, sortOrder: $sortOrder }
          ) {
            pageInfo {
              nextOffset
              size
              totalCount
            }
            results {
              id
              apellido_materno
              apellido_paterno
              asistencia {
                general
                presencial
                remoto
              }
              electo_en {
                orden
              }
              nombre_completo
              nombres
              pacto
              roles
              partido
              comision
              subpacto
              imagen_url
              congresista
              profesiones
              total_tweets
              total_apariciones_en_medios
              total_proyectos
            }
          }
        }
      }
    }
  }
`

const getFiltersSummary = (filters) => {
  const summaryEntries = filters.map((filter) => [filter.field, filter.conj || filter.terms])
  return Object.fromEntries(summaryEntries)
}

const updateFilters = (filtersDict, setFilters, excludeFields = []) => {
  const filtersList = Object.keys(filtersDict)
    .filter((field) => !excludeFields.includes(field))
    .map((field) => ({
      field,
      terms: filtersDict[field],
    }))

  setFilters(filtersList)
}

function TerritorioHeader({ uTerritorial }) {
  return (
    <Tooltip
      placement="top"
      title={uTerritorial}
    >
      {uTerritorial}
    </Tooltip>
  )
}

function NameColumnContent({
  apellidoMaterno,
  apellidoPaterno,
  id,
  imagenURL,
  nombres,
  partido,
  tipo,
}) {
  const rawName = `${nombres} ${apellidoPaterno} ${apellidoMaterno}`
  const name = capitalizeFirstLetterEachWord(rawName)

  const { avatarColors } = useConfig()
  return (
    <NameWrapper key={id}>
      <Avatar
        src={imagenURL}
        color={avatarColors[partido]}
      />
      <NameContainer>
        <StyledLink to={`/${plurales[tipo]}/${id}`}>
          <UnderlinedText $bold>
            {name}
            <br />
          </UnderlinedText>
          <SubtitleStyled $avatarColors={avatarColors}>{partido}</SubtitleStyled>
        </StyledLink>
      </NameContainer>
    </NameWrapper>
  )
}

export default function Table({
  currentFilters,
  getPaginationParams,
  pagination,
  personaSearch,
  phrase,
  roleFilter,
  setCurrentFilters,
  setOrder,
  setPageNumber,
  setPhrase,
  setSort,
  space,
  tipo,
  updatePaginationInfo,
}) {
  const personaSearchDebounced = useDebounce(personaSearch, 500)
  const [filtros, setFiltros] = useState(roleFilter ? [roleFilter] : [])
  const { avatarColors } = useConfig()

  const personasVariables = {
    ...getPaginationParams(),
    phrase,
    space_name: space,
  }

  const { partidosArr } = usePartidos({
    space_name: space,
    tipo,
  })

  const { pactosArr } = usePactos({
    space_name: space,
    tipo,
  })

  const { data, loading, refetch } = useQuery(PARLAMENTARIOS, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ me }) => {
      updatePaginationInfo(me?.organizacion?.espacio?.personas?.pageInfo)
    },
    variables: personasVariables,
  })

  useEffect(() => {
    setPhrase(personaSearchDebounced)
    setPageNumber(1)
  }, [personaSearchDebounced])

  const config = useConfig()

  const { territoriosArr } = TerritoriosQuery(tipoUnidadTerritorial[tipo])
  const { comisionesArr } = useComisiones({
    space_name: space,
    tipo,
  })

  useEffect(() => {
    refetch({
      ...getPaginationParams(),
      filters: filtros,
      phrase,
      space_name: space,
    })
    setCurrentFilters(getFiltersSummary(filtros))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtros,
    pagination.sort,
    pagination.order,
    pagination.search,
    pagination.pageSize,
    pagination.pageNumber,
  ])

  if (loading) {
    return <LoadingSpinner />
  }

  const people =
    data?.me?.organizacion?.espacio?.personas?.results.map((p) => ({
      _targets: [
        p.nombres,
        p.apellido_paterno,
        p.apellido_materno,
        p.subpacto,
        p.proyectos,
        p.congresista,
      ],
      ...p,
    })) || []

  const onTableChange = (tablePagination, _filters, sorter) => {
    if (tablePagination) {
      setPageNumber(tablePagination.current)
    }
    if (sorter) {
      const sortKey = sorter.column?.key ?? 'apellido_paterno.keyword'
      const sortOrder = sorter.order

      setOrder(sortOrder)
      setSort(sortKey)
    }
  }

  return (
    <BaseTable
      id={`perfiles-table-${tipo}`}
      getPopupContainer={() => document.getElementById(`perfiles-table-${tipo}`)}
      rowKey="id"
      scroll={{ x: true }}
      dataSource={people}
      onChange={onTableChange}
      pagination={{
        current: pagination.pageNumber,
        filters: filtros,
        onChange: setPageNumber,
        pageSize: pagination.pageSize,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
        total: pagination.totalItems,
      }}
    >
      <Column
        key="id"
        render={({
          apellido_materno: apellidoMaterno,
          apellido_paterno: apellidoPaterno,
          id,
          imagen_url: imagenURL,
          nombres,
          partido,
          subpacto,
        }) => (
          <NameColumnContent
            apellidoMaterno={apellidoMaterno}
            apellidoPaterno={apellidoPaterno}
            id={id}
            imagenURL={imagenURL}
            nombres={nombres}
            partido={partido}
            subpacto={subpacto}
            tipo={tipo}
            espacio={space}
          />
        )}
        title="Nombre"
        filterDropdown={
          <FilterSearch
            optionsArr={partidosArr}
            currentFiltersDict={currentFilters}
            field="partido.keyword"
            setQueryFilters={setFiltros}
            updateFilters={updateFilters}
            width="300px"
          />
        }
      />
      {config.canViewColumn.pacto?.includes(tipo) && (
        <Column
          key="pacto"
          render={({ subpacto }) => (
            <PactoLabel color={avatarColors[subpacto]}>{subpacto}</PactoLabel>
          )}
          filterDropdown={
            <FilterSearch
              optionsArr={pactosArr}
              currentFiltersDict={currentFilters}
              field="subpacto.keyword"
              setQueryFilters={setFiltros}
              updateFilters={updateFilters}
              width="300px"
            />
          }
          title="Pacto"
          width={210}
        />
      )}
      {config.canViewColumn.territorio?.includes(space) && (
        <Column
          key="territorio"
          render={({ electo_en: electoEn }) => (electoEn ? `Nº ${electoEn.orden}` : '-')}
          title={
            <TerritorioHeader
              uTerritorial={capitalizeFirstLetterEachWord(unidadTerritorial[tipo])}
            />
          }
          align="right"
          width={100}
          filterDropdown={
            <FilterSearch
              optionsArr={territoriosArr}
              currentFiltersDict={currentFilters}
              field="electo_en_id"
              setQueryFilters={setFiltros}
              updateFilters={updateFilters}
            />
          }
        />
      )}
      {config.canViewColumn.comision?.includes(tipo) && (
        <Column
          key="comision"
          render={({ comision }) => comision || 'Próximamente'}
          title="Comisión a la que pertenece"
          filterDropdown={
            <FilterSearch
              optionsArr={comisionesArr}
              currentFiltersDict={currentFilters}
              field="comision.keyword"
              setQueryFilters={setFiltros}
              updateFilters={updateFilters}
              width={300}
            />
          }
        />
      )}
      {config.canViewColumn.profesion?.includes(space) && (
        <Column
          key="profesion"
          render={({ profesiones }) => profesiones || '-'}
          title="Profesión"
        />
      )}
      {config.canViewColumn.tweets?.includes(space) && (
        <Column
          key="total_tweets"
          align="right"
          render={({ total_tweets: totalTweets }) => normalNumberFormatter.format(totalTweets) ?? 0}
          title="Nº de Tweets"
          width={145}
          sorter
          sortOrder={pagination.sort === 'total_tweets' ? pagination.order : null}
        />
      )}
      {config.canViewColumn.noticias?.includes(space) && (
        <Column
          key="total_apariciones_en_medios"
          align="right"
          render={({ total_apariciones_en_medios: totalNoticias }) => totalNoticias ?? 0}
          title="Apariciones en medios"
          width={185}
          sorter
          sortOrder={pagination.sort === 'total_apariciones_en_medios' ? pagination.order : null}
        />
      )}
      {config.canViewColumn.proyectos?.includes(space) && (
        <Column
          key="id"
          render={({ total_proyectos: totalProyectos }) => totalProyectos ?? 0}
          title="Proyectos presentados"
          align="right"
          width={190}
        />
      )}
    </BaseTable>
  )
}

const StyledLink = styled(Link)`
  color: Black !important;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
`

const NameContainer = styled.div``

const SubtitleStyled = styled.span`
  color: ${({ $avatarColors, partido }) => $avatarColors[partido]};
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  font-family: 'Open Sans';
`

const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 8px;
`
