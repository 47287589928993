const splitByIncisos = (content) => {
  // eslint-disable-next-line max-len
  const incisoPattern =
    '\\s*\\([\\s\\w]*[inciso|Inciso|INCISO|incisos| ][\\s\\w,]*\\)(:*[\\.-]*)\\s*'
  const incisoRegex = new RegExp(incisoPattern)
  return content.split(incisoRegex)
}

const filterSpecialCharacters = (characters) => {
  const undesiredCharactersPattern = '(^[\\s]*:[\\s]*|(^(?!.))|^(\\.-)+)'
  const charactersToFilter = new RegExp(undesiredCharactersPattern)
  return characters.filter((c) => !charactersToFilter.test(c))
}

const handleNewLines = (characters) => {
  /*
   * Se unen con salto de línea, puesto que cada (*inciso*)
   * representa uno. Posteriormente se separan por salto
   * de línea, para respetar los que venían desde el
   * contenido original
   */
  const newLinePattern = '\\r?\\n'
  const newLineRegex = new RegExp(newLinePattern)
  const joinUsingNewLines = characters.join('\n')
  return joinUsingNewLines.split(newLineRegex)
}

const parseContent = (content) => {
  const splitContent = splitByIncisos(content)
  const trimContent = splitContent.map((c) => c.trim())
  const filterContent = filterSpecialCharacters(trimContent)
  return handleNewLines(filterContent)
}

export default parseContent
export { filterSpecialCharacters, handleNewLines, parseContent, splitByIncisos }
