import { formatLocale } from 'd3-format'
import { DateTime, Settings } from 'luxon'

Settings.defaultLocale = 'es'

const locale = formatLocale({
  currency: ['$', ''],
  decimal: ',',
  grouping: [3],
  thousands: '.',
})

export function formatRut(value) {
  const [num, dv] = value.split('-')
  const format = locale.format(',d')
  return `${format(num)}-${dv.toUpperCase()}`
}

export function formatLongDate(value) {
  return DateTime.fromISO(value).toFormat('d MMM y, t')
}

export function formatLongOnlyDate(value) {
  return DateTime.fromISO(value).toFormat('d MMM y')
}

export function formatShortDate(value) {
  return DateTime.fromISO(value).toFormat('dd MMM')
}

export function formatMediumDate(value) {
  return DateTime.fromISO(value).toFormat('MMMM y')
}

export function formatNationalDate(value) {
  return DateTime.fromISO(value).toFormat('dd/MM/yyyy')
}

export function formatLongOnlyDateWithZero(value) {
  const date = DateTime.fromISO(value)
  const day = date.day < 10 ? `0${date.day}` : date.day
  return `${day} ${date.toFormat('LLL y')}`
}
