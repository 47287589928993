import React from 'react'
import styled from 'styled-components'

import { spaces } from 'Constants'

const Wrapper = styled.div`
  /* Estilos para el rectángulo */
  border: ${({ color }) => `1px solid ${color}`};
  display: flex;
  justify-content: center;
  align-items: baseline;
  line-height: 1em;
  border-radius: 5px;
  width: fit-content;
  height: min-content;

  /* Estilos para el texto */
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  padding: ${spaces.s} ${spaces.m};
`

function ColoredRectangle({ className, color, text }) {
  return (
    <Wrapper
      className={className}
      color={color}
    >
      {text}
    </Wrapper>
  )
}

export default ColoredRectangle
