import { Layout } from 'antd'
import styled from 'styled-components'

import Transcripcion from './Transcripcion'
import Video from './Video'

const { Content } = Layout

const isFromYoutube = (url) => url && url.startsWith('https://www.youtube.com')

export default function TranscriptionContent({
  editMode,
  filename,
  goto,
  isPlaying,
  origen,
  playedSeconds,
  playerRef,
  sesion,
  setIsLoaded,
  setPlayedSeconds,
  transcripciones,
  transcripcionRef,
  url,
}) {
  return (
    <StyledContent>
      <Video
        isPlaying={isPlaying}
        playerRef={playerRef}
        setIsLoaded={setIsLoaded}
        setPlayedSeconds={setPlayedSeconds}
        url={url}
        isFromYoutube={isFromYoutube(url)}
      />
      {transcripciones.length > 0 && (
        <StyledTranscriptionList>
          <Transcripcion
            highlightSeconds={playedSeconds}
            onSeek={goto}
            origen={origen}
            ref={transcripcionRef}
            transcripciones={transcripciones}
            sesion={sesion}
            editMode={editMode}
            filename={filename}
          />
        </StyledTranscriptionList>
      )}
    </StyledContent>
  )
}

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`

const StyledTranscriptionList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  .ant-table-wrapper {
    height: 100%;

    .ant-table-container {
      display: flex;
      flex-direction: column;

      .ant-table-header {
        position: sticky;
      }

      .ant-table-body {
        overflow-y: auto;
      }
    }

    .ant-table {
      height: 100%;
    }
  }
`
