import { UserOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import Avatar from 'components/Avatar'
import useConfig from 'config/configAdapter/useConfig'
import { spaces } from 'Constants'
import { toSeconds } from '../helpers'
import Name from './Name'
import Time from './Time'

export default function Speaker({ onSeek, origen, rawFullName, speaker, tiempo }) {
  const { avatarColors } = useConfig()

  return (
    <Wrapper>
      <Avatar
        src={speaker?.imagen_url}
        color={speaker?.partido && avatarColors[speaker.partido]}
        icon={!speaker && <UserOutlined />}
        size={35}
      />
      <Right>
        <Name
          origen={origen}
          rawFullName={rawFullName}
          speaker={speaker}
        />
        <Time
          time={tiempo}
          onClick={() => onSeek(toSeconds(tiempo))}
          className="align-top"
        />
      </Right>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  font-weight: 700;
  gap: ${spaces.s};
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
`
