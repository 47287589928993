import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Empty, List as ListBase } from 'antd'
import { styled } from 'styled-components'

import { spaces } from 'Constants'
import { ArticuloCard } from 'views/AuthenticatedSite/Articulos/ArticuloCard'
import Header from './Header'

export default function List({
  CardComponent = ArticuloCard,
  defaultDownloadLink = `https://d3tks6n15ycs13.cloudfront.net
/textos/Texto-Definitivo-CPR-2022-Tapas.pdf`,
  leftHeader,
  legend,
  origin,
  results,
  textTitle,
  tooltipText,
}) {
  const { comision } = useParams()

  const { hash } = useLocation()

  const [articulos, setArticulos] = useState([])
  const [downloadUrl, setDownloadUrl] = useState(null)

  useEffect(() => {
    const articulosFromBackend = [...(results || [])]
    articulosFromBackend?.sort(
      (a, b) => Number(a.nombre.match(/\d+/)) - Number(b.nombre.match(/\d+/))
    )
    const selectedArticuloKey = decodeURI(hash).replace(/^#/, '')
    setArticulos(articulosFromBackend)
    document.getElementById(selectedArticuloKey)?.scrollIntoView()
  }, [results, hash])

  useEffect(() => {
    const articulo = articulos.length > 0 && articulos[0]
    setDownloadUrl(articulo?.downloadUrl || defaultDownloadLink)
  }, [articulos, defaultDownloadLink])

  const EmptyResults = (
    <Empty
      description={`No se encontraron artículos
    aprobados en la comisión de ${comision}.`}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  )

  return (
    <Wrapper>
      <ListBase
        dataSource={articulos}
        header={
          <Header
            downloadUrl={downloadUrl}
            leftHeader={leftHeader}
            tooltipText={tooltipText}
          />
        }
        locale={{ emptyText: EmptyResults }}
        renderItem={(articulo) => (
          <ListBase.Item id={`${articulo.nombre}_${articulo.titulo}`}>
            <CardComponent
              {...articulo}
              textTitle={textTitle}
              legend={legend}
              origin={origin}
            />
          </ListBase.Item>
        )}
        rowKey={({ nombre, titulo }) => [nombre, titulo].join('-')}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 ${spaces.l} ${spaces.l} ${spaces.l};
  @media (max-width: ${spaces.phoneBreakpoint}) {
    padding: 0 ${spaces.m} ${spaces.m} ${spaces.m};
  }
`
