import styled from 'styled-components'

import { normalNumberFormatter } from 'utils'

export default function NumberCard({ counter, title }) {
  return (
    <Card>
      <Emojis>{normalNumberFormatter.format(counter)}</Emojis>
      {title}
    </Card>
  )
}

const Card = styled.section`
  max-width: 100%;
  max-height: 250px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  padding-top: 80px;
  background-color: white;
  border-radius: 5px;
  gap: 24px;
  text-align: center;
`

const Emojis = styled.span`
  color: #363795;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
