import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'

import { spaces } from 'Constants'
import { getGuestsObj, toSeconds } from '../helpers'
import Speaker from './Speaker'

export default forwardRef(
  (
    {
      className,
      editMode,
      filename,
      highlightSeconds,
      onSeek,
      origen,
      transcripciones: tOriginales,
    },
    ref
  ) => {
    const [following, setFollowing] = useState(true)
    const editedTranscription = editMode && JSON.parse(localStorage.getItem(filename))
    const transcripciones = editedTranscription || tOriginales
    const highlightedIdx = transcripciones.findIndex(
      (t, idx) =>
        highlightSeconds >= toSeconds(t.tiempo_inicio) &&
        (idx === transcripciones.length - 1 ||
          highlightSeconds < toSeconds(transcripciones[idx + 1].tiempo_inicio))
    )

    useImperativeHandle(ref, () => ({
      follow: () => setFollowing(true),
    }))

    const segmentoRef = useRef()

    useEffect(() => {
      if (segmentoRef.current && highlightedIdx && following) {
        segmentoRef.current.scrollIntoView()
      }
    }, [highlightedIdx, following])

    const guestsObj = getGuestsObj(transcripciones)

    return (
      <Wrapper className={className}>
        {transcripciones.map(
          (
            {
              hablante,
              hablante_nombre: hablanteNombre,
              speakerIdPrediction,
              texto,
              tiempo_inicio: tiempoInicio,
            },
            index
          ) => {
            const isCurrent = index === highlightedIdx && 'highlighted-row'
            const tRef = index === highlightedIdx ? segmentoRef : undefined
            const key = `${tiempoInicio}-${texto}-${hablanteNombre}-${index}`
            return (
              <Transcripcion
                key={key}
                ref={tRef}
              >
                <Speaker
                  origen={origen}
                  rawFullName={hablante?.nombre_completo || guestsObj[hablanteNombre]}
                  speaker={hablante}
                  predictedSpeaker={speakerIdPrediction}
                  onSeek={onSeek}
                  tiempo={tiempoInicio}
                />
                <Text $bold={isCurrent}>{texto}</Text>
              </Transcripcion>
            )
          }
        )}
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: ${spaces.l};
`

const Transcripcion = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.s};
`

const Text = styled.div`
  padding-right: 10px;
  font-size: 12px;
  ${({ $bold }) => $bold && 'font-weight: 500;'}
`
