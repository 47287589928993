import { useNavigate } from 'react-router-dom'
import { Card } from 'antd'
import styled from 'styled-components'

import PriorityText from 'components/PriorityText'
import useConfig from 'config/configAdapter/useConfig'
import { colors } from 'Constants'

export default function ComisionCard({ documentsCount, goTo, lastVoting, nombre }) {
  const parsedLastVoting = !lastVoting ? '-' : lastVoting

  const splitName = nombre.split(' ')
  const [nameHead, ...rest] = splitName
  const nameTail = !rest ? '' : rest.join(' ')

  const navigate = useNavigate()

  const { primaryColor } = useConfig()

  return (
    <StyledCard
      onClick={() => navigate(goTo)}
      hoverable
      primaryColor={primaryColor}
    >
      <div className="card-content">
        <PriorityText
          bold
          className="card-title"
        >
          Comisión {nameHead}
          <br />
          {nameTail}
        </PriorityText>
        <br />
        <div>
          <p className="documents-count">{documentsCount ? `${documentsCount} artículos` : null}</p>
          <p className="last-voting">{`Última votación: ${parsedLastVoting}`}</p>
        </div>
      </div>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  white-space: normal;
  border-radius: 5%;
  margin-bottom: 0;
  padding-bottom: 0;
  max-width: 250px;
  min-width: 200px;
  height: 160px;
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 200px;
  }

  .ant-card-body {
    height: 100%;
    display: flex;
  }

  .documents-count {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 0;
  }

  .last-voting {
    font-size: 9px;
    color: ${colors.menuUnselectedItem};
    font-style: italic;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .card-title {
    font-size: 12px;
    color: ${(props) => props.primaryColor};
  }
`
