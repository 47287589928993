import { Flags } from 'react-feature-flags'
import { useNavigate, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { styled } from 'styled-components'

import Content from 'components/Content'
import SesionEnSala from 'components/sesiones/SesionesEnSala'
import LoadingSpinner from 'components/StyledSpinner'

const SESION = gql`
  query getSesion($spaceName: String!, $id: String!) {
    me {
      organizacion {
        espacio(space_name: $spaceName) {
          sesion(id: $id) {
            id
            origen
            fecha
            video_url
            titulo
            transcripciones {
              hablante_id
              hablante_nombre
              hablante {
                id
                nombre_completo
              }
              texto
              tiempo_inicio
              duracion_seg
              tiempo_fin
              tiempo_duracion
            }
          }
        }
      }
    }
  }
`

export default function SesionCiudadania() {
  const { sesionId } = useParams()
  const navigate = useNavigate()

  const { data, loading } = useQuery(SESION, { variables: { id: sesionId, spaceName: 'Regiones' } })

  if (loading) {
    return <LoadingSpinner />
  }
  if (!data) {
    navigate('./..')
    return null
  }

  return (
    <Wrapper>
      <Flags authorizedFlags={['colunga']}>
        <SesionEnSala sesion={data.me.organizacion.espacio.sesion} />
      </Flags>
    </Wrapper>
  )
}
const Wrapper = styled(Content)`
  height: 100%;
  padding-bottom: 0;
`
