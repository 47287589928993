import React from 'react'
import styled from 'styled-components'

import pciudadanaIndex from 'assets/images/pciudadanaIndex.svg'

const Rectangle = styled.div`
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  width: 45%;
  margin-right: 100px;
  margin-left: 8%;
  margin-top: -15%;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-top: 45px;
`
const Title = styled.h1`
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 25px;
  color: #071e26;
`

const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  width: 85%;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
  color: #071e26;
`

function ciudadanaIndexPage() {
  return (
    <Rectangle>
      <ContentWrapper>
        <Title>
          ¿Qué es la Plataforma <br /> de Audiencias Públicas?
        </Title>
        <Text>
          Un sitio donde podrás encontrar el contenido de todas las audiencias en las que participó
          la ciudadanía para entregar su opinión sobre el anteproyecto constitucional. Puedes
          acceder a las transcripciones y videos de cada instancia, y también buscar tus temas de
          interés para saber qué se dijo en las audiencias sobre éstos.
        </Text>
      </ContentWrapper>
      <Image
        src={pciudadanaIndex}
        alt="Ciudadana Index"
      />
    </Rectangle>
  )
}

export default ciudadanaIndexPage
