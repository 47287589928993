import { gql, useQuery } from '@apollo/client'

import { Environment, useEnvironment } from 'components/Fetch'
import Loading from 'components/StyledSpinner'
import useConfig from 'config/configAdapter/useConfig'

const FLAGS = gql`
  query getFeatureFlags {
    me {
      organizacion {
        activeFeatureFlags
      }
    }
  }
`

export default function SpaceProvider({ children }) {
  const initEnv = useEnvironment()
  const { anonymousMode } = useConfig()
  const { data, loading } = useQuery(FLAGS, { skip: anonymousMode?.active })

  if (!anonymousMode?.active && loading) {
    return <Loading />
  }

  const activeFeatureFlags = data?.me?.organizacion?.activeFeatureFlags
  const spaces = anonymousMode?.active
    ? anonymousMode?.spaces
    : {
        canViewConsejoConstitucional: activeFeatureFlags?.includes('Consejo Constitucional'),
        canViewConstituyentes: activeFeatureFlags?.includes('Convencionales'),
        canViewDiputados: activeFeatureFlags?.includes('Diputados'),
        canViewRegiones: activeFeatureFlags?.includes('Regiones'),
        canViewSenadores: activeFeatureFlags?.includes('Senadores'),
      }

  return <Environment env={{ ...initEnv, spaces }}>{children}</Environment>
}
