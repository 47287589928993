import { Empty } from 'antd'
import styled from 'styled-components'

import StyledTable from 'components/StyledTable'

const { Column } = StyledTable

function DetalleProyectos() {
  const data = []

  if (!data) {
    ;<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }
  const proyectosData = data

  return data ? (
    <StyledTable
      dataSource={proyectosData}
      scroll={{ y: 400 }}
      rowKey="boletin"
    >
      <Column
        key="boletin"
        title="Nº Boletín"
        render={({ boletin }) => (
          <StyledBoletin
            href=""
            target="_blank"
          >
            {boletin}
          </StyledBoletin>
        )}
      />
      <Column
        width="40%"
        dataIndex="titulo"
        key="titulo"
        title="Título"
      />
      <Column
        dataIndex="fecha_presentacion"
        key="fecha_presentacion"
        title="Fecha"
        align="right"
        width={125}
        sorter
      />
      <Column
        dataIndex="estado"
        key="estado"
        title="Estado"
      />
    </StyledTable>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )
}

const StyledBoletin = styled.a`
  color: black !important;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-decoration: underline !important;
`

export default DetalleProyectos
