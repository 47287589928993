import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Card, Divider, Empty, Pagination } from 'antd'
import { DateTime } from 'luxon'
import styled from 'styled-components'

import Avatar from 'components/Avatar'
import LoadingSpinner from 'components/StyledSpinner'
import useConfig from 'config/configAdapter/useConfig'
import { singulares } from 'Constants'
import { capitalizeFirstLetter, capitalizeFirstLetterEachWord, formatGenericDatetime } from 'utils'
import usePagination from 'views/AuthenticatedSite/Pagination'

const groupByDate = (dataArray) => {
  const results = {}
  dataArray.forEach((data) => {
    const dataDate = DateTime.fromISO(data.fecha)
    if (!Object.keys(results).includes(dataDate.year.toString())) {
      results[dataDate.year] = {}
    }
    if (!Object.keys(results[dataDate.year]).includes(dataDate.month.toString())) {
      results[dataDate.year][dataDate.month] = []
    }
    results[dataDate.year][dataDate.month].push(data)
  })
  return results
}

const sortFunc = (obj1, obj2) => obj2 - obj1

const INTERVENCIONES = gql`
  query getIntervenciones(
    $id: String!
    $spaceName: String!
    $from: Int!
    $size: Int!
    $sortKey: String
    $sortOrder: String
  ) {
    me {
      organizacion {
        espacio(space_name: $spaceName) {
          persona(id: $id) {
            nombre_completo
            imagen_url
            partido
            intervenciones(
              pagination: { from: $from, size: $size, sortKey: $sortKey, sortOrder: $sortOrder }
            ) {
              pageInfo {
                nextOffset
                size
                totalCount
              }
              results {
                titulo
                fecha
                sesion_id
                menciones {
                  texto
                  tiempo_inicio
                }
              }
            }
          }
        }
      }
    }
  }
`

function DetalleIntervenciones({ activeTab, handlerSetTab, spaceName }) {
  const { avatarColors } = useConfig()
  const { id, role } = useParams()
  const tipo = singulares[role]
  const { getPaginationParams, pagination, setPageNumber, updatePaginationInfo } = usePagination({
    defaultFilters: [],
    defaultSortKey: 'fecha',
    defaultSortOrder: 'desc',
  })
  const queryParams = getPaginationParams()
  queryParams.id = `${tipo}:${id}`
  const { data, loading, refetch } = useQuery(INTERVENCIONES, {
    errorPolicy: 'all',
    onCompleted: ({
      me: {
        organizacion: {
          espacio: { persona },
        },
      },
    }) => {
      updatePaginationInfo(persona.intervenciones.pageInfo)
      const results = persona?.intervenciones?.results || []
      if (activeTab === '3' && !results?.length) handlerSetTab('4')
    },
    variables: {
      ...queryParams,
      spaceName,
    },
  })
  const { persona } = data?.me?.organizacion?.espacio || []
  const results = persona?.intervenciones?.results || []

  useEffect(() => {
    refetch(getPaginationParams())
  }, [pagination.pageSize, pagination.pageNumber])

  if (loading) {
    return <LoadingSpinner />
  }

  if (!results?.length) {
    return (
      <EmptyContainer>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </EmptyContainer>
    )
  }
  const groupedIntervenciones = groupByDate(results)
  return (
    <StyledContainer>
      <div>
        {Object.keys(groupedIntervenciones)
          .sort(sortFunc)
          .map((year) =>
            Object.keys(groupedIntervenciones[year.toString()])
              .sort(sortFunc)
              .map((month) => {
                const IntervencionesList = groupedIntervenciones[year.toString()][
                  month.toString()
                ].map((intervencion) => {
                  const mencionesList = []
                  for (let i = 0; i < intervencion.menciones.length; i += 1) {
                    const mencion = intervencion.menciones[i]
                    mencionesList.push(
                      <div key={`${intervencion.sesion_id}-${mencion.tiempo_inicio}`}>
                        <MencionFragment
                          nombre={capitalizeFirstLetterEachWord(persona.nombre_completo)}
                          role={role}
                          sesionId={intervencion.sesion_id}
                          texto={mencion.texto}
                          tiempoInicio={mencion.tiempo_inicio}
                          useHeaderDivider={i !== 0}
                          imagenUrl={persona.imagen_url}
                          imagenColor={avatarColors[persona.partido]}
                        />
                      </div>
                    )
                  }
                  return (
                    <div key={intervencion.sesion_id}>
                      <StyledCard
                        key={`${intervencion.sesion_id}-card`}
                        title={<TituloIntervencion titulo={intervencion.titulo} />}
                        bodyStyle={{ padding: '14px 24px' }}
                        headStyle={{ borderBottomColor: '#E0E0E0' }}
                      >
                        {mencionesList}
                      </StyledCard>
                    </div>
                  )
                })
                return (
                  <div key={`${year}-${month}`}>
                    <StyledMonth key={`${year}-${month}-label`}>
                      {capitalizeFirstLetter(
                        formatGenericDatetime(
                          `${year}-${month.toString().padStart(2, '0')}-01T00:00:00`,
                          'MMMM y'
                        )
                      )}
                    </StyledMonth>
                    {IntervencionesList}
                  </div>
                )
              })
          )}
      </div>
      <StyledPagination
        current={pagination.pageNumber}
        onChange={setPageNumber}
        pageSize={pagination.pageSize}
        showSizeChanger={false}
        showTotal={(total, range) => `${range[0]}-${range[1]} de ${total}`}
        total={pagination.totalItems}
      />
    </StyledContainer>
  )
}

function TituloIntervencion({ titulo }) {
  return (
    <HeaderWrapper key={titulo}>
      <StyledRow key={`${titulo}-name`}>{titulo}</StyledRow>
      <StyledTitle key={`${titulo}-place`}>Congreso de Chile</StyledTitle>
    </HeaderWrapper>
  )
}

const rolesAdapter = {
  consejeros: 'consejo',
}

function MencionFragment({
  imagenColor,
  imagenUrl,
  nombre,
  role,
  sesionId,
  texto,
  tiempoInicio,
  useHeaderDivider,
}) {
  const textFragment = texto.slice(0, 300).split(' ').slice(0, -1).join(' ')
  const singularRole = rolesAdapter[role] || role
  const sesionLink = `/${singularRole}/sesiones/${sesionId}/#${tiempoInicio}`

  return (
    <div>
      {useHeaderDivider ? <Divider /> : null}
      <MencionWrapper key={`${sesionId}-${tiempoInicio}-mencion`}>
        <div>
          <Avatar
            size={50}
            src={imagenUrl}
            color={imagenColor}
          />
        </div>
        <div>
          <NameTimeContainer key={`${sesionId}-${tiempoInicio}-name`}>
            {nombre}
            <span> - </span>
            <Link
              to={sesionLink}
              key={`${sesionId}-${tiempoInicio}-link`}
            >
              <StyledTime key={`${sesionId}-${tiempoInicio}-time`}>{tiempoInicio}</StyledTime>
            </Link>
          </NameTimeContainer>
          <StyledTranscriptText key={`${sesionId}-${tiempoInicio}-text`}>
            {textFragment}
          </StyledTranscriptText>
        </div>
      </MencionWrapper>
    </div>
  )
}

const MencionWrapper = styled.div`
  display: flex;
  gap: 12px;
`

const StyledCard = styled(Card)`
  margin-bottom: 16px;
  border-color: #e0e0e0;
  border-radius: 2px;
`

const HeaderWrapper = styled.div`
  padding: 10px 0;
`

const EmptyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledContainer = styled.div``

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
`

const StyledRow = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
`

const StyledTime = styled.div`
  font-weight: 700;
  color: #363795;
  letter-spacing: -0.32812514901161194px;
  text-align: left;
`

const StyledTranscriptText = styled.div`
  margin: 6px 0;
  color: #333333;
  font-style: italic;
  font-size: 12px;
`

const NameTimeContainer = styled.div`
  display: flex;
  grid-template-columns: auto auto 1fr;
  grid-gap: 4px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
`

const StyledMonth = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
`

const StyledPagination = styled(Pagination)`
  margin-block-start: 24px;
  display: flex;
  justify-content: right;
`

export default DetalleIntervenciones
