import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Tabs } from 'antd'
import styled from 'styled-components'

import Content from 'components/Content'
import LoadingSpinner from 'components/StyledSpinner'
import { singulares, spaces } from 'Constants'
import { capitalizeFirstLetter } from 'utils'
import DetalleIntervenciones from '../tabs/DetalleIntervenciones'
import DetalleMediosDigitales from '../tabs/DetalleMediosDigitales'
import DetalleNoticias from '../tabs/DetalleNoticias'
import DetalleProyectos from '../tabs/DetalleProyectos'
import DatosPersonales from './DatosPersonales'
import DetalleHeader from './DetalleHeader'

const PARLAMENTARIO = gql`
  query getPersona($spaceName: String!, $id: String!) {
    me {
      organizacion {
        espacio(space_name: $spaceName) {
          persona(id: $id) {
            id
            congresista
            edad
            electo_en {
              orden
              tipo
            }
            lista
            nombre_completo
            profesiones
            redes_sociales {
              id
              source
              link
            }
            subpacto
            partido
            imagen_url
            imagen_partido_url
            imagen_pacto_url
            tweets {
              pageInfo {
                totalCount
              }
            }
          }
        }
      }
    }
  }
`

const ConsejoMembers = ['expertos', 'admisibilidad', 'consejeros', 'mixta']

function getSpaceName(role, consejoList) {
  if (consejoList.includes(role)) {
    return 'Consejo Constitucional'
  }
  return capitalizeFirstLetter(role)
}

function getRedirect(role, consejoList) {
  if (consejoList.includes(role)) {
    return 'consejo'
  }
  return role
}

function getTabsItems(role, spaceName, personaId, isCongresista, handlerSetTab, activeTab) {
  return [
    isCongresista && {
      children: (
        <DetalleProyectos
          role={role}
          spaceName={spaceName}
        />
      ),
      key: '1',
      label: 'Proyectos Presentados',
    },
    {
      children: (
        <DetalleIntervenciones
          spaceName={spaceName}
          handlerSetTab={handlerSetTab}
          activeTab={activeTab}
        />
      ),
      key: '3',
      label: 'Intervenciones en sesiones',
    },
    {
      children: (
        <DetalleNoticias
          id={personaId}
          spaceName={spaceName}
        />
      ),
      key: '4',
      label: 'Apariciones en medios de comunicación',
    },
    {
      children: (
        <DetalleMediosDigitales
          personaId={personaId}
          spaceName={spaceName}
        />
      ),
      key: '5',
      label: 'Actividad en Twitter',
    },
  ]
}

export default function Detalle() {
  const navigate = useNavigate()
  const { id, role } = useParams()
  const tipo = singulares[role]
  const personaId = `${tipo}:${id}`
  const spaceName = getSpaceName(role, ConsejoMembers)
  const redirect = getRedirect(role, ConsejoMembers)

  const [activeTab, setActiveTab] = useState('3')
  const handlerSetTab = (tabKey) => setActiveTab(tabKey)

  const { data, loading } = useQuery(PARLAMENTARIO, {
    errorPolicy: 'content_type=None',
    variables: { id: personaId, spaceName },
  })
  if (loading) {
    return <LoadingSpinner />
  }
  if (!data || !data.me.organizacion.espacio.persona) {
    navigate(`/${redirect}/perfiles`)
    return null
  }

  const isConsejoMember = ConsejoMembers.includes(role)

  const { persona } = data.me.organizacion.espacio

  return (
    <Wrapper>
      <DetalleHeader
        nombreCompleto={persona.nombre_completo}
        isConsejoMember={isConsejoMember}
        role={role}
      />
      <DatosPersonales persona={persona} />
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        items={getTabsItems(
          role,
          spaceName,
          personaId,
          persona.isCongresista,
          handlerSetTab,
          activeTab
        )}
        onChange={(activeKey) => setActiveTab(activeKey)}
      />
    </Wrapper>
  )
}

const Wrapper = styled(Content)`
  display: flex;
  flex-direction: column;
  gap: ${spaces.m};
`
