import { Empty, Table } from 'antd'
import styled from 'styled-components'

import ColoredRectangle from 'components/ColorBorderRectagle'
import useConfig from 'config/configAdapter/useConfig'
import { capitalizeFirstLetterEachWord } from 'utils'
import FueraDePacto from './FueraDePacto'

const { Column } = Table

function contarVotosPorPacto(votos) {
  return votos.reduce((contador, voto) => {
    const votosCount = { ...contador }
    votosCount[voto.pacto] = {
      total: (votosCount[voto.pacto]?.total || 0) + 1,
      votoMasRepetido: (() => {
        const votoActual = voto.voto
        const votoMasRepetidoActual = votosCount[voto.pacto]?.votoMasRepetido
        if (
          !votoMasRepetidoActual ||
          (votosCount[voto.pacto]?.votos[votoActual] || 0) >
            (votosCount[voto.pacto]?.votos[votoMasRepetidoActual] || 0)
        ) {
          return votoActual
        }
        return votoMasRepetidoActual
      })(),
      votos: {
        ...votosCount[voto.pacto]?.votos,
        [voto.voto]: (votosCount[voto.pacto]?.votos?.[voto.voto] || 0) + 1,
      },
    }

    return votosCount
  }, {})
}

function DetalleVotacionTable({ votos }) {
  const { avatarColors } = useConfig()
  const votosPorPacto = contarVotosPorPacto(votos)

  return votos ? (
    <Table
      dataSource={votos}
      pagination={false}
      scroll={{ x: true }}
    >
      <Column
        key="Fuera de Pacto"
        title="Fuera de Pacto"
        width={140}
        render={(_text, record) => (
          <FueraDePacto
            actualVote={record}
            votosPorPacto={votosPorPacto}
          />
        )}
      />
      <Column
        key="Consejeros"
        title="Consejeros"
        render={({ elector }) => <Cell>{elector}</Cell>}
      />
      <Column
        key="Voto"
        title="Voto"
        render={({ voto }) => (
          <Cell>{capitalizeFirstLetterEachWord(voto === 'ABSTENCION' ? 'Abstención' : voto)}</Cell>
        )}
      />
      <Column
        key="Pacto"
        title="Pacto"
        render={({ pacto }) => (
          <ColoredRectangle
            color={avatarColors[pacto]}
            text={pacto}
          />
        )}
      />
    </Table>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )
}

export default DetalleVotacionTable

const Cell = styled.div`
  font-size: 13px;
  min-width: 100px;
`
