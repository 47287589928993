import styled from 'styled-components'

export default function ActivityBar({ activity }) {
  const total = activity.reduce((accumulator, current) => current.value + accumulator, 0)
  const scale = 150 / total
  return (
    <StyledActivityBar>
      <StyledDivider />
      {activity.map((item) => (
        <ActivitySlide
          color={item.color}
          $percentage={`${item.value * scale}px`}
        />
      ))}
      <StyledDivider />
    </StyledActivityBar>
  )
}

const ActivitySlide = styled.div`
  height: 11px;
  width: ${({ $percentage }) => $percentage || '130px'};
  background-color: ${({ color }) => color || 'black'};
`
const StyledActivityBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledDivider = styled.div`
  background-color: #737373;
  height: 16px;
  width: 1px;
`
