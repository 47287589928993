import { useAutoAnimate } from '@formkit/auto-animate/react'
import styled from 'styled-components'

import { formatLongOnlyDate } from 'format'
import InsideRows from './utilityStyles'

function Date({ data }) {
  const [parent] = useAutoAnimate()
  const [firstRow] = [...data.versions]

  return (
    <Wrapper ref={parent}>
      <div>{formatLongOnlyDate(firstRow.fecha)}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${InsideRows}
`

export default Date
