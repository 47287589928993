import { gql, useQuery } from '@apollo/client'

const PARTIDOS = gql`
  query getPartidos(
    $from: Int!
    $size: Int!
    $search: String
    $sortKey: String
    $sortOrder: String
    $filters: [FilterTerms!]
  ) {
    partidos(
      pagination: {
        from: $from
        size: $size
        search: $search
        sortKey: $sortKey
        sortOrder: $sortOrder
        filters: $filters
      }
    ) {
      pageInfo {
        nextOffset
        size
        totalCount
      }
      results {
        nombre
      }
    }
  }
`

/* eslint-disable import/prefer-default-export */
export const PartidosQuery = () => {
  const partidosData = useQuery(PARTIDOS, {
    errorPolicy: 'content_type=None',
    variables: {
      filters: [],
      from: 0,
      search: '',
      size: 100,
      sortOrder: 'asc',
    },
  }).data

  const partidosArr = !partidosData
    ? []
    : partidosData.partidos.results.reduce((acc, it) => {
        acc.push({
          text: it.nombre,
          value: it.nombre,
        })
        return acc
      }, [])

  return { partidosArr }
}
