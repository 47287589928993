import { DownloadOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import styled from 'styled-components'

import OptionalOutlink from 'components/OptionalOutlink'
import { spaces } from 'Constants'

export default function Header({ downloadUrl, leftHeader, tooltipText }) {
  const download = (
    <DownloadWrapper href={downloadUrl}>
      <DonloadIcon />
      <DownloadText>Descargar propuesta</DownloadText>
    </DownloadWrapper>
  )
  return (
    <FlexHeaderContainer>
      {leftHeader || <span />}
      <Right>{tooltipText ? <Tooltip title={tooltipText}>{download} </Tooltip> : download}</Right>
    </FlexHeaderContainer>
  )
}

const FlexHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const DonloadIcon = styled(DownloadOutlined)`
  font-size: 18px;
`

const Right = styled.div`
  display: flex;
  gap: ${spaces.m};
`
const DownloadWrapper = styled(OptionalOutlink)`
  display: flex;
  gap: ${spaces.xs};
`

const DownloadText = styled.span``
