import { colors } from 'Constants'

export default {
  constitucion_1925: {
    color: colors.constitutions.constitucion_1925,
    // eslint-disable-next-line max-len
    href: 'https://obtienearchivo.bcn.cl/obtienearchivo?id=documentos/10221.1/17659/1/Constituci%C3%B3n%20pol%C3%ADtica%20de%20la%20Rep%C3%BAblica%20de%20Chile.%20promulgada%20el%2018%20de%20septiembre%20de%201925.pdf',
    label: 'Constitución 1925',
  },
  constitucion_actual: {
    color: colors.constitutions.constitucion_actual,
    href: 'https://obtienearchivo.bcn.cl/obtienearchivo?id=documentos/10221.1/60446/3/132632.pdf',
    label: 'Constitución actual',
  },
  constitucion_mb: {
    color: colors.constitutions.constitucion_mb,
    // eslint-disable-next-line max-len
    href: 'https://obtienearchivo.bcn.cl/obtienearchivo?id=documentos/10221.1/76296/1/Mensaje%20Pdta.Bachelet.pdf',
    label: 'Propuesta de Gobierno M. Bachelet',
  },
  leyes_organicas: {
    color: colors.constitutions.leyes_organicas,
    href: 'https://www.bcn.cl/leychile/consulta/listaMasSolicitadasxmat?agr=1&sub=4&tipCat=0',
    label: 'Leyes Orgánicas Constitucionales',
  },
}
