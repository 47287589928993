import { Fragment, useState } from 'react'
import { Flags } from 'react-feature-flags'
import { useNavigate, useParams } from 'react-router-dom'
import { GithubOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { Table, Tooltip, Typography } from 'antd'
import styled from 'styled-components'

import Content from 'components/Content'
import { FueraDePactoIcon } from 'components/icons'
import PactoLabel from 'components/PactoLabel'
import StyledPageHeader from 'components/PageHeader'
import { CustomSwitch, SwitchContainer } from 'components/Switch'
import { nuevosPactos, pactoDeConstituyenteViejos, viejosPactos } from 'config/staticData'
import { spaces } from 'Constants'
import { formatLongDate } from 'format'
import { htmlDecodeText } from 'utils'
import convencionales from './convencionales'
import { Dot, DotGroup as DotGroupBase } from './Dots'
import {
  pactoPorConvencional,
  recordToObject,
  rollupPorOpcion,
  rollupPorPactoYOpcion,
} from './rollupVotos'

const { Column } = Table
const { Text } = Typography

const VOTACION = gql`
  query getVotacion($space_name: String!, $id: String!) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          votacion(id: $id) {
            id
            titulo
            tema
            resultado
            patrocinadores
            fecha
            materia_original
            votos {
              opcion
              elector
            }
          }
        }
      }
    }
  }
`

const mapNewToOldQueryResult = (queryResult) => {
  if (!queryResult) return queryResult

  const oldData = {
    convencionales,
    votacion: queryResult.votacion,
  }

  return oldData
}

export default function Detalle() {
  const navigate = useNavigate()
  const { votacionId } = useParams()

  const { data: newQueryData } = useQuery(VOTACION, {
    variables: {
      id: votacionId,
      space_name: 'Convencionales',
    },
  })

  const data = mapNewToOldQueryResult(newQueryData?.me?.organizacion?.espacio)

  const [newPactSwitch, setNewPactSwitch] = useState(true)
  const pactoDeConstituyente = data && pactoPorConvencional(data.convencionales)
  const pactosConstituyente = newPactSwitch ? pactoDeConstituyente : pactoDeConstituyenteViejos
  const pactos = newPactSwitch ? nuevosPactos : viejosPactos
  const votacion = data && recordToObject(data.votacion, pactosConstituyente)
  const porOpcion = data && rollupPorOpcion(votacion.votos)
  const opcionesOrdenadas = data && [...porOpcion.keys()]
  const porPactoYOpcion = data && rollupPorPactoYOpcion(votacion.votos, opcionesOrdenadas)
  const pactosMap = new Map(pactos.map((p) => [p.key, p.longName || p.label]))

  const searchParams = new URLSearchParams(window.location.search)
  const pageNumber = searchParams.get('page')

  return !data ? null : (
    <Content>
      <Flags authorizedFlags={['votaciones2022']}>
        <StyledPageHeader
          extra={[
            votacion.githubUrl && (
              <a
                href={votacion.githubUrl}
                key="github"
                rel="noreferrer"
                target="_blank"
              >
                <GithubOutlined />
              </a>
            ),
            <SwitchContainer key="1">
              <CustomSwitch
                labelLeft="Pactos originales"
                labelRight="Pactos vigentes"
                onClickLeft={() => setNewPactSwitch(false)}
                onClickRight={() => setNewPactSwitch(true)}
              />
            </SwitchContainer>,
          ]}
          onBack={() => navigate(`/convencionales/votaciones?page=${pageNumber}`)}
          subTitle={formatLongDate(votacion.fecha)}
          title={htmlDecodeText(votacion.titulo || '')}
        />
        <Text>{htmlDecodeText(votacion.materiaOriginal || '')}</Text>
        <VotosPorPacto
          cols={pactos.length + 2}
          rows={opcionesOrdenadas.length}
        >
          <Cell />
          <Cell />

          {pactos.map(({ key, label, longName }) => (
            <ColHeader key={key}>
              <Tooltip title={longName}>{label}</Tooltip>
            </ColHeader>
          ))}

          {opcionesOrdenadas.map((opcion, idxOpcion) => (
            <Fragment key={opcion}>
              <RowHeader muted={idxOpcion !== 0}>
                <OpcionCount>{porOpcion.get(opcion).length}</OpcionCount>
              </RowHeader>
              <RowHeader muted={idxOpcion !== 0}>
                <OpcionLabel>{opcion}</OpcionLabel>
              </RowHeader>
              {pactos
                .map((pacto) => ({
                  dataPacto: porPactoYOpcion.get(pacto.key),
                  keyPacto: pacto.key,
                }))
                .map(({ dataPacto, keyPacto }) => (
                  <Cell key={keyPacto}>
                    <DotGroup
                      className={`data-category-${keyPacto}`}
                      $highlighted={opcion === dataPacto.opcionPreferida}
                      style={{ padding: '9px' }}
                    >
                      {dataPacto.porOpcion[idxOpcion].votos.map((voto) => (
                        <Dot
                          className="data-fill"
                          key={voto.elector}
                          $margin={2}
                          size={8}
                        />
                      ))}
                    </DotGroup>
                  </Cell>
                ))}
            </Fragment>
          ))}
        </VotosPorPacto>

        <Table
          dataSource={votacion.votos.map((voto) => ({
            ...voto,
            fueraDePacto: voto.opcion !== porPactoYOpcion.get(voto.pacto).opcionPreferida,
            nombrePacto: pactosMap.get(voto.pacto),
          }))}
          pagination={false}
          rowKey="elector"
          size="small"
          scroll={{ x: true }}
        >
          <Column
            align="center"
            render={(value, record) => record.fueraDePacto && <FueraDePactoIcon size={24} />}
            sorter={(a, b) => a.fueraDePacto - b.fueraDePacto}
            title="Fuera de Pacto"
            width={100}
          />
          <Column
            dataIndex="elector"
            defaultSortOrder="ascend"
            sorter={(a, b) => (a.elector < b.elector ? -1 : 1)}
            title="Convencional"
          />
          <Column
            dataIndex="opcion"
            filters={[...porOpcion.keys()].map((opcion) => ({
              text: opcion,
              value: opcion,
            }))}
            onFilter={(value, record) => record.opcion === value}
            title="Voto"
          />
          <Column
            dataIndex="pacto"
            filters={pactos.map(({ key, label, longName }) => ({
              text: longName || label,
              value: key,
            }))}
            onFilter={(value, record) => record.pacto === value}
            render={(_, record) => <PactoLabel>{record.nombrePacto}</PactoLabel>}
            title="Pacto"
          />
        </Table>
      </Flags>
    </Content>
  )
}

const VotosPorPacto = styled.div`
  display: grid;
  grid: 1fr / auto ${(p) => `repeat(${p.cols - 1}, 1fr)`};
  width: 100%;
  overflow-x: auto;
  @media (max-width: ${spaces.mobileBreakpoint}) {
    box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
  }
`

const Cell = styled.div`
  display: flex;
  padding: 3px;
  border-bottom: 1px solid #eee;
`

const HeaderCell = styled(Cell)`
  line-height: 1em;
  font-size: 1.4em;
  font-weight: bold;
`

const ColHeader = styled(HeaderCell)`
  align-items: end;
  justify-content: center;
  padding-bottom: 4px;
  font-size: 1em;
  text-align: center;
`

const RowHeader = styled(HeaderCell)`
  align-items: center;
  text-align: right;

  font-weight: ${(p) => (p.muted ? 'normal' : '700')};
  font-size: ${(p) => (p.muted ? '12px' : '14px')};
`

const OpcionCount = styled.div`
  margin-left: auto;
  margin-right: 0px;
  width: 3em;
`

const OpcionLabel = styled.div`
  padding-right: 2em;
`

const DotGroup = styled(DotGroupBase)`
  min-width: 80px;
`
