import { Table } from 'antd'
import styled from 'styled-components'

import { colors } from 'Constants'

const StyledTable = styled(Table)`
  && {
    th.ant-table-column-sort {
      background-color: ${colors.winterWhite} !important;
      &::before {
        background-color: #f0f0f0 !important;
      }
      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        color: ${(props) => props.sorterColor} !important;
      }
    }
    tr:not(:hover) {
      td.ant-table-column-sort {
        background-color: revert !important;
      }
    }
  }

  .ant-table-tbody .align-top-cell {
    vertical-align: top !important;
  }
`

export default StyledTable
