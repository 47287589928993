import Icon from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import styled from 'styled-components'

import { UserIcon } from 'assets'
import useConfig from 'config/configAdapter/useConfig'

const USER = gql`
  query getCurrentUser {
    me {
      nombreCompleto
      id
    }
  }
`

export default function Profile() {
  const { data } = useQuery(USER)
  const { anonymousMode = {} } = useConfig()
  const user = data?.me?.nombreCompleto || 'Perfil'

  if (anonymousMode?.active) {
    return null
  }

  return (
    <Container>
      <StyledIcon component={UserIcon} />
      <Title>{user}</Title>
    </Container>
  )
}

const Title = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: grey;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 6px;
  margin: 0 3px;
`

const StyledIcon = styled(Icon)`
  color: grey;
  font-size: 16px; /* Tamaño del icono */
`
