import { Divider } from 'antd'
import styled from 'styled-components'

import { singulares, unidadTerritorial } from 'Constants'
import { capitalizeFirstLetter } from 'utils'

function UnidadInfo({ content, title }) {
  return (
    content && (
      <StatisticContainer>
        <div className="data-title">{title}:</div>
        <div className="data-content">{content}</div>
      </StatisticContainer>
    )
  )
}

export default function InfoPresidente({
  edad,
  nombre,
  pacto,
  partido,
  profesion,
  region,
  rol,
  territorio,
}) {
  return (
    <StyledPresidentData>
      <StyledColumn className="presidente-general-data col1">
        <UnidadInfo
          title="Presidente"
          content={nombre}
        />
        <UnidadInfo
          title="Edad"
          content={edad}
        />
        <UnidadInfo
          title="Profesion"
          content={profesion}
        />
      </StyledColumn>
      {nombre && (
        <Divider
          type="vertical"
          style={{ height: '100%' }}
        />
      )}
      <StyledColumn className="presidente-general-data col2">
        <UnidadInfo
          title="Pacto"
          content={pacto}
        />
        <UnidadInfo
          title="Partido"
          content={partido}
        />
        <UnidadInfo
          title={capitalizeFirstLetter(unidadTerritorial[singulares[rol]])}
          content={territorio}
        />
        <UnidadInfo
          title="Región"
          content={region}
        />
      </StyledColumn>
    </StyledPresidentData>
  )
}

const StatisticContainer = styled.div`
  display: flex;
  .data-title {
    font-weight: 700;
    color: #000000;
    font-size: 14px;
    line-height: 22px;
    margin-right: 5px;
  }
  .data-content {
    font-size: 14px;
    line-height: 22px;
  }
`

const StyledPresidentData = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  &.presidente-general-data {
    margin-top: 30px;
    max-width: 340px;
  }
`
