import { useEffect, useState } from 'react'
import { WarningOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export default function FueraDePacto({ actualVote, votosPorPacto }) {
  const [isOut, setIsOut] = useState(false)
  const { pacto, voto } = actualVote
  const { total, votoMasRepetido, votos } = votosPorPacto[pacto]

  useEffect(() => {
    if (
      voto.toLowerCase() !== votoMasRepetido.toLowerCase() &&
      votos[voto] < Math.ceil(total / 2)
    ) {
      setIsOut(true)
    }
  }, [voto, votoMasRepetido])

  return <FueraDePactoHeader>{isOut && <FueraPactoIcon />}</FueraDePactoHeader>
}

const FueraDePactoHeader = styled.div`
  min-width: 50px;
`

const FueraPactoIcon = styled(WarningOutlined)`
  color: #f2994a;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`
