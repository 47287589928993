import { colors } from 'Constants'
import _ from 'lodash'

import { AdaptedConfig, Config } from './config.type'
import DEFAULT_CONFIG from './DEFAULT_CONFIG'

const addExtraConfig = (config: Config): AdaptedConfig => ({
  ...config,
  primaryColor: config.antdStyles.styles.token?.colorPrimary || (colors.scannerBlue as string),
})

const configAdapter = (remoteConfig: Config): AdaptedConfig | Config => {
  const config = remoteConfig || DEFAULT_CONFIG
  const adaptedConfig: Config = _.merge(DEFAULT_CONFIG, config)
  return addExtraConfig(adaptedConfig)
}

export default configAdapter
