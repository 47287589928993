import styled from 'styled-components'

import { spaces } from 'Constants'
import Legend from './Legend'

export default function Legends({ className, legends }) {
  return (
    <Wrapper className={className}>
      {Object.values(legends).map(({ color, href, label }) => (
        <Legend
          color={color}
          key={label}
        >
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            {label}
          </a>
        </Legend>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: ${spaces.m};
  padding: ${spaces.m} 0;
  flex-wrap: wrap;
  @media (max-width: ${spaces.mobileBreakpoint}) {
    gap: ${spaces.s};
  }
`
