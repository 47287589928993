import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import styled from 'styled-components'

import StyledTitle from 'components/SpaceTitle'
import LoadingSpinner from 'components/StyledSpinner'
import StyledTable from 'components/StyledTable'
import useConfig from 'config/configAdapter/useConfig'
import { formatLongOnlyDateWithZero } from 'format'
import { capitalizeFirstLetterEachWord } from 'utils'
import usePagination from 'views/AuthenticatedSite/Pagination'

const { Column } = StyledTable

const SESIONES = gql`
  query getSesiones(
    $space_name: String!
    $from: Int!
    $size: Int!
    $sortKey: String
    $sortOrder: String
    $phrase: String!
    $filters: [FilterInput!]
  ) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          sesiones(
            search: { phrase: $phrase, filters: $filters }
            pagination: { from: $from, size: $size, sortKey: $sortKey, sortOrder: $sortOrder }
          ) {
            pageInfo {
              totalCount
              nextOffset
              size
            }
            results {
              id
              titulo
              origen
              fecha
              sede
              organizacion
              tema
              solicitante
            }
          }
        }
      }
    }
  }
`

export default function RegionTable({ defaultFilters = [], space }) {
  const { canViewColumn, primaryColor, regiones } = useConfig()
  const [phrase] = useState('')
  const [filtros, setFiltros] = useState([])
  const { nroRegion } = useParams()
  const { fecha, organizacion, sede, tema, titulo } = canViewColumn || {}

  const {
    getPaginationParams,
    pagination,
    setFilters,
    setOrder,
    setPageNumber,
    setSort,
    updatePaginationInfo,
  } = usePagination({
    defaultSortKey: 'fecha',
    defaultSortOrder: 'descend',
  })

  const { data, loading, refetch } = useQuery(SESIONES, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ me }) => {
      updatePaginationInfo(me?.organizacion?.espacio?.sesiones?.pageInfo)
      if (defaultFilters) {
        setFiltros(defaultFilters)
      }
    },
    variables: {
      ...getPaginationParams(),
      filters: [...filtros, ...defaultFilters],
      phrase,
      space_name: space,
    },
  })

  useEffect(() => {
    refetch({
      ...getPaginationParams(),
      filters: [...filtros, ...defaultFilters],
      phrase,
      space_name: space,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.sort,
    pagination.order,
    pagination.search,
    pagination.pageSize,
    pagination.pageNumber,
    nroRegion,
    defaultFilters,
  ])

  if (loading) {
    return <LoadingSpinner />
  }

  const sesiones = data?.me?.organizacion?.espacio?.sesiones?.results || []

  const onTableChange = (_pagination, filters, sorter) => {
    if (filters) {
      const fields = Object.keys(filters)
      const filterTerms = []
      fields.forEach((field) => {
        if (filters[field]) {
          filters[field].forEach((term) => {
            filterTerms.push({ field, term })
          })
        }
      })
      setFilters(filterTerms)
    }
    if (sorter) {
      setSort(sorter.columnKey || '_score')
      setOrder(sorter.order)
    }
  }

  const region = regiones?.filter((item) => item.id === nroRegion)[0]
  return (
    <TableWrapper>
      {titulo.includes('regiones') && <StyledTitle sectionName={region.titulo} />}
      <StyledTable
        onChange={onTableChange}
        sortDirections={['descend', 'ascend', 'descend']}
        dataSource={sesiones}
        pagination={{
          current: pagination.pageNumber,
          onChange: setPageNumber,
          pageSize: pagination.pageSize,
          showSizeChanger: false,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
          total: pagination.totalItems,
        }}
        rowKey="id"
        sorterColor={primaryColor}
      >
        {fecha.includes('regiones') && (
          <Column
            key="fecha"
            sorter
            sortOrder={pagination.order || 'descend'}
            align="left"
            width={125}
            render={({ fecha: fechaAudiencia }) => (
              <StyledContent>{formatLongOnlyDateWithZero(fechaAudiencia)}</StyledContent>
            )}
            title={<Header>Fecha</Header>}
          />
        )}
        {sede.includes('regiones') && (
          <Column
            key="sede"
            width={250}
            title={<Header>Sede</Header>}
            render={({ sede: sedeAudiencia }) => (
              <StyledContent>{sedeAudiencia || 'No identificada'}</StyledContent>
            )}
          />
        )}
        {organizacion.includes('regiones') && (
          <Column
            key="organizacion"
            width={250}
            title={<Header>Organización</Header>}
            render={({ organizacion: orgAudiencia }) => (
              <StyledContent>
                {capitalizeFirstLetterEachWord(orgAudiencia || 'No identificada')}
              </StyledContent>
            )}
          />
        )}
        {tema.includes('regiones') && (
          <Column
            key="solicitante"
            width={250}
            title={<Header>Nombre solicitante</Header>}
            render={({ solicitante }) => (
              <StyledContent>{solicitante || 'No identificado'}</StyledContent>
            )}
          />
        )}
        {tema.includes('regiones') && (
          <Column
            key="tema"
            title={<Header>Tema</Header>}
            render={({ id, tema: temaAudiencia }) => (
              <StyledContent>
                <div>
                  <Link to={id}>
                    <StyledSessionTitle>{temaAudiencia}</StyledSessionTitle>
                  </Link>
                </div>
              </StyledContent>
            )}
          />
        )}
      </StyledTable>
    </TableWrapper>
  )
}

const StyledContent = styled.div`
  letter-spacing: -0.328125px;

  .link {
    font-weight: bold;
  }
`

const TableWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-table-wrapper {
    height: 100%;
  }
  .ant-spin-nested-loading {
    height: 100%;
  }
  .ant-spin-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`
const Header = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
`
const StyledSessionTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.33px;
  color: black;
  text-decoration: underline;
`
