import { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Table } from 'antd'
import dayjs from 'dayjs'
import { styled } from 'styled-components'

import FilterSearch from 'components/FilterSearch'
import LoadingSpinner from 'components/StyledSpinner'
import StyledTable from 'components/StyledTable'
import useCapitulo from 'query/useCapitulo'
import useInstancia from 'query/useInstancia'
import useEstado from 'query/useState'
import usePagination from 'views/AuthenticatedSite/Pagination'
import RangeDatePicker from '../RangeDatePicker'
import ChapterRender from './Columns/Chapter'
import DateRender from './Columns/Date'
import InstanceRender from './Columns/Instance'
import NameRender from './Columns/Name'
import ResultsRender from './Columns/Results'
import StateRender from './Columns/State'
import ResultadosHeader from './ResultadosHeader'

const { Column } = StyledTable

const CONSEJO_SPACE_NAME = 'Consejo Constitucional'

const VOTACION = gql`
  query getVotaciones2023(
    $space_name: String!
    $from: Int!
    $size: Int!
    $sortKey: String
    $sortOrder: String
    $phrase: String!
    $filters: [FilterInput!]
    $specialFilters: String
  ) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          votaciones2023(
            search: { phrase: $phrase, filters: $filters }
            pagination: {
              from: $from
              size: $size
              sortKey: $sortKey
              sortOrder: $sortOrder
              specialFilters: $specialFilters
            }
          ) {
            pageInfo {
              nextOffset
              size
              totalCount
            }
            results {
              id
              descripcion
              comision
              articulo
              capitulo
              versions {
                fecha
                state
                version
                results {
                  apruebos
                  rechazos
                  abstenciones
                  noVota
                }
                votos {
                  voto
                  elector
                  pacto
                  elector_id
                }
              }
            }
          }
        }
      }
    }
  }
`

const getFiltersSummary = (filters) => {
  const summaryEntries = filters.map((filter) => [filter.field, filter.conj || filter.terms])
  return Object.fromEntries(summaryEntries)
}

const updateFilters = (filtersDict, setFilters, excludeFields = []) => {
  const filtersList = Object.keys(filtersDict)
    .filter((field) => !excludeFields.includes(field))
    .map((field) => ({
      field,
      terms: filtersDict[field],
    }))

  setFilters(filtersList)
}

function Articulos() {
  const [filtros, setFiltros] = useState([])
  const [specialFilters, setSpecialFilters] = useState()
  const [dateRange, setDateRange] = useState([])
  const [expandArray, setExpandArray] = useState(false)

  const {
    getPaginationParams,
    pagination,
    setFilters,
    setOrder,
    setPageNumber,
    setSort,
    updatePaginationInfo,
  } = usePagination({
    defaultSortKey: 'fecha',
    defaultSortOrder: 'descend',
  })

  const [currentFilters, setCurrentFilters] = useState(getFiltersSummary(pagination.filters))

  const { estadosArr } = useEstado({
    space_name: CONSEJO_SPACE_NAME,
  })

  const { capitulosArr } = useCapitulo({
    space_name: CONSEJO_SPACE_NAME,
  })

  const { instanciasArr } = useInstancia({
    space_name: CONSEJO_SPACE_NAME,
    tipo: 'consejero',
  })

  const { data, loading, refetch } = useQuery(VOTACION, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ me }) => {
      updatePaginationInfo(me?.organizacion?.espacio?.votaciones2023?.pageInfo)
    },
    variables: {
      ...getPaginationParams(),
      filters: filtros,
      phrase: '',
      space_name: CONSEJO_SPACE_NAME,
      specialFilters,
    },
  })

  useEffect(() => {
    refetch({
      ...getPaginationParams(),
      filters: filtros,
      phrase: '',
      space_name: CONSEJO_SPACE_NAME,
      specialFilters,
    })
    setCurrentFilters(getFiltersSummary(filtros))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtros,
    specialFilters,
    pagination.sort,
    pagination.order,
    pagination.search,
    pagination.pageSize,
    pagination.pageNumber,
  ])

  const handleExpand = (index) => {
    const newExpandArray = [...expandArray]
    newExpandArray[index] = !newExpandArray[index]
    setExpandArray(newExpandArray)
  }

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setDateRange([dayjs(dateStrings[0], 'DD-MM-YYYY'), dayjs(dateStrings[1], 'DD-MM-YYYY')])
    } else {
      setDateRange([])
    }
  }

  const onTableChange = (_pagination, filters, sorter) => {
    if (filters) {
      const fields = Object.keys(filters)
      const filterTerms = []
      fields.forEach((field) => {
        if (filters[field]) {
          filters[field].forEach((term) => {
            filterTerms.push({ field, term })
          })
        }
      })
      setFilters(filterTerms)
    }
    if (sorter) {
      setSort(sorter.columnKey || '_score')
      setOrder(sorter.order)
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  const votaciones = data?.me?.organizacion?.espacio?.votaciones2023?.results || []

  return (
    <Wrapper
      onRow={(record, rowindex) => ({ rowindex })}
      onChange={onTableChange}
      rowKey="id"
      scroll={{ x: true }}
      tableLayout="fixed"
      dataSource={votaciones}
      pagination={{
        current: pagination.pageNumber,
        filters: filtros,
        onChange: setPageNumber,
        pageSize: pagination.pageSize,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total}`,
        total: pagination.totalItems,
      }}
      showHeader
    >
      <Column
        width={120}
        sorter
        key="fecha"
        filterDropdown={
          <RangeDatePicker
            onChange={onRangeChange}
            value={dateRange}
            onClear={() => {
              setDateRange([])
              setSpecialFilters()
              setPageNumber(1)
            }}
            onFilter={() => {
              const dateFilter = {
                range: {
                  fecha: {
                    gte: dayjs(dateRange[0]).format('YYYY-MM-DD'),
                    lte: dayjs(dateRange[1].endOf('day')).format('YYYY-MM-DD'),
                  },
                },
              }
              const filtersString = JSON.stringify(dateFilter)
              if (dateRange.length > 0) {
                setSpecialFilters(filtersString)
              }
            }}
          />
        }
        render={(rowData, _, index) => (
          <DateRender
            data={rowData}
            index={index}
            align="right"
            handleExpand={handleExpand}
            $isExpanded={expandArray[index]}
          />
        )}
        title="Fecha"
      />
      <Column
        key="articulos"
        width={250}
        render={(rowData, _, index) => (
          <NameRender
            data={rowData}
            index={index}
            handleExpand={handleExpand}
            $isExpanded={expandArray[index]}
          />
        )}
        title="Nombre"
      />
      <Column
        width={250}
        key="chapter"
        render={(rowData, _, index) => (
          <ChapterRender
            data={rowData}
            index={index}
            handleExpand={handleExpand}
            $isExpanded={expandArray[index]}
          />
        )}
        filterDropdown={
          <FilterSearch
            optionsArr={capitulosArr}
            currentFiltersDict={currentFilters}
            field="capitulo.keyword"
            setQueryFilters={setFiltros}
            updateFilters={updateFilters}
            width="300px"
          />
        }
        title="Capítulo"
      />
      <Column
        width={250}
        key="instance"
        render={(rowData, _, index) => (
          <InstanceRender
            data={rowData}
            index={index}
            handleExpand={handleExpand}
            $isExpanded={expandArray[index]}
          />
        )}
        filterDropdown={
          <FilterSearch
            optionsArr={instanciasArr}
            currentFiltersDict={currentFilters}
            field="comision.keyword"
            setQueryFilters={setFiltros}
            updateFilters={updateFilters}
            width="300px"
          />
        }
        title="Comisión/Pleno"
      />
      <Column
        width={200}
        key="state"
        render={(rowData, _, index) => (
          <StateRender
            data={rowData}
            index={index}
            handleExpand={handleExpand}
            $isExpanded={expandArray[index]}
          />
        )}
        filterDropdown={
          <FilterSearch
            optionsArr={estadosArr}
            currentFiltersDict={currentFilters}
            field="estado.keyword"
            setQueryFilters={setFiltros}
            updateFilters={updateFilters}
            width="300px"
          />
        }
        title="Estado"
      />
      <Column
        width={250}
        key="results"
        render={(rowData, _, index) => (
          <ResultsRender
            data={rowData}
            index={index}
            handleExpand={handleExpand}
            $isExpanded={expandArray[index]}
          />
        )}
        title={<ResultadosHeader />}
      />
    </Wrapper>
  )
}

export default Articulos

const Wrapper = styled(Table)`
  .ant-table-wrapper .ant-table {
    min-width: 1000px;
  }
`
