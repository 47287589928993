import styled from 'styled-components'

import CircleGrid from 'components/CircleGrid'
import Circle from 'components/VoteCircle'

export default function VotoContainer({ $color, $colorBackground, $count, $maxNRows }) {
  return (
    <VotoWarp
      $colorBackground={$colorBackground}
      $color={$color}
    >
      {$count && (
        <>
          <VotoCount>{$count}</VotoCount>
          <CircleGrid $nRows={$maxNRows}>
            {Array.from({ length: $count }).map((_) => (
              <Circle color={$color} />
            ))}
          </CircleGrid>
        </>
      )}
      <br />
    </VotoWarp>
  )
}

const VotoCount = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  padding-right: 24px;
`

const VotoWarp = styled.div`
  display: flex;
  min-width: 150px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: ${({ $color, $colorBackground }) =>
    $colorBackground ? `${$color}0D` : 'none'};
`
