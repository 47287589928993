import { useAutoAnimate } from '@formkit/auto-animate/react'
import styled from 'styled-components'

import { colors } from 'Constants'
import ProgressBar from '../../ProgressBar'
import InsideRows from './utilityStyles'

function Results({ data }) {
  const [parent] = useAutoAnimate()
  const [firstRow] = [...data.versions]

  return (
    <>
      <DashedLine $breakpoint={1 / 2} />

      <Wrapper ref={parent}>
        <ProgressBar
          results={firstRow.results}
          state={firstRow.state}
        />
      </Wrapper>
    </>
  )
}
const Wrapper = styled.div`
  ${InsideRows}
`

const DashedLine = styled.div`
  height: 100%;
  width: 1px;
  border-left: 1px dashed ${colors.selectedNav};
  position: absolute;
  ${({ $breakpoint }) => $breakpoint && `left: ${$breakpoint * 100}%;`}
  z-index: 1;
  top: 0;
`

export default Results
