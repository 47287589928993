import useConfig from 'config/configAdapter/useConfig'
import { colors } from 'Constants'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

export default function PoliticaPrivacidad() {
  const { auth0Domain, primaryColor, privacyPolicyUrl } = useConfig()
  const [searchParams] = useSearchParams()
  const [isAccepted, setIsAccepted] = useState(false)
  const state = searchParams.get('state')
  const continueUrl = `${auth0Domain}/continue?state=${state}`

  return (
    <Wrapper>
      <Card>
        <LogoScanner
          src="/logo/logoScannerCNN.svg"
          alt="logo"
        />
        <Title>Bienvenido</Title>
        <Form
          action={continueUrl}
          method="post"
        >
          <Label>
            <input
              type="checkbox"
              name="confirm"
              value="yes"
              onChange={(value) => setIsAccepted(value.target.checked)}
            />
            <span>
              Autorizo a<b> ScannerCNN </b>
              para registrar mi correo electrónico.
              <br />
              <br />
              <p>
                Los datos personales serán tratados de acuerdo a la{' '}
                <LinkPolitica
                  href={privacyPolicyUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>Política de Privacidad</b>
                </LinkPolitica>
                .
              </p>
            </span>
          </Label>
          <Continuar
            isAccepted={isAccepted}
            primaryColor={primaryColor}
            type="submit"
          >
            Continuar
          </Continuar>
        </Form>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('images/background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
`

const Card = styled.section`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: white;
  border-radius: 5px;
  gap: 24px;
`

const LogoScanner = styled.img`
  max-height: 52px;
`

const Title = styled.h1`
  font-weight: 400;
  font-size: 24px;
  text-align: center;
`

const Label = styled.label`
  font-size: 14px;
  display: flex;
  align-items: start;
  gap: 6px;

  span {
    margin-top: -2px;
  }
`

const Continuar = styled.button<{ isAccepted: boolean; primaryColor: string }>`
  background-color: ${({ isAccepted, primaryColor }) =>
    isAccepted ? primaryColor : colors.backgroundLightGrey};
  width: 100%;
  min-height: 52px;
  font-size: 16px;
  color: white;
  border-radius: 3px;
  border: none;
  pointer-events: ${({ isAccepted }) => (isAccepted ? 'auto' : 'none')};
  cursor: ${({ isAccepted }) => (isAccepted ? 'pointer' : 'not-allowed')};
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const LinkPolitica = styled.a`
  color: black;
`
