import React from 'react'
import { Flags } from 'react-feature-flags'

import ComisionList from 'components/Comisiones/ComisionList'

function ComisionSenadoresList() {
  return (
    <Flags
      exactFlags
      authorizedFlags={['senadores', 'comisiones']}
    >
      <ComisionList
        space="Senadores"
        tipo="Senado"
      />
    </Flags>
  )
}

export default ComisionSenadoresList
