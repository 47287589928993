import styled from 'styled-components'

import { colors } from 'Constants'

export default function ResultadosHeader() {
  return (
    <MainWrapper>
      <ResultsTitle>Resultado</ResultsTitle>
      <ResultsLegend>
        <LegendWrapper>
          <Rectangle color={colors.votacion.rechazada} />
          <div>En contra</div>
        </LegendWrapper>
        <LegendWrapper>
          <Rectangle color={colors.votacion.aprobada} />
          <div>A favor</div>
        </LegendWrapper>
      </ResultsLegend>
    </MainWrapper>
  )
}

const ResultsLegend = styled.div`
  display: flex;
  font-size: 10px;
  font-weight: 400;
  justify-content: space-around;
`

const ResultsTitle = styled.div`
  text-align: center;
`

const Rectangle = styled.div`
  width: 9px;
  height: 9px;
  background-color: ${({ color }) => color};
`

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
