import { Button as ButtonBase } from 'antd'
import styled from 'styled-components'

import SpaceTitle from 'components/SpaceTitle'
import { colors, spaces } from 'Constants'
import useBreakpoint from 'query/useBreakpoint'
import Legends from './Legends'

export default function Header({ legend, showDrawer, title = '' }) {
  const { isMobile } = useBreakpoint()
  return (
    <Wrapper>
      <SpaceTitle sectionName={title} />
      <Legends legends={legend} />
      <div>
        <Button
          type="primary"
          onClick={showDrawer}
          isMobile={isMobile}
          size={isMobile && 'small'}
        >
          Ver índice
        </Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid: min-content / 1fr;
  align-items: center;
  border-bottom: 0.1px solid ${colors.backgroundLightGrey};
  padding: ${spaces.l} ${spaces.l} ${spaces.m} ${spaces.l};
  @media (max-width: ${spaces.mobileBreakpoint}) {
    padding: ${spaces.m} ${spaces.m} ${spaces.s} ${spaces.m};
  }
`

const Button = styled(ButtonBase)`
  ${({ isMobile }) => isMobile && 'font-weight: 300;'}
  ${({ isMobile }) => isMobile && 'font-size: 11px !important;'}
  ${({ isMobile }) => isMobile && 'max-width: 90px;'}
  box-shadow: none;
`
