export const pactoDeConstituyenteViejos = {
  'Abarca, Damaris': 'aprueboDignididad',
  'Abarca, Jorge': 'apruebo',
  'Achurra, Ignacio': 'aprueboDignididad',
  'Aguilera, Tiare': 'pueblosIndigenas',
  'Alvarado, Gloria': 'otros',
  'Alvarez, Julio': 'apruebo',
  'Alvarez, Rodrigo': 'vamosPorChile',
  'Alvez, Amaya': 'aprueboDignididad',
  'Ampuero, Adriana': 'delPueblo',
  'Andrade, Cristóbal': 'delPueblo',
  'Antilef, Victorino': 'pueblosIndigenas',
  'Arancibia, Jorge': 'vamosPorChile',
  'Arauna, Francisca': 'delPueblo',
  'Arellano, Marco': 'delPueblo',
  'Arrau, Martín': 'vamosPorChile',
  'Atria, Fernando': 'aprueboDignididad',
  'Bacian, Wilfredo': 'pueblosIndigenas',
  'Baradit, Jorge': 'apruebo',
  'Baranda, Benito': 'nuevaConstitucion',
  'Barceló, Luis': 'apruebo',
  'Barraza, Marcos': 'aprueboDignididad',
  'Bassa, Jaime': 'aprueboDignididad',
  'Botto, Miguel Ángel': 'nuevaConstitucion',
  'Bown, Carol': 'vamosPorChile',
  'Bravo, Daniel': 'delPueblo',
  'Caamaño, Francisco': 'delPueblo',
  'Caiguan, Alexis': 'pueblosIndigenas',
  'Calvo, Carlos': 'apruebo',
  'Cancino, Adriana': 'apruebo',
  'Cantuarias, Rocío': 'vamosPorChile',
  'Carrillo, Alondra': 'otros',
  'Castillo, Eduardo': 'apruebo',
  'Castillo, María Trinidad': 'otros',
  'Castro, Claudia': 'vamosPorChile',
  'Catrileo, Rosa': 'pueblosIndigenas',
  'Celedón, Roberto': 'aprueboDignididad',
  'Celis, Raúl': 'vamosPorChile',
  'Chahín, Fuad': 'apruebo',
  'Chinga, Eric': 'pueblosIndigenas',
  'Cozzi, Ruggero': 'vamosPorChile',
  'Cretton, Eduardo': 'vamosPorChile',
  'Cruz, Andrés': 'apruebo',
  'Cubillos, Marcela': 'vamosPorChile',
  'Céspedes, Lorena': 'nuevaConstitucion',
  'Daza, Mauricio': 'otros',
  'De la Maza, Bernardo': 'vamosPorChile',
  'Delgado, Aurora': 'aprueboDignididad',
  'Domínguez, Gaspar': 'nuevaConstitucion',
  'Dorador, Cristina': 'otros',
  'Fernández, Patricio': 'apruebo',
  'Flores, Alejandra': 'otros',
  'Fontaine, Bernardo': 'vamosPorChile',
  'Fuchslocher, Javier': 'nuevaConstitucion',
  'Gallardo, Bessy': 'apruebo',
  'Galleguillos, Félix': 'pueblosIndigenas',
  'Garín, Renato': 'apruebo',
  'Giustinianovich, Elisa': 'delPueblo',
  'Godoy, Isabel': 'pueblosIndigenas',
  'González, Dayyana': 'delPueblo',
  'González, Lidia': 'pueblosIndigenas',
  'Grandón, Giovanna': 'delPueblo',
  'Grandón, Paola': 'aprueboDignididad',
  'Gutiérrez, Hugo': 'aprueboDignididad',
  'Gómez, Claudio': 'apruebo',
  'Gómez, Yarela': 'aprueboDignididad',
  'Harboe, Felipe': 'apruebo',
  'Henríquez, Natalia': 'delPueblo',
  'Hoppe, Vanessa': 'aprueboDignididad',
  'Hube, Constanza': 'vamosPorChile',
  'Hurtado, Maximiliano': 'apruebo',
  'Hurtado, Ruth': 'vamosPorChile',
  'Jiménez, Luis': 'pueblosIndigenas',
  'Jofré, Alvaro': 'vamosPorChile',
  'Jurgensen, Harry': 'vamosPorChile',
  'Labbé, Bastián': 'otros',
  'Labra, Patricia': 'vamosPorChile',
  'Labraña, Elsa': 'delPueblo',
  'Laibe, Tomás': 'apruebo',
  'Larraín, Hernán': 'vamosPorChile',
  'Letelier, Margarita': 'vamosPorChile',
  'Linconao, Francisca': 'pueblosIndigenas',
  'Llanquileo, Natividad': 'pueblosIndigenas',
  'Logan, Rodrigo': 'otros',
  'Loncon, Elisa': 'pueblosIndigenas',
  'Madriaga, Tania': 'delPueblo',
  'Mamani, Isabella': 'pueblosIndigenas',
  'Marinovic, Teresa': 'vamosPorChile',
  'Martin, Juan José': 'nuevaConstitucion',
  'Martínez, Helmuth': 'delPueblo',
  'Mayol, Luis': 'vamosPorChile',
  'Mella, Jeniffer': 'aprueboDignididad',
  'Mena, Felipe': 'vamosPorChile',
  'Meneses, Janis': 'otros',
  'Millabur, Adolfo': 'pueblosIndigenas',
  'Miranda, Valentina': 'aprueboDignididad',
  'Monckeberg, Cristian': 'vamosPorChile',
  'Montealegre, Katerine': 'vamosPorChile',
  'Montero, Ricardo': 'apruebo',
  'Moreno, Alfredo': 'vamosPorChile',
  'Muñoz, Pedro': 'apruebo',
  'Namor, Guillermo': 'nuevaConstitucion',
  'Navarrete, Geoconda': 'vamosPorChile',
  'Neumann, Ricardo': 'vamosPorChile',
  'Núñez, Nicolás': 'aprueboDignididad',
  'Olivares, Ivanna': 'delPueblo',
  'Orellana, Matías': 'apruebo',
  'Ossandón, Manuel': 'vamosPorChile',
  'Oyarzún, María José': 'aprueboDignididad',
  'Pinto, Malucha': 'apruebo',
  'Politzer, Patricia': 'nuevaConstitucion',
  'Portilla, Ericka': 'aprueboDignididad',
  'Pustilnick, Tammy': 'nuevaConstitucion',
  'Pérez, Alejandra': 'delPueblo',
  'Quinteros, María Elisa': 'otros',
  'Rebolledo, Bárbara': 'vamosPorChile',
  'Reyes, María Ramona': 'apruebo',
  'Rivera, María Magdalena': 'delPueblo',
  'Rivera, Pollyana': 'vamosPorChile',
  'Roa, Giovanna': 'aprueboDignididad',
  'Rojas, Rodrigo': 'delPueblo',
  'Royo, Manuela': 'aprueboDignididad',
  'Saldaña, Alvin': 'otros',
  'Salinas, Fernando': 'delPueblo',
  'San Juan, Constanza': 'delPueblo',
  'Schonhaut, Constanza': 'aprueboDignididad',
  'Sepúlveda, Bárbara': 'aprueboDignididad',
  'Sepúlveda, Carolina': 'nuevaConstitucion',
  'Serey, Mariela': 'aprueboDignididad',
  'Silva, Luciano': 'vamosPorChile',
  'Squella, Agustín': 'apruebo',
  'Stingo, Daniel': 'aprueboDignididad',
  'Sánchez, Beatriz': 'aprueboDignididad',
  'Tepper, María Angélica': 'vamosPorChile',
  'Tirado, Fernando': 'pueblosIndigenas',
  'Toloza, Pablo': 'vamosPorChile',
  'Ubilla, María Cecilia': 'vamosPorChile',
  'Uribe, César': 'otros',
  'Urrutia, Tatiana': 'aprueboDignididad',
  'Valenzuela, César': 'apruebo',
  'Valenzuela, Paulina': 'nuevaConstitucion',
  'Vallejos, Loreto': 'delPueblo',
  'Vargas, Margarita': 'pueblosIndigenas',
  'Vargas, Mario': 'apruebo',
  'Vega, Roberto': 'vamosPorChile',
  'Veloso, Paulina': 'vamosPorChile',
  'Velásquez, Hernán': 'aprueboDignididad',
  'Vergara, Lisette': 'delPueblo',
  'Vidal, Loreto': 'delPueblo',
  'Videla, Carolina': 'aprueboDignididad',
  'Viera, Christian': 'apruebo',
  'Vilches, Carolina': 'aprueboDignididad',
  'Villena, Ingrid': 'delPueblo',
  'Woldarsky, Manuel': 'delPueblo',
  'Zárate, Camila': 'delPueblo',
  'Zúñiga, Luis Arturo': 'vamosPorChile',
}

export const viejosPactos = [
  { key: 'apruebo', label: 'Apruebo' },
  { key: 'aprueboDignididad', label: 'Apruebo Dignidad' },
  { key: 'delPueblo', label: 'Del Pueblo' },
  { key: 'nuevaConstitucion', label: 'Nueva Constitución' },
  { key: 'pueblosIndigenas', label: 'Pueblos Indígenas' },
  { key: 'vamosPorChile', label: 'Vamos por Chile' },
  { key: 'otros', label: 'Otros' },
]

export const nuevosPactos = [
  { key: 'ChileDigno', label: 'CD', longName: 'Chile Digno' },
  {
    key: 'ColectivoDelApruebo',
    label: 'CA',
    longName: 'Colectivo del Apruebo',
  },
  {
    key: 'ColectivoSocialista',
    label: 'CS',
    longName: 'Colectivo Socialista',
  },
  { key: 'FrenteAmplio', label: 'FA', longName: 'Frente Amplio' },
  {
    key: 'IndependientesNuevaConstitucion',
    label: 'INC',
    longName: 'Independientes por la Nueva Constitución',
  },
  {
    key: 'IndependientesRNE',
    label: 'IRE',
    longName: 'Independientes RN-Evópoli',
  },
  { key: 'Mixto', label: 'M', longName: 'Mixto' },
  {
    key: 'MovimientosSocialesConstituyentes',
    label: 'MSC',
    longName: 'Movimientos Sociales Constituyentes',
  },
  {
    key: 'PuebloConstituyente',
    label: 'PC',
    longName: 'Pueblo Constituyente',
  },
  { key: 'PueblosIndigenas', label: 'PI', longName: 'Pueblos Indígenas' },
  { key: 'VamosPorChile', label: 'VC', longName: 'Vamos por Chile' },
  { key: 'Otros', label: 'O', longName: 'Otros' },
]
