import { gql, useQuery } from '@apollo/client'

const PARTIDOS = gql`
  query getPartidos($space_name: String!, $tipo: String!) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          partidos(tipo: $tipo) {
            pageInfo {
              nextOffset
              size
              totalCount
            }
            results {
              nombre
            }
          }
        }
      }
    }
  }
`

interface usePartidosParams {
  spaceName: string
  tipo: string
}

const usePartidos = (variables: usePartidosParams) => {
  const { data: partidosData } = useQuery(PARTIDOS, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables,
  })

  const partidos = partidosData?.me?.organizacion?.espacio?.partidos?.results || []

  const adaptedPartidos = partidos.map(({ nombre }) => ({
    text: nombre,
    value: nombre,
  }))

  return { partidosArr: adaptedPartidos }
}

export default usePartidos
