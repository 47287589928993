import Comision from 'components/Comisiones/Comision'
import { useEnvironment } from 'components/Fetch'
import Detalle from 'components/persona/Detalle'
import useConfig from 'config/configAdapter/useConfig'
import { Navigate, Route, Routes as RoutesBase } from 'react-router-dom'

import IndexColunga from './Colunga/index'
import SesionColunga from './Colunga/Sesiones/Sesion'
import Comparador2023 from './ConsejoConstitucional/Comparador'
import PerfilesConsejo from './ConsejoConstitucional/Perfiles'
import SesionesConsejo from './ConsejoConstitucional/Sesiones'
import SesionConsejo from './ConsejoConstitucional/Sesiones/Sesion'
import VotacionesConsejo from './ConsejoConstitucional/Votaciones'
import DetalleConsejo from './ConsejoConstitucional/Votaciones/Articulos/Detalle/DetalleArticulo'
import Comparador from './Convencionales/Comparador'
import PerfilesConvencionales from './Convencionales/Perfiles'
import SesionesConvencional from './Convencionales/Sesiones'
import SesionConvencional from './Convencionales/Sesiones/Sesion'
import Votaciones from './Convencionales/Votaciones'
import DetalleVotaciones from './Convencionales/Votaciones/Detalle'
import ComisionesDiputados from './Diputados/Comisiones'
import PerfilesDiputados from './Diputados/Perfiles'
import SesionesDiputados from './Diputados/Sesiones'
import SesionDiputados from './Diputados/Sesiones/Sesion'
import CnnInicio from './Inicio/indexCnn'
import IndexCiudadania from './Regiones/index'
import RegionInfo from './Regiones/Region'
import SesionCiudadania from './Regiones/Sesiones/Sesion'
import Search from './Search'
import ComisionesSenadores from './Senadores/Comisiones'
import PerfilesSenadores from './Senadores/Perfiles'
import SesionesSenadores from './Senadores/Sesiones'
import SesionSenadores from './Senadores/Sesiones/Sesion'

export default function Routes() {
  const { spaces } = useEnvironment()
  const { defaultPath, flags = {} } = useConfig()
  return (
    <RoutesBase>
      {/* Regiones */}
      {spaces.canViewRegiones && (
        <>
          <Route
            element={<RegionInfo />}
            path="/regiones/:nroRegion/sesiones"
          />
          <Route
            element={<SesionCiudadania />}
            path="/regiones/:nroRegion/sesiones/:sesionId"
          />
          <Route
            element={<IndexCiudadania />}
            path="/regiones/inicio"
          />
        </>
      )}
      {/* Colunga */}
      {spaces.canViewColunga && (
        <>
          <Route
            element={<SesionColunga />}
            path="/conversatorio/sesiones/:sesionId"
          />
          <Route
            element={<IndexColunga />}
            path="/conversatorio/inicio"
          />
        </>
      )}
      {/* Convencionales */}
      {spaces.canViewConstituyentes && (
        <>
          <Route
            element={<PerfilesConvencionales />}
            path="/convencionales/perfiles"
          />
          <Route
            element={<SesionesConvencional />}
            path="/convencionales/sesiones"
          />
          <Route
            element={<SesionConvencional />}
            path="/convencionales/sesiones/:sesionId"
          />
          <Route
            element={<Comparador space="articulos" />}
            path="/comparador/:comision"
          />
          <Route
            element={<Votaciones />}
            path="/convencionales/votaciones"
          />
          <Route
            element={<DetalleVotaciones />}
            path="/convencionales/votaciones/:votacionId"
          />
        </>
      )}
      {/* Consejo */}
      {spaces.canViewConsejoConstitucional && (
        <>
          <Route
            element={<PerfilesConsejo />}
            path="/consejo/perfiles"
          />
          <Route
            element={
              flags.votaciones2023 ? (
                <VotacionesConsejo />
              ) : (
                <Navigate
                  to={defaultPath}
                  replace
                />
              )
            }
            path="/consejo/votaciones"
          />
          <Route
            element={
              flags.votaciones2023 ? (
                <DetalleConsejo />
              ) : (
                <Navigate
                  to={defaultPath}
                  replace
                />
              )
            }
            path="/consejo/votaciones/detalle/:votoId"
          />
          <Route
            element={<Comparador2023 space="articulos_2023" />}
            path="/consejo/comparador/"
          />
          <Route
            element={<Comparador2023 space="articulos_2023" />}
            path="/consejo/comparador/:comision"
          />
          <Route
            element={<SesionesConsejo />}
            path="/consejo/sesiones"
          />
          <Route
            element={<SesionConsejo />}
            path="consejo/sesiones/:sesionId"
          />
          <Route
            element={<CnnInicio />}
            path="cnn/inicio"
          />
        </>
      )}
      {/* Buscador */}
      <Route
        element={<Search.Results />}
        path="/search"
      />
      {/* Diputados */}
      {spaces.canViewDiputados && (
        <>
          <Route
            element={<PerfilesDiputados />}
            path="/diputados/perfiles"
          />
          <Route
            element={<SesionesDiputados />}
            path="/diputados/sesiones"
          />
          <Route
            element={<ComisionesDiputados />}
            path="/diputados/comisiones"
          />
          <Route
            element={<SesionDiputados />}
            path="diputados/sesiones/:sesionId"
          />
        </>
      )}
      {/* Senadores */}
      {spaces.canViewSenadores && (
        <>
          <Route
            element={<SesionesSenadores />}
            path="/senadores/sesiones"
          />
          <Route
            element={<ComisionesSenadores />}
            path="/senadores/comisiones"
          />
          <Route
            element={<SesionSenadores />}
            path="senadores/sesiones/:sesionId"
          />
          <Route
            element={<PerfilesSenadores />}
            path="/senadores/perfiles"
          />
        </>
      )}
      {/* Default */}
      <Route
        element={<Detalle />}
        path="/:role/:id"
      />
      <Route
        element={<Comision />}
        path=":role/comisiones/:id"
      />
      <Route
        path="*"
        element={
          <Navigate
            to={defaultPath}
            replace
          />
        }
      />
    </RoutesBase>
  )
}
