import { rollup } from 'd3-array'

import { pactoDeConstituyenteViejos } from 'config/staticData'

export const ABSTENCION = 'Abstención'

export const electoresSinPacto = new Map()

export const rollupPorOpcion = (votos) =>
  new Map(
    [
      ...rollup(
        votos,
        (values) => values,
        ({ opcion }) => opcion
      ).entries(),
    ].sort((entryA, entryB) => {
      if (entryA[0] === ABSTENCION) return 1
      if (entryB[0] === ABSTENCION) return -1

      return entryB[1].length - entryA[1].length
    })
  )

export const recordToObject = (record, pacts) => {
  const normalizaOpcion = (opcion) => {
    if (opcion === 'Abstención' || !opcion) {
      return ABSTENCION
    }
    return opcion
  }

  const votes = record.votos.reduce(
    (current, vote) => ({
      ...current,
      [vote.elector]: normalizaOpcion(vote.opcion),
    }),
    {}
  )

  return {
    fecha: record.fecha,
    materiaOriginal: record.materia_original,
    patrocinadores: [],
    titulo: record.titulo || record.materia_original.substring(0, 50),
    votos: Object.keys(pactoDeConstituyenteViejos).map((elector) => ({
      elector,
      opcion: normalizaOpcion(votes[elector]),
      pacto: pacts[elector],
    })),
  }
}

export const pactoPorConvencional = (convencionales) => {
  const results = {}
  convencionales.forEach((convencional) => {
    convencional.lookups.forEach((lookup) => {
      results[lookup] = convencional.pacto.key
    })
  })
  return results
}

export const rollupPorPactoYOpcion = (votos, opcionesOrdenadas) =>
  rollup(
    votos,
    (votosPacto) => {
      const rolledUp = rollupPorOpcion(votosPacto)
      const [firstOpcion, firstVotos] = rolledUp.entries().next().value

      return {
        afinidad: firstVotos.length / votosPacto.filter((v) => v.opcion !== ABSTENCION).length,
        opcionPreferida: firstOpcion,
        porOpcion: opcionesOrdenadas.map((opcion) => ({
          opcion,
          votos: rolledUp.get(opcion) || [],
        })),
      }
    },
    ({ pacto }) => pacto
  )
