import { Flags } from 'react-feature-flags'
import { useQuery } from '@apollo/client'

import Comparador from 'components/comparador'
import ARTICULOS from 'components/comparador/ARTICULOS.gql'
import LoadingSpinner from 'components/StyledSpinner'
import useConfig from 'config/configAdapter/useConfig'
import legend from './legend'

const origin = 'articulos_2023'

function ComparadorDetalle({ ispublic = false, space }) {
  const { linkPropuesta2023 } = useConfig()

  const tipoArticulo = 'propuesta_expertos'
  const { data, loading } = useQuery(ARTICULOS(origin), {
    variables: {
      tipoArticulo,
    },
  })

  if (loading) {
    return <LoadingSpinner />
  }
  const results = data?.[origin]?.results
  return (
    <Flags authorizedFlags={['comparadorConsejo']}>
      <Comparador
        textTitle="Propuesta de Comisión de Expertos 2023"
        tipoArticulo={tipoArticulo}
        title="Comparador Constitucional"
        pathPrefix="/consejo/comparador"
        legend={legend}
        results={results}
        space={space}
        ispublic={ispublic}
        downloadLink={linkPropuesta2023}
      />
    </Flags>
  )
}

export default ComparadorDetalle
