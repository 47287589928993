import { NavLink } from 'react-router-dom'
import Icon, {
  AppstoreOutlined,
  MessageOutlined,
  PullRequestOutlined,
  UserOutlined,
} from '@ant-design/icons'

import { ArticulosIcon, ComparadorIcon, VotacionesIcon } from 'assets'
import icons from 'assets/images/index'
import regiones from 'assets/images/territories/index'

function CustomNavLink({ title, to }) {
  return (
    <NavLink
      className="menu-link"
      to={to}
      style={({ isActive }) => ({
        fontWeight: isActive ? 'bold' : '',
      })}
    >
      {title}
    </NavLink>
  )
}

export default ({ config, flags, spaces }) =>
  [
    {
      children: config.regiones?.map((region) => {
        const { id, nombre } = region
        return {
          icon: (
            <Icon
              key={id}
              component={regiones[id]}
            />
          ),
          label: (
            <CustomNavLink
              to={`/regiones/${id}/sesiones`}
              title={nombre}
            />
          ),
        }
      }),
      hide: !(flags.regiones && spaces.canViewRegiones) || undefined,
      key: 'regiones',
      label: 'REGIÓN SOLICITANTE',
      type: 'group',
    },
    {
      children: config.menuItems?.map((menuItem) => {
        const { iconId, id, nombre } = menuItem
        return {
          icon: (
            <Icon
              key={id}
              component={icons[iconId]}
            />
          ),
          label: (
            <CustomNavLink
              to={`/conversatorio/sesiones/${id}`}
              title={nombre}
            />
          ),
        }
      }),
      hide: !(flags.colunga && spaces.canViewColunga) || undefined,
      key: 'colunga',
      label: 'MATERIAL MULTIMEDIA',
      type: 'group',
    },
    {
      children: [
        {
          icon: <UserOutlined />,
          key: '/consejo/tabla',
          label: (
            <CustomNavLink
              to="/consejo/perfiles"
              title="Perfiles"
            />
          ),
        },
        {
          hide: !(flags.votaciones2023 && spaces.canViewConsejoConstitucional) || undefined,
          icon: (
            <Icon
              className="custom-icon"
              component={VotacionesIcon}
            />
          ),
          key: '/consejo/votaciones',
          label: (
            <CustomNavLink
              to="/consejo/votaciones"
              title="Votaciones"
            />
          ),
        },
        {
          hide: !(flags.sesionesConsejo && spaces.canViewConsejoConstitucional) || undefined,
          icon: <AppstoreOutlined />,
          key: '/consejo/sesiones',
          label: (
            <CustomNavLink
              to="/consejo/sesiones"
              title="Sesiones"
            />
          ),
        },
        {
          hide: !(flags.comparadorConsejo && spaces.canViewConsejoConstitucional) || undefined,
          icon: (
            <Icon
              className="custom-icon"
              component={ComparadorIcon}
            />
          ),
          key: '/consejo/comparador',
          label: (
            <CustomNavLink
              to="/consejo/comparador/I.%20Fundamentos%20del%20orden%20constitucional"
              title="Comparador"
            />
          ),
        },
      ],
      hide: !(flags.consejeros && spaces.canViewConsejoConstitucional) || undefined,
      key: 'consejo constitucional',
      label: 'CONSEJO 2023',
    },
    {
      children: [
        {
          icon: <UserOutlined />,
          key: '/diputados/perfiles',
          label: (
            <CustomNavLink
              to="/diputados/perfiles"
              title="Perfiles"
            />
          ),
        },
        {
          hide: true,
          icon: <MessageOutlined />,
          key: '/diputados/votaciones',
          label: (
            <CustomNavLink
              to="/diputados/votaciones"
              title="Votaciones"
            />
          ),
        },
        {
          icon: <AppstoreOutlined />,
          key: '/diputados/sesiones',
          label: (
            <CustomNavLink
              to="/diputados/sesiones"
              title="Sesiones"
            />
          ),
        },
        {
          hide: !flags.comisiones ? true : undefined,
          icon: <PullRequestOutlined />,
          key: '/diputados/comisiones',
          label: (
            <CustomNavLink
              to="/diputados/comisiones"
              title="Comisiones"
            />
          ),
        },
      ],
      hide: !(flags.diputados && spaces.canViewDiputados) || undefined,
      key: 'diputados',
      label: 'DIPUTADOS',
    },
    {
      children: [
        {
          icon: <UserOutlined />,
          key: '/senadores/perfiles',
          label: (
            <CustomNavLink
              to="/senadores/perfiles"
              title="Perfiles"
            />
          ),
        },
        {
          hide: true,
          icon: <MessageOutlined />,
          key: '/senadores/votaciones',
          label: (
            <CustomNavLink
              to="/senadores/votaciones"
              title="Votaciones"
            />
          ),
        },
        {
          icon: <AppstoreOutlined />,
          key: '/senadores/sesiones',
          label: (
            <CustomNavLink
              to="/senadores/sesiones"
              title="Sesiones"
            />
          ),
        },
        {
          hide: !flags.comisiones ? true : undefined,
          icon: <PullRequestOutlined />,
          key: '/senadores/comisiones',
          label: (
            <CustomNavLink
              to="/senadores/comisiones"
              title="Comisiones"
            />
          ),
        },
      ],
      hide: !(flags.senadores && spaces.canViewSenadores) || undefined,
      key: 'senadores',
      label: 'SENADORES',
    },
    {
      children: [
        {
          icon: <UserOutlined />,
          key: '/convencionales/perfiles',
          label: (
            <CustomNavLink
              to="/convencionales/perfiles"
              title="Perfiles"
            />
          ),
        },
        {
          hide: !(flags.votaciones2022 && spaces.canViewConstituyentes) || undefined,
          icon: (
            <Icon
              className="custom-icon"
              component={VotacionesIcon}
            />
          ),
          key: '/convencionales/votaciones',
          label: (
            <CustomNavLink
              to="/convencionales/votaciones"
              title="Votaciones"
            />
          ),
        },
        {
          icon: <AppstoreOutlined />,
          key: '/convencionales/sesiones',
          label: (
            <CustomNavLink
              to="/convencionales/sesiones"
              title="Sesiones"
            />
          ),
        },
        {
          hide: true,
          icon: (
            <Icon
              className="custom-icon"
              component={ArticulosIcon}
            />
          ),
          key: '/convencionales/articulos',
          label: (
            <CustomNavLink
              to="/convencionales/articulos"
              title="Artículos Aprobados"
            />
          ),
        },
        {
          icon: (
            <Icon
              className="custom-icon"
              component={ComparadorIcon}
            />
          ),
          key: '/comparador',
          label: (
            <CustomNavLink
              to="/comparador/I. Principios y Disposiciones Generales"
              title="Comparador"
            />
          ),
        },
      ],
      hide: !(flags.convencionales && spaces.canViewConstituyentes) || undefined,
      key: 'convencion',
      label: 'CONVENCIÓN 2022',
    },
  ]
    .map((menuItem) =>
      menuItem.hide
        ? null
        : {
            ...menuItem,
            children: menuItem.children.filter((mi) => !mi.hide),
          }
    )
    .filter((menuItem) => Boolean(menuItem))
