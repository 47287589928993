// SVG
import { ReactComponent as FueraDeChile } from './FueraDeChile.svg'
import { ReactComponent as I } from './I.svg'
import { ReactComponent as II } from './II.svg'
import { ReactComponent as III } from './III.svg'
import { ReactComponent as IV } from './IV.svg'
import { ReactComponent as IX } from './IX.svg'
import { ReactComponent as V } from './V.svg'
import { ReactComponent as VI } from './VI.svg'
import { ReactComponent as VII } from './VII.svg'
import { ReactComponent as VIII } from './VIII.svg'
import { ReactComponent as X } from './X.svg'
import { ReactComponent as XI } from './XI.svg'
import { ReactComponent as XII } from './XII.svg'
import { ReactComponent as XIV } from './XIV.svg'
import { ReactComponent as XV } from './XV.svg'
import { ReactComponent as XVI } from './XVI.svg'
import { ReactComponent as XVIII } from './XVIII.svg'

const regiones = {
  FueraDeChile,
  I,
  II,
  III,
  IV,
  IX,
  V,
  VI,
  VII,
  VIII,
  X,
  XI,
  XII,
  XIV,
  XV,
  XVI,
  XVIII,
}

export default regiones
