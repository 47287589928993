import { spaces, tipoTerritorio } from 'Constants'
import styled from 'styled-components'
import { capitalizeFirstLetter } from 'utils'

import SocialMediaIcons from './SocialMedia'

interface StatisticRowProps {
  info?: string
  name?: string
}

function StatisticRow({ info, name }: StatisticRowProps) {
  if (!info) return null
  return (
    <>
      <Label>{name}:</Label>
      <Info>{info}</Info>
    </>
  )
}

export default function Fields({ persona }) {
  const { nombre_completo: nombreCompleto, redes_sociales: redesSociales } = persona

  return (
    <Wrapper>
      <StatisticRow
        name="Partido"
        info={persona.partido}
      />
      <StatisticRow
        name="Pacto"
        info={persona.subpacto}
      />
      <StatisticRow
        name={
          Boolean(persona.electo_en) &&
          capitalizeFirstLetter(tipoTerritorio[persona.electo_en.tipo])
        }
        info={persona.electo_en?.orden && <>Nº {persona.electo_en.orden}</>}
      />
      <StatisticRow
        name="Edad"
        info={persona.edad}
      />
      <StatisticRow
        name="Profesión"
        info={persona.profesiones[0] || '-'}
      />
      <SocialMediaIcons
        redesSociales={redesSociales}
        nombreCompleto={nombreCompleto}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: ${spaces.xs} ${spaces.s};
  font-size: 14px;
  height: min-content;
`

const Info = styled.span`
  font-weight: 300;
`

const Label = styled.span`
  font-weight: 700;
`
