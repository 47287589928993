import * as Tooltip from '@radix-ui/react-tooltip'
import styled from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'

export default function HighlightTooltip({ children, content }) {
  const { antdStyles } = useConfig()
  const fontFamily = antdStyles?.styles?.token?.fontFamily
  return (
    <Tooltip.Provider
      delayDuration={0}
      skipDelayDuration={0}
    >
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <span>{children}</span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <TooltipBox
            sideOffset={8}
            fontFamily={fontFamily}
          >
            {content}
            <Tooltip.Arrow fill="white" />
          </TooltipBox>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

const TooltipBox = styled(Tooltip.Content)`
  font-family: ${(props) => props.fontFamily || 'inherit'};
  font-size: 13px;
  padding: 12px;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
`
