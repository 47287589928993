import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'

export default function NameColumn({ data }) {
  const [firstRow, ...expandedRows] = [...data.versions]

  const { primaryColor } = useConfig()

  const isOnlyVersion = expandedRows.length === 0
  const { pathname } = useLocation()

  const articuloText =
    data.articulo.length > 70 ? `${data.articulo.substring(0, 70)}...` : data.articulo

  return (
    <Wrapper>
      <Label
        $isAlone={isOnlyVersion}
        color={primaryColor}
        to={`${pathname}/detalle/${data.id}`}
      >
        {`${articuloText} (v${firstRow.version})`}
      </Label>
    </Wrapper>
  )
}

const Label = styled(Link)`
  font-weight: bold;
  color: ${(props) => props.color};
  ${({ $isAlone }) => $isAlone && 'grid-column-start: 2;'};
  text-transform: capitalize;
`

const Wrapper = styled.div``
