import styled from 'styled-components'

import { spaces } from 'Constants'
import TranscripcionBase from '../Transcripcion/Mobile'
import Top from './Top'

export default function Mobile({
  editMode,
  filename,
  goto,
  handleBackButtonClick,
  isFromYoutube,
  isPlaying,
  mergedTranscriptions,
  playedSeconds,
  playerRef,
  primaryColor,
  sesion,
  setIsLoaded,
  setPlayedSeconds,
  transcripcionRef,
}) {
  return (
    <Wrapper>
      <Top
        editMode={editMode}
        filename={filename}
        handleBackButtonClick={handleBackButtonClick}
        isFromYoutube={isFromYoutube}
        isPlaying={isPlaying}
        mergedTranscriptions={mergedTranscriptions}
        playerRef={playerRef}
        primaryColor={primaryColor}
        sesion={sesion}
        setIsLoaded={setIsLoaded}
        setPlayedSeconds={setPlayedSeconds}
      />
      {mergedTranscriptions.length > 0 && (
        <Transcripcion
          highlightSeconds={playedSeconds}
          onSeek={goto}
          origen={sesion.origen}
          ref={transcripcionRef}
          transcripciones={mergedTranscriptions}
          sesion={sesion}
          editMode={editMode}
          filename={filename}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
const Transcripcion = styled(TranscripcionBase)`
  padding: ${spaces.l} 0;
`
