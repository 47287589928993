import useBreakpoint from 'query/useBreakpoint'
import styled from 'styled-components'

interface SpaceTitleProps {
  origenName?: string
  sectionName: string
}

export default function SpaceTitle({ origenName, sectionName }: SpaceTitleProps) {
  const { isMobile } = useBreakpoint()
  return (
    <Wrapper $isMobile={isMobile}>
      {sectionName} {origenName}
    </Wrapper>
  )
}

const Wrapper = styled.h1<{ $isMobile: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ $isMobile }) => ($isMobile ? '1.2rem' : '1.5rem')};
  margin-bottom: 0;
`
