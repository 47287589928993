import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Avatar from 'components/Avatar'
import useConfig from 'config/configAdapter/useConfig'
import { spaces } from 'Constants'
import Filelds from './Filelds'

function PactoImage({ url }) {
  return (
    <Image
      src={url}
      alt="logo"
    />
  )
}

export default function DatosPersonales({ persona }) {
  const { avatarColors } = useConfig()
  const { role } = useParams()
  const hasPactoImage = role === 'expertos' || role === 'admisibilidad'

  return (
    <Wrapper>
      <Logos>
        <Avatar
          size={100}
          shape="circle"
          src={persona.imagen_url}
          color={avatarColors[persona.partido]}
        />
        {!hasPactoImage && <PactoImage url={persona.imagen_pacto_url} />}
      </Logos>
      <Filelds persona={persona} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spaces.l};
  align-items: center;
`

const Image = styled.img`
  max-height: 100px;
  width: 100%;
  object-fit: contain;
`

const Logos = styled.div`
  display: grid;
  grid: 1fr / max-content 1fr;
  align-items: center;
  gap: ${spaces.l};
`
