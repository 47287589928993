import React, { useContext, useMemo } from 'react'

const EnvironmentContext = React.createContext()
export const useEnvironment = () => useContext(EnvironmentContext)

export function Environment({ children, env = {} }) {
  const environment = useEnvironment() || {}
  const valueEnv = useMemo(() => ({ ...env, ...environment }))
  return <EnvironmentContext.Provider value={valueEnv}>{children}</EnvironmentContext.Provider>
}
