import { useState } from 'react'

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_SORT_KEY = '_score'
const DEFAULT_SORT_ORDER = 'ascend'

export default function usePagination({
  defaultFilters = [],
  defaultPageNumber = 1,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  defaultSearch = '',
  defaultSortKey = DEFAULT_SORT_KEY,
  defaultSortOrder = DEFAULT_SORT_ORDER,
  defaultTotalItems = 0,
} = {}) {
  const [totalItems, setTotalItems] = useState(defaultTotalItems)
  const [pageNumber, setPageNumber] = useState(defaultPageNumber)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [sort, setSort] = useState(defaultSortKey)
  const [order, setOrder] = useState(null)
  const [filters, setFilters] = useState(defaultFilters)
  const [search, setSearch] = useState(defaultSearch)

  const formatOrder = (ord) => {
    const options = { ascend: 'asc', descend: 'desc' }
    return options[ord]
  }

  const updatePaginationInfo = (pageInfo) => setTotalItems(pageInfo.totalCount)
  const formatedOrder = formatOrder(order || defaultSortOrder)
  const getPaginationParams = ({ from, size } = {}) => {
    const startOfPage = (pageNumber - 1) * pageSize

    return {
      filters,
      from: from || startOfPage,
      search,
      size: size || pageSize,
      sortKey: sort || defaultSortKey,
      sortOrder: formatedOrder,
    }
  }

  return {
    getPaginationParams,
    pagination: {
      filters,
      order,
      pageNumber,
      pageSize,
      search,
      sort,
      totalItems,
    },
    setFilters,
    setOrder,
    setPageNumber,
    setPageSize,
    setSearch,
    setSort,
    updatePaginationInfo,
  }
}
