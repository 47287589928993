import { spaces } from 'Constants'

const { styled } = require('styled-components')

export default styled.div`
  padding: ${spaces.l};
  @media (max-width: ${spaces.phoneBreakpoint}) {
    padding: ${spaces.m};
  }
`
