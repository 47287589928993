import { useNavigate, useParams } from 'react-router-dom'
import { Collapse as CollapseBase } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'

import { Circle } from 'components/comparador/Legend'
import { colors, spaces } from 'Constants'
import { romanToNum } from 'utils'
import ArticuloName from './ArticuloName'
import Circles from './Circles'
import Header from './Header'

const { Panel: PanelBase } = CollapseBase

const naturalSorter = (a, b) =>
  a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })

const getColorsOrder = (legend) => Object.values(legend).map(({ color }) => color)

function colorsSorter(colorA, colorB, order) {
  const indexKeyA = order.indexOf(colorA)
  const indexKeyB = order.indexOf(colorB)
  return indexKeyA - indexKeyB
}

const groupArticulosPorComision = (articulos, capitulos) => {
  const articlesByComission = {}
  capitulos.forEach((c) => {
    articlesByComission[c] = articulos
      .filter(({ comision }) => comision === c)
      .sort((a, b) => naturalSorter(a.nombre, b.nombre))
  })
  _(articlesByComission)
    .toPairs()
    .sort(
      (titleA, titleB) => romanToNum(titleA[0].split('.')[0]) - romanToNum(titleB[0].split('.')[0])
    )
    .fromPairs()
    .value()

  return articlesByComission
}

export default function Nav({ className, legend, onClose, pathPrefix, results, title }) {
  const { comision } = useParams()
  const colorsOrder = getColorsOrder(legend)
  const navigate = useNavigate()

  const articulos = results.map((art, ind) => ({
    key: `${art.comision.replaceAll(' ', '_')}__${art.nombre.replaceAll(' ', '_')}__${ind}`,
    ...art,
  }))
  const capitulos = Array.from(new Set(articulos?.map((articulo) => articulo.comision) || [])).sort(
    (titleA, titleB) => romanToNum(titleA.split('.')[0]) - romanToNum(titleB.split('.')[0])
  )

  const currentComisionIndex = capitulos?.indexOf(comision)
  const groupedByCapitulo = results && groupArticulosPorComision(articulos, capitulos)

  const articleColors = ({ highlights }) =>
    highlights.map(({ match }) => ({
      color: colors.constitutions[match.documento_id.split('articulos_')[1]],
      key: `${match.file_path}__${match.start}`,
    }))

  const capituloColors = ({ arts }) => {
    const colorsByArticle = arts.flatMap(({ highlights }) =>
      articleColors({ highlights }).map(({ color }) => color)
    )
    return Array.from(new Set(colorsByArticle))
  }

  return (
    <Wrapper className={className}>
      {title && <Title>{title}</Title>}
      <Collapse
        accordion
        defaultActiveKey={currentComisionIndex}
      >
        {Object.entries(groupedByCapitulo).map(([c, arts]) => (
          <Panel
            $active={c === comision}
            header={
              <Header
                colors={capituloColors({ arts })}
                title={c}
                colorsOrder={colorsOrder}
              />
            }
            key={c}
            showArrow={false}
          >
            {arts.map(({ highlights, key, nombre, titulo }) => {
              const highlightsSet = [
                ...new Set(articleColors({ highlights }).map(({ color }) => color)),
              ]
              const onClickArticle = () => {
                onClose()
                navigate(`${pathPrefix}/${c}#${nombre}_${titulo}`)
              }
              return (
                <ArticuloName
                  articulo={nombre}
                  key={key}
                  onClick={onClickArticle}
                  titulo={
                    <Circles>
                      {highlightsSet
                        .sort((ca, ab) => colorsSorter(ca, ab, colorsOrder))
                        .map((color) => (
                          <Circle
                            key={color}
                            color={color}
                          />
                        ))}
                    </Circles>
                  }
                  on
                />
              )
            })}
          </Panel>
        ))}
      </Collapse>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.s};
  max-width: 100%;
  padding: 0 ${spaces.m};
`

const Title = styled.span`
  font-size: 12px;
  text-transform: capitalize;
  padding: ${spaces.m} 0;
`

const Collapse = styled(CollapseBase)`
  background: white;
  border: none;
  width: max-content;
  font-size: 13px;
  flex-direction: column;
  display: flex;
  max-width: 100%;
`

const Panel = styled(PanelBase)`
  .ant-collapse-content {
    overflow-y: auto;
  }

  &.ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    font-weight: ${(props) => (props.$active ? 'bold' : 'normal')};
    padding: ${spaces.m} ${spaces.m} ${spaces.m} 0;
  }
`
