import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { AutoComplete } from 'antd'
import Column from 'antd/es/table/Column'
import styled from 'styled-components'

import StyledTable from 'components/StyledTable'
import useConfig from 'config/configAdapter/useConfig'
import downloadObjectAsJson from '../downloadJson'
import { getGuestsObj, toSeconds } from '../helpers'
import personas from '../personas'
import Speaker from './Speaker'
import Time from './Time'

const Transcripcion = forwardRef(
  ({ editMode, filename, highlightSeconds, onSeek, origen, transcripciones: tOriginales }, ref) => {
    const [following, setFollowing] = useState(true)
    const editedTranscription = editMode && JSON.parse(localStorage.getItem(filename))
    const transcripciones = editedTranscription || tOriginales
    const [tEtiquetadas, setTEtiquetadas] = useState(transcripciones)
    const highlightedIdx = transcripciones.findIndex(
      (t, idx) =>
        highlightSeconds >= toSeconds(t.tiempo_inicio) &&
        (idx === transcripciones.length - 1 ||
          highlightSeconds < toSeconds(transcripciones[idx + 1].tiempo_inicio))
    )

    useImperativeHandle(ref, () => ({
      follow: () => setFollowing(true),
    }))

    const segmentoRef = useRef()

    useEffect(() => {
      if (segmentoRef.current && highlightedIdx && following) {
        segmentoRef.current.scrollIntoView()
      }
    }, [highlightedIdx, following])

    useEffect(() => {
      if (editMode) {
        localStorage.setItem(filename, JSON.stringify(tEtiquetadas))
      }
    }, [tEtiquetadas])

    const saveChange = ({ transcripcion, value }) => {
      const toModify = tEtiquetadas.map((t) => ({ annotations: {}, ...t }))
      const modifiedTranscription = toModify.find(
        (t) =>
          t.tiempo_inicio === transcripcion.tiempo_inicio &&
          t.tiempo_fin === transcripcion.tiempo_fin
      )
      const newAnnotations = {
        ...modifiedTranscription.annotations,
        hablante: value,
      }
      modifiedTranscription.annotations = newAnnotations
      setTEtiquetadas(toModify)
    }

    const guestsObj = getGuestsObj(transcripciones)

    const { primaryColor } = useConfig()

    const downloadTranscription = () => {
      downloadObjectAsJson(tEtiquetadas, filename)
    }

    return (
      <>
        {editMode && (
          <button
            type="button"
            onClick={downloadTranscription}
          >
            Descargar archivo de transcripciones
          </button>
        )}
        <TrancriptTable
          dataSource={transcripciones}
          pagination={false}
          scroll={{ y: 'fit-content' }}
          tableLayout="auto"
          rowClassName={(_, index) => index === highlightedIdx && 'highlighted-row'}
          onRow={(_, index) => ({
            ref: index === highlightedIdx ? segmentoRef : undefined,
          })}
          rowKey={(record) => record.tiempo_inicio}
        >
          <Column
            key="hablante"
            className="align-top-cell"
            title="Hablante"
            render={({
              annotations,
              hablante,
              hablante_nombre: hablanteNombre,
              speaker_id_prediction: speakerIdPrediction,
              ...transcription
            }) =>
              editMode ? (
                <div>
                  <AutoComplete
                    options={personas}
                    style={{
                      width: 200,
                    }}
                    onSelect={(value) =>
                      saveChange({
                        transcripcion: transcription,
                        value,
                      })
                    }
                    onBlur={({ target }) =>
                      saveChange({
                        transcripcion: transcription,
                        value: target.value,
                      })
                    }
                    filterOption={(inputValue, option) =>
                      option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    placeholder={annotations?.hablante || guestsObj[hablanteNombre] || ''}
                  />
                  {speakerIdPrediction && <span>Predicted speaker: {speakerIdPrediction}</span>}
                </div>
              ) : (
                <Speaker
                  origen={origen}
                  rawFullName={hablante?.nombre_completo || guestsObj[hablanteNombre]}
                  speaker={hablante}
                  predictedSpeaker={speakerIdPrediction}
                  primarycolor={primaryColor}
                />
              )
            }
            width={186}
          />
          <Column
            key="tiempo"
            title="Tiempo"
            className="align-top-cell"
            render={({ tiempo_inicio: tiempoInicio }) => (
              <Time
                time={tiempoInicio}
                onClick={() => onSeek(toSeconds(tiempoInicio))}
                primarycolor={primaryColor}
                className="align-top"
              />
            )}
            width={93}
          />
          <Column
            key="transcripcion"
            title="Transcripción"
            render={({ texto }) => <StyledTranscriptText>{texto}</StyledTranscriptText>}
          />
        </TrancriptTable>
      </>
    )
  }
)

const StyledTranscriptText = styled.div`
  padding-right: 10px;
  font-size: 12px;
`

const TrancriptTable = styled(StyledTable)`
  .ant-table-tbody {
    tr.ant-table-row:hover > td {
      background: none !important;
    }
  }

  .highlighted-row {
    font-weight: bold;
  }
`

export default Transcripcion
