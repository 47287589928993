import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import useConfig from 'config/configAdapter/useConfig'

export default function Logo() {
  const { pathname } = useLocation()
  const { antdStyles, logoPath } = useConfig()
  const menuLogoSize = antdStyles.styles.token.logoSize

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname })
  }, [pathname])

  return (
    <Link to="/">
      <Wrapper $logoSize={menuLogoSize}>
        {logoPath && (
          <img
            src={logoPath}
            alt="logo"
          />
        )}
      </Wrapper>
    </Link>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: ${({ $logoSize }) => $logoSize} 0;

  img {
    width: 70%;
  }
`
