import { gql, useQuery } from '@apollo/client'

const COMISIONES = gql`
  query getComisiones($space_name: String!, $tipo: String!) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          sesion_comision(tipo: $tipo) {
            pageInfo {
              nextOffset
              size
              totalCount
            }
            results {
              nombre
            }
          }
        }
      }
    }
  }
`

interface useSesionComisionesParams {
  spaceName: string
  tipo: string
}

const useSesionComisiones = (variables: useSesionComisionesParams) => {
  const { data: comisionesData } = useQuery(COMISIONES, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables,
  })

  const comisiones = comisionesData?.me?.organizacion?.espacio?.sesion_comision?.results || []

  const adaptedSesionComisiones = comisiones.map(({ nombre }) => ({
    text: nombre,
    value: nombre,
  }))

  return { comisionesArr: adaptedSesionComisiones }
}

export default useSesionComisiones
