import styled from 'styled-components'

import { colors } from 'Constants'

export default styled.div.attrs((props) => ({
  color: props.color || colors.default,
}))`
  border-radius: 6px;
  border: 2px solid ${(props) => props.color};
  display: inline-block;
  font-size: 14px;
  padding: 1px 6px;
  white-space: nowrap;
`
