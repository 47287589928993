import { useAutoAnimate } from '@formkit/auto-animate/react'
import styled from 'styled-components'

import InsideRows from './utilityStyles'

function Instance({ data }) {
  const [parent] = useAutoAnimate()

  return (
    <Wrapper ref={parent}>
      <div>{data.comision}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${InsideRows}
`

export default Instance
