import styled from 'styled-components'

export default function Dato({ contenido, titulo }) {
  return (
    <>
      {titulo && <Label>{titulo}:</Label>}
      <Value>{contenido}</Value>
    </>
  )
}

const Label = styled.div`
  font-weight: 700;
`

export const Value = styled.div`
  font-weight: 400;
`
