import { Button } from 'antd'
import useConfig from 'config/configAdapter/useConfig'
import { spaces } from 'Constants'
import { useEffect } from 'react'
import styled from 'styled-components'

import NumberCard from './Card'

export default function Landing() {
  const { logoPath } = useConfig()
  useEffect(() => {
    const descriptionMeta = document.querySelector('meta[name="description"]')
    if (descriptionMeta) {
      descriptionMeta.setAttribute(
        'content',
        'Plataforma de recopilación y análisis cuantitativo ' +
          'de las audiencias ciudadanas del proceso constitucional 2023.'
      )
    }
  }, [])

  return (
    <Wrapper>
      <LogoScanner
        src={logoPath}
        alt="logo"
      />
      <Title>
        Scanner CNN es una plataforma que captura, procesa, registra y expone los datos del trabajo
        del Consejo Constitucional.
      </Title>
      <CardGroup>
        <NumberCard
          counter="1928"
          title="Sesiones procesadas"
        />
        <NumberCard
          counter="687"
          title="Votaciones"
        />
        <NumberCard
          counter="39200000 "
          title="Apariciones en medios"
        />
      </CardGroup>
      <SignInButton type="primary">Regístrate</SignInButton>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('/images/background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  row-gap: 15px;

  @media (max-width: ${spaces.phoneBreakpoint}) {
    width: 100%;
    height: 100%;
    padding: 15px 0;
  }
`

const LogoScanner = styled.img`
  max-width: 446px;
  max-height: 82.491px;
  padding: 10px;
`

const Title = styled.h1`
  color: var(--Gray-1, #333);
  text-align: center;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 52.5px; /* 169.355% */
  max-width: 834px;
  @media (max-width: ${spaces.phoneBreakpoint}) {
    font-size: 1.4em;
  }
`

const CardGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spaces.xxl};
  @media (max-width: ${spaces.phoneBreakpoint}) {
    flex-direction: column;
  }
`

const SignInButton = styled(Button)`
  width: 256px;
  height: 44.522px;
  flex-shrink: 0;
  margin-top: 15px;
`
