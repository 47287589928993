import React from 'react'
import styled from 'styled-components'

import footerimg from 'assets/images/cnnIndexFooter.svg'
import logo from 'assets/images/cnnIndexTop.svg'
import Content from 'components/Content'
import { colors, spaces } from 'Constants'

function cnnIndexPage() {
  return (
    <Wrapper>
      <Contents>
        <Top>
          <Logo src={logo} />
          <Title>
            Scanner CNN es una plataforma que captura, procesa, registra y expone los datos del
            trabajo del Consejo Constitucional.
          </Title>
        </Top>
        <Paragraphs>
          <Text>
            Aquí encontrarás los perfiles de los miembros del Consejo, la comisión de expertos y el
            comité de admisibilidad. Podrás acceder al contenido de las sesiones, con sus
            transcripciones y videos, y buscar tus temas de interés para saber cómo han sido
            abordados en el organismo.
          </Text>
          <Text>
            En el Comparador puedes ver las coincidencias entre el anteproyecto de los expertos con
            distintos textos constitucionales de nuestra historia y próximamente podrás contar con
            el registro de votaciones que se desarrollen en la instancia.
          </Text>
          <Text>Manténte informado de este proceso con CNN Chile.</Text>
        </Paragraphs>
        <Image src={footerimg} />
      </Contents>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Contents = styled(Content)`
  display: flex;
  flex-direction: column;
  max-width: ${spaces.phoneBreakpoint};
  gap: ${spaces.m};
  align-items: center;
  font-size: 14px;
  text-align: center;
  color: ${colors.selectedNav};
`
const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${spaces.l};
  align-items: center;
`
const Paragraphs = styled.div``

const Image = styled.img`
  max-width: 300px;
`
const Logo = styled.img`
  @media (max-width: ${spaces.phoneBreakpoint}) {
    max-width: 250px;
  }
`

const Title = styled.h1`
  font-size: 1.6em;
  font-weight: 500;
  @media (max-width: ${spaces.phoneBreakpoint}) {
    font-size: 1.4em;
  }
`

const Text = styled.p`
  font-weight: 400;
  margin-bottom: ${spaces.m};
`

export default cnnIndexPage
