import { useEffect } from 'react'

import useConfig from 'config/configAdapter/useConfig'

export default function Login() {
  const { baseUrl } = useConfig()

  useEffect(() => {
    if (baseUrl) {
      window.location.href = `${baseUrl}/auth/auth`
    }
  }, [baseUrl])
  return null
}
