import styled from 'styled-components'

import { spaces } from 'Constants'

export default function ArticuloName({ articulo, onClick, titulo }) {
  return (
    <Wrapper onClick={onClick}>
      {articulo}
      {titulo}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: ${spaces.s};
  cursor: pointer;
  margin-bottom: 1em;
`
