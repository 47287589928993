import { Flags } from 'react-feature-flags'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import Wrapper from 'components/Content'
import SesionEnSala from 'components/sesiones/SesionesEnSala'
import LoadingSpinner from 'components/StyledSpinner'

const SESION = gql`
  query getSesion($spaceName: String!, $id: String!) {
    me {
      organizacion {
        espacio(space_name: $spaceName) {
          sesion(id: $id) {
            id
            video_url
            origen
            codigo_sesion
            titulo
            comision
            fecha
            transcripciones {
              hablante_id
              hablante_nombre
              hablante {
                id
                partido
                nombre_completo
                imagen_url
              }
              texto
              tiempo_inicio
              duracion_seg
              tiempo_fin
              tiempo_duracion
              speaker_id_prediction
            }
          }
        }
      }
    }
  }
`

export default function SesionConvencional() {
  const { sesionId } = useParams()
  const { data, loading } = useQuery(SESION, {
    variables: { id: sesionId, spaceName: 'Convencionales' },
  })

  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <Wrapper>
      <Flags authorizedFlags={['convencionales']}>
        {data && <SesionEnSala sesion={data.me.organizacion.espacio.sesion} />}
      </Flags>
    </Wrapper>
  )
}
