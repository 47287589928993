import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ConfigProvider } from '@unholster/react-use-config'
import esES from 'antd/es/locale/es_ES'

import App from 'App'
import GlobalStyle from 'components/GlobalStyle'
import AntdConfigProvider from 'providers/AntdConfigProvider'
import packageInfo from '../package.json'
import configAdapter from './config/configAdapter'
import FlagsProvider from './providers/FlagsProvider'
import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    release: `${packageInfo.name}@${packageInfo.version}`,
    tracesSampleRate: 0.1,
  })
}

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ConfigProvider
      configPath={process.env.REACT_APP_CONFIG_PATH || '/config.json'}
      adapter={configAdapter}
    >
      <GlobalStyle>
        <FlagsProvider>
          <AntdConfigProvider locale={esES}>
            <App />
          </AntdConfigProvider>
        </FlagsProvider>
      </GlobalStyle>
    </ConfigProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
