import { AutoComplete, Button, Form } from 'antd'
import styled from 'styled-components'

import downloadJson from './downloadJson'
import personas from './personas'

const { Item } = Form

const getUniqueSpeakerIds = (transcripciones) => {
  const uniqueIds = [
    ...new Set(
      transcripciones.reduce((uIds, t) => {
        const speakerId = t.speaker_id_prediction
        if (!speakerId) return uIds
        uIds.push(speakerId)
        return uIds
      }, [])
    ),
  ]
  return uniqueIds
}

const downloadNewSpeakers = (values, filename, transcripciones, titulo) => {
  const speakers = transcripciones.map((t) => {
    const modifiedSpeaker = values[t.speaker_id_prediction]
    return {
      algoritmo: modifiedSpeaker ? 'manual' : undefined,
      hablante_id: t.hablante_id,
      hablante_label: modifiedSpeaker || t.speaker_id_prediction,
      speaker_id_prediction: t.speaker_id_prediction,
      timestamp_final: t.tiempo_fin,
      timestamp_inicio: t.tiempo_inicio,
    }
  })
  const speakersFileContent = {
    speakers,
    titulo,
  }
  downloadJson(speakersFileContent, filename)
}

export default function HablantesEditor({ filename, titulo, transcripciones }) {
  const uniqueSpeakers = getUniqueSpeakerIds(transcripciones)

  return (
    <Wrapper>
      <h3>
        <b>Editor de hablantes</b>
      </h3>
      <Form
        name="wrap"
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        onFinish={(values) => downloadNewSpeakers(values, filename, transcripciones, titulo)}
      >
        {uniqueSpeakers.map((speaker) => (
          <Item
            label={speaker}
            name={speaker}
          >
            <AutoComplete
              options={personas}
              filterOption={(inputValue, option) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              placeholder={speaker}
            />
          </Item>
        ))}

        {uniqueSpeakers.length ? (
          <Item label=" ">
            <Button
              type="primary"
              htmlType="submit"
              style={{ height: 60, width: 150 }}
            >
              Descargar archivo
              <br />
              de hablantes
            </Button>
          </Item>
        ) : (
          'No hay hablantes que editar'
        )}
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  height: 100%;
`
