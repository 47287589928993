import { useNavigate, useParams } from 'react-router-dom'
import { LeftOutlined, UserOutlined } from '@ant-design/icons'
import { gql, useQuery } from '@apollo/client'
import { Divider, Image, Row, Tabs } from 'antd'
import styled from 'styled-components'

import Avatar from 'components/Avatar'
import StyledPageHeader from 'components/PageHeader'
import useConfig from 'config/configAdapter/useConfig'
import { calculateAge, capitalizeFirstLetter, capitalizeFirstLetterEachWord } from 'utils'
import InfoPresidente from './InfoPresidenteComision'
import DetalleIndicaciones from './tabs/DetalleIndicaciones'
import DetalleIntegrantes from './tabs/DetalleIntegrantes'
import DetalleProyectos from './tabs/DetalleProyectos'

const COMISION = gql`
  query getComision($id: String!, $space_name: String!) {
    me {
      organizacion {
        espacio(space_name: $space_name) {
          comision(id: $id) {
            nombre
            integrantes {
              id
              nombre_completo
              nombres
              apellido_materno
              apellido_paterno
              partido
              pacto
              subpacto
              electo_en {
                orden
              }
              imagen_url
            }
            origen
            presidente {
              id
              nombre_completo
              rut
              edad
              profesiones
              pacto
              partido
              subpacto
              electo_en {
                orden
              }
              imagen_url
              imagen_partido_url
              fecha_nacimiento
            }
          }
        }
      }
    }
  }
`

function TabsDetail({ integrantes, role }) {
  return (
    <Tabs
      defaultActiveKey="Integrantes"
      items={[
        {
          children: (
            <DetalleIntegrantes
              integrantes={integrantes}
              role={role}
            />
          ),
          key: 'Integrantes',
          label: 'Integrantes',
        },
        {
          children: <DetalleProyectos />,
          key: 'Proyectos',
          label: (
            <div>
              {' '}
              Proyectos
              <br /> presentados{' '}
            </div>
          ),
        },
        {
          children: <DetalleIndicaciones />,
          key: 'Indicaciones',
          label: (
            <div>
              Indicaciones <br /> presentadas
            </div>
          ),
        },
      ]}
    />
  )
}

export default function Detalle() {
  const { avatarColors, primaryColor } = useConfig()
  const navigate = useNavigate()
  const { id, role } = useParams()
  const { data } = useQuery(COMISION, {
    variables: {
      id,
      space_name: capitalizeFirstLetter(role),
    },
  })

  const comision = data?.me?.organizacion?.espacio?.comision

  if (!comision) {
    return null
  }
  const { presidente } = comision
  const avatarColorKey = presidente ? presidente.partido : ''
  return (
    <Container>
      <StyledPageHeader
        className="page-header"
        backIcon={<LeftOutlined style={{ color: primaryColor }} />}
        extra={undefined}
        onBack={() => navigate(`/${role}/comisiones`)}
        title={capitalizeFirstLetter(comision.nombre)}
      />
      <Divider
        type="horizontal"
        style={{ height: '100%', marginTop: '0px' }}
      />
      <StyledRow
        id="conventional-card-container"
        className={presidente ? 'president' : 'no-president'}
      >
        <StyledColumn className="avatar-col">
          <Avatar
            size={136}
            src={presidente ? presidente.imagen_url : undefined}
            color={avatarColors[avatarColorKey]}
            icon={<UserOutlined />}
          />
        </StyledColumn>
        {presidente ? (
          <>
            <InfoPresidente
              nombre={capitalizeFirstLetterEachWord(presidente.nombre_completo || '')}
              edad={calculateAge(presidente.fecha_nacimiento)}
              profesion={presidente.profesiones}
              pacto={presidente.pacto}
              partido={presidente.partido}
              territorio={presidente.electo_en.orden}
              rol={role}
            />
            {presidente.imagen_partido_url && (
              <StyledColumn className="partido-col">
                <Image
                  width="145px"
                  src={presidente.imagen_partido_url}
                  preview={false}
                />
              </StyledColumn>
            )}
          </>
        ) : (
          <div className="no-president-phrase">Sin presidente asignado</div>
        )}
      </StyledRow>
      <TabsDetail
        integrantes={comision.integrantes}
        role={role}
      />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  .page-header {
    background-color: #ffffff;
    padding-top: 0;
  }
  .president {
    justify-content: space-between;
  }
  .no-president {
    justify-content: flex-start;
  }
`

const StyledRow = styled(Row)`
  margin: 12px 36px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  .ant-statistic {
    font-size: 1em;
    .ant-statistic-content {
      font-size: 1.2em;
    }
  }
  .no-president-phrase {
    margin-top: 30px;
    margin-left: 20px;
  }
`

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  .anticon svg {
    width: 80px;
    height: 80px;
  }
  &.parlamentario-general-data {
    width: 400px;
  }
`
