import useConfig from 'config/configAdapter/useConfig'
import { spaces } from 'Constants'
import { useEffect } from 'react'
import styled from 'styled-components'

export default function Mantencion() {
  const { backgroundPath, logoPath } = useConfig()
  useEffect(() => {
    const currentLocation = document.location.pathname
    const isInRoot = currentLocation === '/'
    if (!isInRoot) document.location.href = '/'
  }, [])

  return (
    <Wrapper backgroundPath={backgroundPath}>
      <Card>
        <LogoScanner
          src={logoPath}
          alt="logo"
        />
        <Emojis>🧑🏻‍💻👩🏻‍💻</Emojis>
        Estamos trabajando para mejorar tu experiencia
        <br />
        en la plataforma.
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.main<{ backgroundPath?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ backgroundPath }) => backgroundPath && `background-image: url('${backgroundPath}');`}
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
`

const Card = styled.section`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: white;
  border-radius: 5px;
  gap: 24px;
  text-align: center;
`

const LogoScanner = styled.img`
  max-height: 52px;
`

const Emojis = styled.span`
  font-size: ${spaces.l};
`
