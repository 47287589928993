import styled from 'styled-components'

import VoteBar from 'components/VoteBar'
import { colors } from 'Constants'

const barColors = {
  abstenciones: colors.votacion.pendiente,
  apruebos: colors.votacion.aprobada,
  debate: colors.votacion.debate,
  rechazos: colors.votacion.rechazada,
}

function ProgressBar({ results }) {
  const filteredValues = Object.values(results).filter((value) => value !== 'Resultado')
  const total = Object.values(filteredValues).reduce(
    (partialSum, a) => partialSum + parseInt(a, 10),
    0
  )

  const { apruebos, rechazos } = results

  return (
    <MainWrapper>
      <BarWrapper>
        <MainBar>
          <VoteBar
            $inverted
            $color={barColors.rechazos}
            $percentage={`${(rechazos / total) * 100}%`}
            $remainingColor={colors.votacionBackground}
          />
          <VoteBar
            $color={barColors.apruebos}
            $percentage={`${(apruebos / total) * 100}%`}
            $remainingColor={colors.votacionBackground}
          />
        </MainBar>
      </BarWrapper>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  height: 24px;
  display: flex;
  position: relative;
`

const BarWrapper = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 4px;
`

const MainBar = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
`

export default ProgressBar
