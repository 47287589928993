import React from 'react'
import { Flags } from 'react-feature-flags'

import SesionList from 'components/sesiones/SesionList'

function SesionDiputadosList() {
  return (
    <Flags authorizedFlags={['diputados']}>
      <SesionList
        space="Diputados"
        tipo="Cámara de Diputadas y Diputados"
      />
    </Flags>
  )
}

export default SesionDiputadosList
